import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { TabDetailsSections } from '../coaching.constants';
import { Section } from '@app/models';
import { Note } from '@app/models/note.model';
import { DialogEventService } from '@app/shared/components/dialog-event/dialog-event.service';
import { EventService } from '@app/services/event.service';
import { addSpaceAfterComma } from '@app/shared/helpers/string.helper';
import { MatDialog } from '@angular/material/dialog';
import { DialogCoachingSamplesComponent } from '@app/shared/components/dialog-coaching-samples/dialog-coaching-samples.component';
import { DialogEventComponent } from '@app/shared/components/dialog-event/dialog-event.component';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { InterventionService } from '@app/services/intervention.service';
import { ToasterService } from '@app/services/toaster.service';
import { FlyoutType } from '@app/models/fly-out.model';
import { FlyoutService } from '@app/services/flyout.service';
import { StatusType } from '@app/models/status.model';
import { DialogConfirmComponent } from '../../dialog-confirm/dialog-confirm.component';
import { PermissionService } from '@app/services/permission.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-tab-section-details',
  templateUrl: './tab-section-details.component.html',
  styleUrls: ['./tab-section-details.component.scss'],
})
export class TabSectionDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() showMenu!: boolean;
  @Input() interventionId!: string;
  @Input() driverId!: string;
  @Input() dataRow!: any;

  sectionSelected: string = 'coaching-plan';
  coachingScriptContents!: string;
  coachingScriptOriginalContents!: string;
  interventionDetail!: any;
  linkedEventList: any[] = [];
  linkedEventColumns: string[] = [];
  isLoading: boolean = false;
  userId!: string;

  userName: string = '';
  avatarUrl: string = '';
  noteContents: string = '';
  notes: Note[] = [];
  entityType: string = 'intervention';
  statuses = [
    {
      value: StatusType.OPEN.toLowerCase(),
      viewValue: StatusType.OPEN,
      icon: 'fa-solid fa-circle-notch',
    },
    {
      value: StatusType.ASSIGNED.toLowerCase(),
      viewValue: StatusType.ASSIGNED,
      icon: 'fa-solid fa-user-circle icon-second-blue',
    },
    {
      value: StatusType.CLOSED.toLowerCase(),
      viewValue: StatusType.CLOSED,
      icon: 'fa-solid fa-circle-xmark icon-red icon-default-size',
    },
  ];
  selectedRow: string | null = null;
  showSaveButton: boolean = false;
  changesAmount: number = 0;
  coachingStatistics: any[] = [];
  coachingExamples: any[] = [];
  addSpaceAfterComma = addSpaceAfterComma;

  readonly sections: Section[] = TabDetailsSections;
  private destroy$ = new Subject<void>();

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private dialogEventService: DialogEventService,
    private eventService: EventService,
    private dialog: MatDialog,
    private interventionService: InterventionService,
    private toasterService: ToasterService,
    private flyoutService: FlyoutService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.fetchLinkedEvents();
    this.fetchInterventionDetail();
    this.setSubscriptionToRefreshList();
    this.updateColumns();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setSubscriptionToRefreshList(): void {
    this.dialogEventService.statusSelectionChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this.fetchLinkedEvents(), 200);
      });
  }

  cancelCoachingScript(): void {
    this.coachingScriptContents = this.coachingScriptOriginalContents;
  }

  fetchInterventionDetail(): void {
    this.userId = this.dataRow.assignee;
    this.interventionService.getIntervention(this.dataRow.id).subscribe({
      next: (res) => {
        this.interventionDetail = res[0];
        this.coachingScriptContents = this.interventionDetail.coachingScript;
        this.coachingScriptOriginalContents = this.coachingScriptContents;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  fetchLinkedEvents(fromUnlink?: boolean): void {
    const params = {
      interventionId: this.interventionId,
    };
    this.isLoading = true;
    this.eventService.getLinkedEventsToIntervention(params).subscribe({
      next: (res) => {
        if (res[0]?.events) {
          this.linkedEventList = res[0].events;
          this.fetchCoachingSamples();
        } else {
          this.linkedEventList = [];
        }
        if (fromUnlink && !this.linkedEventList.length) {
          this.setStatusToOpen();
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  setStatusToOpen(): void {
    this.dataRow.status = StatusType.OPEN;
    const completed = false;
    const request = {
      id: this.dataRow?.id,
      status: this.dataRow?.status,
      completed,
      lastModifiedBy: this.dataRow?.lastModifiedBy,
      organizationId: environment.organizationId,
    };
    this.interventionService.upsertIntervention(request).subscribe({
      next: () => {
        this.interventionService.announceSelectionChange('update');
        this.toasterService.showSuccess(`The Status was updated successfully`);
      },
      error: (error) => console.error(error),
    });
  }

  getEventTypes(): string {
    const uniqueEventTypes = new Set();

    this.linkedEventList.forEach((event) => {
      if (event.eventType) {
        uniqueEventTypes.add(event.eventType);
      }
    });

    return Array.from(uniqueEventTypes).join(',');
  }

  fetchCoachingSamples(): void {
    const eventTypes = this.getEventTypes();
    const params = {
      eventTypes,
    };
    this.interventionService.getCoachingSamples(params).subscribe({
      next: (res) => {
        if (res[0]?.samples) {
          this.coachingStatistics = res[0].samples.filter(
            (sample: any) =>
              sample.sampleType === 'statistics' || sample.sampleType === 'statistic'
          );
          this.coachingExamples = res[0].samples.filter(
            (sample: any) => sample.sampleType === 'example'
          );
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  openModalExamples(): void {
    const dialogConfig = {
      width: '800px',
      height: '600px',
      data: {
        title: 'Coaching Script Examples',
        content: this.coachingExamples,
      },
      id: 'coaching-samples',
    };

    this.dialog.open(DialogCoachingSamplesComponent, dialogConfig);
  }

  openModalStatistics(): void {
    const dialogConfig = {
      width: '800px',
      height: '600px',
      data: {
        title: 'Coaching Script Statistics',
        content: this.coachingStatistics,
      },
      id: 'coaching-samples',
    };

    this.dialog.open(DialogCoachingSamplesComponent, dialogConfig);
  }

  onNavClick(elementId: string): void {
    this.dialogEventService.onNavClick(elementId);
  }

  isRowSelected(id: string): boolean {
    return id === this.selectedRow;
  }

  onRowClick(data: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.EVENT);
    this.selectedRow = data?.id;
    data.driverName = this.dataRow.driverName;
    data.driverId = this.driverId;
    data.terminal = this.dataRow.terminal;
    data.type = data.eventType;
    data.id = data.eventId;

    setTimeout(() => {
      const dialogWidth = calculateDialogWidth(
        this.flyoutService.getFlyoutOffsetBasedOnIndex()
      );

      this.dialog
        .open(DialogEventComponent, {
          data: { ...data, showInfo: true, uniqueIdentifier: FlyoutType.EVENT },
          position: {
            right: '0',
            top: '70px',
          },
          width: dialogWidth,
          minWidth: dialogWidth,
          panelClass: ['dialog-event', 'animate-slide-in-left'],
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.selectedRow = null;
        });
    }, 100);
  }

  ngAfterViewInit(): void {
    this.attachScrollListener();
  }

  private attachScrollListener(): void {
    const articleElement = this.scrollContainer.nativeElement;
    articleElement.addEventListener('scroll', () => {
      this.onContainerScroll();
    });
  }

  private onContainerScroll(): void {
    let closestSection = null;
    let minDistance = Infinity;

    this.sections.forEach((section) => {
      const element = this.scrollContainer.nativeElement.querySelector(`#${section.id}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const distance = Math.abs(
          rect.top - this.scrollContainer.nativeElement.getBoundingClientRect().top
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestSection = section.id;
        }
      }
    });

    if (closestSection !== null) {
      this.sectionSelected = closestSection;
    }
  }

  onEditorContentChange(newcoachingScriptContents: string) {
    this.changesAmount = this.changesAmount + 1;
    this.coachingScriptContents = newcoachingScriptContents;
    if (this.changesAmount > 1) {
      this.showSaveButton = true;
    }
    if (this.coachingScriptContents === this.coachingScriptOriginalContents) {
      this.showSaveButton = false;
    }
  }

  saveCoachingScript(): void {
    this.showSaveButton = false;
    const request = {
      id: this.dataRow?.id,
      lastModifiedBy: this.dataRow?.lastModifiedBy,
      coachingScript: this.coachingScriptContents,
      organizationId: environment.organizationId,
    };
    this.interventionService.upsertIntervention(request).subscribe({
      next: () => {
        this.coachingScriptOriginalContents = this.coachingScriptContents;
        this.toasterService.showSuccess(`The Coaching Script was updated successfully`);
      },
      error: (error) => console.error(error),
    });
  }

  onUnlinkIntervention(intervention: any) {
    const data = {
      title: 'Unlink Intervention',
      text: 'Are you sure you want to unlink the intervention?',
      yesButtonText: 'Unlink',
      noButtonText: 'Cancel',
      noWarn: true,
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '500px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.unlinkIntervention(intervention);
        }
      });
  }

  getUserIdFromLocalStorage(): string {
    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }
    return userId;
  }

  unlinkIntervention(intervention: any): void {
    const relationshipRequest = {
      id: intervention.id,
      eventId: intervention.eventId,
      interventionId: intervention.interventionId,
      userId: this.getUserIdFromLocalStorage(),
      deleted: true,
    };

    this.interventionService
      .relationshipEventIntervention(relationshipRequest)
      .subscribe({
        next: () => {
          this.fetchLinkedEvents(true);
          this.interventionService.announceSelectionChange('update');
        },
        error: (relationshipError) => {
          console.error(relationshipError);
        },
      });
  }

  canLinkEventstoInterventions(): boolean {
    const userId = localStorage.getItem('objectId');
    return (
      (this.permissionService.hasPermission('WRITE', 'All Interventions') &&
        this.permissionService.hasPermission('WRITE', 'Events')) ||
      (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
        this.userId === userId &&
        this.permissionService.hasPermission('WRITE', 'Events'))
    );
  }

  canViewInterventions(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'All Interventions') ||
      this.permissionService.hasPermission('WRITE', 'All Interventions')
    );
  }

  canViewInterventionNotes(): boolean {
    const userId = localStorage.getItem('objectId');
    return (
      this.permissionService.hasPermission('READ', 'Intervention Notes') ||
      this.permissionService.hasPermission('WRITE', 'Intervention Notes') ||
      (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
        this.userId === userId)
    );
  }

  canEditInterventions(): boolean {
    const userId = localStorage.getItem('objectId');
    return (
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
        this.userId === userId)
    );
  }

  updateColumns(): void {
    this.linkedEventColumns = ['type', 'criteria', 'severity', 'date', 'status'];

    if (this.canLinkEventstoInterventions()) {
      this.linkedEventColumns.push('menu');
    }
  }
}
