import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, filter, forkJoin, takeUntil } from 'rxjs';

import { SelectOptionItem } from '@app/shared/models';
import { convertToUSDateFormatWithTime12H } from '@app/shared/helpers/date.helper';
import { StatesList } from '@app/drivers/dialog-driver/dialog-driver.constants';
import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';
import { DialogFileUploadComponent } from '@app/shared/components/dialog-file-upload/dialog-file-upload.component';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { DriverService } from '@app/services/driver.service';
import { ToasterService } from '@app/services/toaster.service';
import { PermissionService } from '@app/services/permission.service';
import { UserManagementService } from '@app/services/user-management.service';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';

@Component({
  selector: 'app-dialog-driver-accident',
  templateUrl: './dialog-driver-accident.component.html',
  styleUrls: ['./dialog-driver-accident.component.scss'],
})
export class DialogDriverAccidentComponent implements OnInit, OnDestroy {
  states: SelectOptionItem[] = StatesList.map((item: string) => ({
    label: item,
    value: item,
  }));
  accidentData!: any;
  disabled: boolean = true;
  showActionItems: boolean = false;
  convertToUSDateFormatWithTime12H = convertToUSDateFormatWithTime12H;
  accidentForm!: FormGroup;

  assetLocationOptions: any[] = [];
  crashTypeOptions: any[] = [];
  equipmentDamageOptions: any[] = [];
  othersInvolvedOptions: any[] = [];
  policeCalledOptions: any[] = [];
  radioDefaultOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  users: any[] = [];
  isLoading: boolean = false;
  readOnly: boolean = false;

  cssContent: string = '';
  isLoadingPrintWeb: boolean = false;
  showPrintComponent: boolean = false;

  selectedOrganization: any = {};

  private destroy$ = new Subject<void>();
  private utcDatePipe = new UtcDatePipe();
  private printIframe!: HTMLIFrameElement | null;

  @ViewChild('driverIdInput') driverIdInput!: ElementRef;
  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogDriverAccidentComponent>,
    private dialogDriverService: DialogDriverService,
    private fb: FormBuilder,
    private driverService: DriverService,
    private toasterService: ToasterService,
    private permissionService: PermissionService,
    private userManagementService: UserManagementService,
    private http: HttpClient
  ) {
    this.initializeAccidentForm();
  }

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.setReadOnlyView();
    this.fetchAll();
    if (!this.canEditAccidents()) {
      this.accidentForm.disable();
    }
    this.loadPrintStyles();
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  loadPrintStyles(): void {
    this.http
      .get('assets/css/print-screen-accident.css', { responseType: 'text' })
      .subscribe({
        next: (css) => {
          this.cssContent = css;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  getCurrentUser() {
    const userDetailsString = localStorage.getItem('userData');
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      const { objectId: userId, firstName, lastName } = userDetails.userInfo;
      return { userId, firstName, lastName };
    } else {
      console.error('User details not found in local storage');
      return null;
    }
  }

  isSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome');
  }

  generateModalHTML(): string {
    const eventTitle = `${this.accidentData?.driverId} - Accident`;

    const currentUser = this.getCurrentUser();
    const userName = currentUser
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : 'Unknown User';

    const formattedDate = new Date(this.accidentData?.accidentDate).toLocaleDateString(
      'en-US',
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }
    );

    return `
      <html>
        <head>
          <title>${eventTitle} - ${formattedDate} (Generated for ${userName})</title>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
          <style>${this.cssContent}</style>
        </head>
        <body>
          <div class="button-container">
            <button onclick="window.close()">Go Back</button>
            <button onclick="window.print()">Print</button>
          </div>
          <div class="printable-modal">
            ${this.modalContainer.nativeElement.innerHTML}
          </div>
        </body>
      </html>
    `;
  }

  printContent(): void {
    this.isLoadingPrintWeb = true;
    this.showPrintComponent = true;

    if (this.isSafari()) {
      setTimeout(() => {
        if (this.modalContainer) {
          const modalHTML = this.generateModalHTML();

          // Reuse the iframe if it has already been created
          if (!this.printIframe) {
            this.printIframe = document.createElement('iframe');
            this.printIframe.style.position = 'absolute';
            this.printIframe.style.width = '0px';
            this.printIframe.style.height = '0px';
            this.printIframe.style.border = 'none';
            document.body.appendChild(this.printIframe);
          }

          const iframeDoc =
            this.printIframe.contentWindow?.document || this.printIframe.contentDocument;
          if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(modalHTML);
            iframeDoc.close();

            // Wait for the iframe to load before executing print
            this.printIframe.onload = () => {
              this.printIframe?.contentWindow?.focus();
              this.printIframe?.contentWindow?.print();
            };
          }
        }
        this.isLoadingPrintWeb = false;
        this.showPrintComponent = false;
      }, 1000);
    } else {
      setTimeout(() => {
        if (this.modalContainer) {
          const modalHTML = this.generateModalHTML();

          const blob = new Blob([modalHTML], { type: 'text/html' });
          const url = URL.createObjectURL(blob);

          const printWindow = window.open(url, '_blank');

          if (printWindow) {
            printWindow.onload = () => {};
          }
        }
        this.isLoadingPrintWeb = false;
        this.showPrintComponent = false;
      }, 1000);
    }
  }

  setReadOnlyView(): void {
    if (this.data?.readOnly !== undefined && this.data?.readOnly !== null) {
      this.readOnly = this.data.readOnly;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    const url = new URL(window.location.href);
    url.searchParams.delete('activity');
    url.searchParams.delete('eventId');

    window.history.replaceState({}, '', url.toString());
  }

  get showCallHazmat(): boolean {
    return this.accidentForm.get('fuelHazardousSpilled')?.value;
  }

  get showCallGeneOrLaura(): boolean {
    return this.accidentForm.get('fatalityInvolved')?.value;
  }

  get showScheduleAccidentTesting(): boolean {
    return (
      this.accidentForm.get('fatalityInvolved')?.value ||
      this.accidentForm.get('othersRequireMedical')?.value ||
      this.accidentForm.get('othersVehicleTowed')?.value ||
      this.accidentForm.get('assetsLocated')?.value === 1
    );
  }

  fetchAll(): void {
    this.isLoading = true;
    forkJoin({
      crashTypes: this.driverService.getAccidentOptionsList('CrashType'),
      assetLocations: this.driverService.getAccidentOptionsList('AssetLocation'),
      equipmentDamage: this.driverService.getAccidentOptionsList('EquipmentDamage'),
      othersInvolved: this.driverService.getAccidentOptionsList('OthersInvolved'),
      policeCalled: this.driverService.getAccidentOptionsList('PoliceCalled'),
      accidentDetails: this.dialogDriverService.getAccidentDetail(this.data.id),
    }).subscribe({
      next: ({
        crashTypes,
        assetLocations,
        equipmentDamage,
        othersInvolved,
        policeCalled,
        accidentDetails,
      }) => {
        this.addFormattedDataToAccidentForm(
          crashTypes,
          assetLocations,
          equipmentDamage,
          othersInvolved,
          policeCalled,
          accidentDetails
        );
        this.setCrashType();
        this.setAssetsLocated();
        this.setEquipmentDamage();
        this.setOthersInvolved();
        this.setPoliceCalled();
        this.fetchUsers();
        this.setManagersInForm();
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  fetchUsers(): void {
    const request = { organizationId: this.selectedOrganization.organizationId };
    this.userManagementService
      .getUsers(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.users = res[0]?.userResults;
          this.assignUserData('createdBy');
          this.assignUserData('lastModifiedBy');
          this.accidentData.creationDate = this.utcDatePipe.transform(
            this.accidentData.creationDate,
            'M/d/yyyy h:mm A'
          );
          this.accidentData.lastModifiedDate = this.utcDatePipe.transform(
            this.accidentData.lastModifiedDate,
            'M/d/yyyy h:mm A'
          );
          this.accidentForm.patchValue(this.accidentData);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  assignUserData(field: 'createdBy' | 'lastModifiedBy'): void {
    const user = this.users.find((user) => user.userId === this.accidentData[field]);
    if (user) {
      this.accidentData[field] = `${user.firstName} ${user.lastName}`;
    }
  }

  addFormattedDataToAccidentForm(
    crashTypes: any,
    assetLocations: any,
    equipmentDamage: any,
    othersInvolved: any,
    policeCalled: any,
    accidentDetails: any
  ): void {
    this.crashTypeOptions = crashTypes.sort((a: any, b: any) => a.id - b.id);
    this.assetLocationOptions = assetLocations.sort((a: any, b: any) => a.id - b.id);
    this.equipmentDamageOptions = equipmentDamage
      .sort((a: any, b: any) => a.id - b.id)
      .map((damage: any) => {
        return { label: damage.description, value: damage.id };
      });
    this.othersInvolvedOptions = othersInvolved
      .sort((a: any, b: any) => a.id - b.id)
      .map((involved: any) => {
        return {
          label: involved.description,
          value: involved.id,
        };
      });
    this.policeCalledOptions = policeCalled
      .sort((a: any, b: any) => a.id - b.id)
      .map((called: any) => {
        return {
          label: called.description,
          value: called.id,
        };
      });
    this.accidentData = accidentDetails[0];
    this.accidentForm.patchValue(this.accidentData);
  }

  setCrashType(): void {
    const crashTypeObject = this.crashTypeOptions.find(
      (option) => option.description === this.accidentData.crashType
    );
    if (crashTypeObject) {
      this.accidentForm.get('crashType')?.setValue(crashTypeObject.id);
      this.accidentData.crashType = crashTypeObject.id;
    }
  }

  setAssetsLocated(): void {
    const assetLocationObject = this.assetLocationOptions.find(
      (option) => option.description === this.accidentData.assetsLocated
    );
    if (assetLocationObject) {
      this.accidentForm.get('assetsLocated')?.setValue(assetLocationObject.id);
    }
  }

  setEquipmentDamage(): void {
    const equipmentDamageObject = this.equipmentDamageOptions.find(
      (option) => option.label === this.accidentData.driverEquipmentDamage
    );
    if (equipmentDamageObject) {
      this.accidentForm
        .get('driverEquipmentDamage')
        ?.setValue(equipmentDamageObject.value);
    }
  }

  setOthersInvolved(): void {
    const othersInvolvedObject = this.othersInvolvedOptions.find(
      (option) => option.label === this.accidentData.othersInvolved
    );
    if (othersInvolvedObject) {
      this.accidentForm.get('othersInvolved')?.setValue(othersInvolvedObject.value);
    }
  }

  setPoliceCalled(): void {
    const policeCalledObject = this.policeCalledOptions.find(
      (option) => option.label === this.accidentData.policeCalled
    );
    if (policeCalledObject) {
      this.accidentForm.get('policeCalled')?.setValue(policeCalledObject.value);
    }
  }

  setManagersInForm(): void {
    if (this.accidentData?.managers && this.accidentData.managers.length > 0) {
      const fleetManager = this.accidentData.managers.find(
        (manager: any) => manager.title === 'Fleet Manager'
      );
      const safetyTrainer = this.accidentData.managers.find(
        (manager: any) => manager.title === 'Safety Trainer'
      );
      const safetyManager = this.accidentData.managers.find(
        (manager: any) => manager.title === 'Safety Manager'
      );

      if (fleetManager) {
        this.accidentForm.get('fleetManager')?.setValue(fleetManager.managerName);
      }

      if (safetyTrainer) {
        this.accidentForm.get('safetyTrainer')?.setValue(safetyTrainer.managerName);
      }

      if (safetyManager) {
        this.accidentForm.get('safetyManager')?.setValue(safetyManager.managerName);
      }
    }
  }

  initializeAccidentForm(): void {
    this.accidentForm = this.fb.group({
      id: [this.data.id],
      callHazmatResponse: [null],
      callAdminsInformFatality: [null],
      scheduleDriverTesting: [null],
      driverId: [{ value: null, disabled: true }],
      driverName: [{ value: null, disabled: true }],
      driverPhone: [null],
      organization: [{ value: null, disabled: true }],
      terminal: [{ value: null, disabled: true }],
      fleetManager: [{ value: null, disabled: true }],
      safetyTrainer: [{ value: null, disabled: true }],
      safetyManager: [{ value: null, disabled: true }],
      accidentDate: [null],
      accidentCity: [null],
      accidentState: [null],
      accidentZip: [null],
      accidentAddress1: [null],
      accidentAddress2: [null],
      accidentLocationOtherDetails: [null],
      fatalityInvolved: [null],
      crashType: [null],
      assetsLocated: [null],
      accidentDescription: [null],
      accidentOnRoadway: [null],
      fuelHazardousSpilled: [null],
      deployedTriangleFlares: [null],
      witnessInformation: [null],
      driverInjured: [null],
      driverRequireMedical: [null],
      driverInjuryDescription: [null],
      driverTruckNumber: [null],
      driverTrailerNumber: [null],
      driverCargoCondition: [null],
      driverVehicleTowed: [null],
      driverVehicleTowedInformation: [null],
      driverEquipmentDamage: [null],
      driverEquipmentDamageDescription: [null],
      othersInvolved: [null],
      othersInvolvedDetails: [null],
      othersInjured: [null],
      othersInjuryDescription: [null],
      othersRequireMedical: [null],
      othersVehiclePropertyDamage: [null],
      othersVehicleTowed: [null],
      othersVehiclePropertyDamageDescription: [null],
      othersVehicleTowedInformation: [null],
      policeCalled: [null],
      citationReceived: [null],
      policeReportNumber: [null],
      policeOfficerInformation: [null],
      creationDate: [{ value: null, disabled: true }],
      createdBy: [{ value: null, disabled: true }],
      lastModifiedBy: [{ value: null, disabled: true }],
      lastModifiedDate: [{ value: null, disabled: true }],
    });
  }

  onDialogCloseClick(): void {
    if (this.accidentForm.touched) {
      const data = {
        title: 'Unsaved changes',
        text: 'You have unsaved changes. Are you sure you want to leave this page? The changes you made will not be saved.',
      };
      this.dialog
        .open(DialogConfirmComponent, { data, width: '500px' })
        .afterClosed()
        .pipe(filter((closeModal: boolean) => closeModal))
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  onSupportingMediaClick(): void {
    this.dialog.open(DialogFileUploadComponent, {
      data: { title: 'Supporting media' },
      width: '660px',
    });
  }

  convertDateFormat(dateStr: string) {
    const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    const amPmFormat = /^\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2} (AM|PM)$/;

    if (isoFormat.test(dateStr)) {
      return dateStr.replace('T', ' ');
    } else if (amPmFormat.test(dateStr)) {
      const parts = dateStr.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{1,2}):(\d{2}) (AM|PM)/);
      if (parts) {
        const [, month, day, year, hour, minute, period] = parts;
        let hourNumber = parseInt(hour, 10);
        if (period === 'PM' && hourNumber < 12) {
          hourNumber += 12;
        }
        if (period === 'AM' && hourNumber === 12) {
          hourNumber = 0;
        }
        const hourFormatted = hourNumber.toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hourFormatted}:${minute}:00`;
      }
    }
    return dateStr;
  }

  save(): void {
    const accidentDate = this.accidentForm.get('accidentDate')?.value
      ? this.convertDateFormat(this.accidentForm.get('accidentDate')?.value)
      : '';

    const reportedDate = this.accidentForm.get('reportedDate')?.value
      ? this.convertDateFormat(this.accidentForm.get('reportedDate')?.value)
      : '';

    const formValues = { ...this.accidentForm.getRawValue() };
    formValues.driverId = this.accidentData.driverId;
    formValues.accidentDate = accidentDate;
    formValues.reportedDate = reportedDate;
    if (!this.showCallHazmat) {
      formValues.callHazmatResponse = null;
    }

    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      formValues.UserId = parsedData.userInfo.objectId;
    }

    formValues.organizationId = this.selectedOrganization.organizationId;

    this.driverService.upsertAccident(formValues).subscribe({
      next: () => {
        this.dialogRef.close();
        this.toasterService.showSuccess('The Accident Detail was edited successfully');
      },
      error: (error) => {
        this.toasterService.showError(error.error);
        console.error('Error saving accident', error);
      },
    });
  }

  getWhatsDamagedSelected(optionSelected?: string): string {
    if (optionSelected === 'Only the Trailer') {
      return 'trailerOnly';
    }
    if (optionSelected === 'Only the Truck') {
      return 'truckOnly';
    }
    if (optionSelected === 'Both') {
      return 'both';
    }
    if (optionSelected === 'No Damage') {
      return 'noDamage';
    }
    return '';
  }

  getWhoWasInvolvedSelected(optionSelected?: string): string {
    if (optionSelected === 'No One Else') {
      return 'noOneElse';
    }
    if (optionSelected === 'Empty Vehicle or Property') {
      return 'emptyVehicleOrProperty';
    }
    if (optionSelected === 'Occupied Vehicle') {
      return 'occupiedVehicle';
    }
    if (optionSelected === 'Pedestrian') {
      return 'pedestrian';
    }
    if (optionSelected === 'Other Party Info') {
      return 'otherPartyInfo';
    }
    return 'noOneElse';
  }

  getAddressLine(): string {
    return (
      this.accidentData?.accidentAddress1 + ' ' + this.accidentData?.accidentAddress2
    );
  }

  canEditAccidents(): boolean {
    return !this.readOnly && this.permissionService.hasPermission('WRITE', 'Accident');
  }
}
