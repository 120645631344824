import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

import { ISpeedPercentageElement } from '@app/models/speed.model';
import { addSpacesToCamelCase } from '@app/shared/helpers/string.helper';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { PaginationParams } from '@app/models/pagination-params';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-speeding-list',
  templateUrl: './percentage-speeding-list.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class PercentageSpeedingListComponent {
  @Input() dateRange: any;
  @Input() driverGUID!: string;
  @Input() maxDisplayedRecords!: number;
  @Input() defaultPageSize!: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  speedingList!: ISpeedPercentageElement[];
  speedingDataFiltered: ISpeedPercentageElement[] = [];
  speedingColumns: string[] = [
    'date',
    'milesDriven',
    'evaluationPeriod',
    'speedingPeriod',
    'speeding',
  ];
  isLoading: boolean = false;
  selectedRow: { sectionName: string; rowId?: string } | null = null;
  dateRangeSubscription!: Subscription;

  isViewMore: boolean = true;
  pageSize!: number;
  paginationParams!: PaginationParams;
  pageEvent!: PageEvent;
  startPageIdx!: number;
  endPageIdx!: number;
  showViewMore: boolean = false;
  totalRecordCount: number = 10000;
  isExpandViewTriggered: boolean = false;

  subscription!: Subscription;

  private eventIdFromUrl?: string;

  constructor(
    private dialogInfoService: DialogDriverService,
    private expandViewService: ExpandViewService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setPaginatorConfig();
    this.getSpeedSummary();
    this.setDateRangeSubscription();
    this.checkUrlParameters();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  private checkUrlParameters(): void {
    const params = this.route.snapshot.queryParams;
    if (params['activity'] === 'percentage-speeding') {
      this.eventIdFromUrl = params['eventId'];
      this.onRowClick({ id: this.eventIdFromUrl });
      this.eventIdFromUrl = undefined;
    }
  }

  setPaginatorConfig(): void {
    this.paginationParams = {
      limit: this.defaultPageSize,
      offset: 0,
      total: 0,
    };
    this.pageEvent = {
      length: this.paginationParams.total || 0,
      pageIndex: 0,
      pageSize: this.paginationParams.limit,
    };
    this.pageSize = this.maxDisplayedRecords;
    this.startPageIdx = 1;
  }

  setDateRangeSubscription(): void {
    this.dateRangeSubscription = this.dialogInfoService.dateChange$.subscribe(() =>
      this.getSpeedSummary()
    );
  }

  ngOnDestroy(): void {
    if (this.dateRangeSubscription) {
      this.dateRangeSubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'percentage-speeding' });
    this.updateUrlParams({ activity: 'percentage-speeding', eventId: row.id });
  }

  private updateUrlParams(params: { [key: string]: string }): void {
    const url = new URL(window.location.href);
    Object.keys(params).forEach((key) => {
      url.searchParams.set(key, params[key]);
    });
    window.history.replaceState({}, '', url.toString());
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  getSpeedSummary(fetchAllRecords: boolean = false): void {
    this.isLoading = true;
    const { start, end } = this.dateRange.value;
    if (start && end) {
      const request = {
        startDate: start,
        endDate: end,
        driverGUID: this.driverGUID,
        page: this.pageEvent.pageIndex + 1,
        pageSize: fetchAllRecords ? this.totalRecordCount : this.pageEvent.pageSize,
      };
      this.dialogInfoService.getPercentageSpeedingSummary(request).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.speedingList = response[0].speedPercentageSummaries;
          this.totalRecordCount = response[0].totalRecordCount;
          this.paginationParams.total = this.totalRecordCount;
          if (fetchAllRecords) {
            this.pageEvent.pageSize = this.totalRecordCount;
            this.pageSize = this.totalRecordCount;
            this.endPageIdx = this.totalRecordCount;
          }
          if (this.totalRecordCount) {
            this.setDataFilteredAndEndPageIdx();
            this.setShowViewMore();
          }
          if (this.isExpandViewTriggered) {
            this.expandViewService.incrementExpandView();
            this.isExpandViewTriggered = false;
          }
        },
        error: (error: any) => {
          this.isLoading = false;
          console.error('Error:', error);
        },
      });
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    const startIdx = event.pageIndex * event.pageSize;
    const endIdx = startIdx + event.pageSize;
    this.startPageIdx = startIdx + 1;
    this.endPageIdx = endIdx < this.totalRecordCount ? endIdx : this.totalRecordCount;
    this.getSpeedSummary();
  }

  setDataFilteredAndEndPageIdx(): void {
    if (!this.endPageIdx) {
      this.endPageIdx =
        this.defaultPageSize < this.totalRecordCount
          ? this.defaultPageSize
          : this.totalRecordCount;
    }
    this.speedingDataFiltered = this.speedingList?.slice(0, this.pageSize);
  }

  setShowViewMore(): void {
    if (this.speedingList.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  addSpacesToCamelCase(str: string): string {
    return addSpacesToCamelCase(str);
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.setPaginatorConfig();
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.getSpeedSummary(expandViewMore);
  }
}
