import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Material
import { MatDialogModule } from '@angular/material/dialog';

import { MobileSearchModalComponent } from './mobile-search-modal.component';

@NgModule({
  declarations: [MobileSearchModalComponent],
  exports: [MobileSearchModalComponent],
  imports: [CommonModule, FormsModule, MatDialogModule],
})
export class MobileSearchModalModule {}
