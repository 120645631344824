import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, debounceTime, map, of, startWith, switchMap } from 'rxjs';

import { EventService } from '@app/services/event.service';
import { InterventionService } from '@app/services/intervention.service';
import { ToasterService } from '@app/services/toaster.service';
import { formatDate } from '@angular/common';
import { AssigneeType } from '@app/models/status.model';
import { DriverIdSearchComponent } from './driver-id-search/driver-id-search.component';

@Component({
  selector: 'app-dialog-new-intervention',
  templateUrl: './dialog-new-intervention.component.html',
  styleUrls: ['./dialog-new-intervention.component.scss'],
})
export class DialogNewInterventionComponent implements OnInit {
  interventionForm!: FormGroup;
  linkedEventControl = new FormControl();
  filteredEvents!: Observable<any[]>;
  selectedEvents: any[] = [];

  priorities = [
    { value: 'Low', label: 'Low', icon: 'fa-solid fa-arrow-down' },
    { value: 'Medium', label: 'Medium', icon: 'fa-solid fa-equals' },
    { value: 'High', label: 'High', icon: 'fa-solid fa-arrow-up' },
  ];
  dueDate: string = '';
  contents!: string;
  terminal: string = '';

  driver: any = {
    driverId: null,
    driverName: null,
    terminal: null,
  };
  isLinkedEventEnabled: boolean = false;

  organizations: any[] = [];
  selectedOrganization: any;
  isFormEnabled: boolean = false;

  @ViewChild('linkedEventInput')
  linkedEventInput!: ElementRef<HTMLInputElement>;

  @ViewChild(DriverIdSearchComponent)
  driverIdSearchComponent!: DriverIdSearchComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private eventService: EventService,
    private interventionService: InterventionService,
    private dialogRef: MatDialogRef<DialogNewInterventionComponent>,
    private toasterService: ToasterService
  ) {
    this.setupLinkedEventAutocomplete();
  }

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.setInitForm();
    this.setInitData();
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  setInitData(): void {
    this.isFormEnabled = true;
    this.interventionForm.get('organization')?.setValue(this.selectedOrganization);

    if (this.data?.event) {
      this.selectedEvents.push(this.data.event);
      this.linkedEventControl.setValue(this.selectedEvents);
      this.interventionForm.get('linkedEvent')?.setValue(this.selectedEvents);
      this.interventionForm.get('driverId')?.setValue(this.data.event.driverId);
      this.interventionForm
        .get('reason')
        ?.setValue(this.data.event.behavior.substring(0, 100));
      this.setDriver(
        this.data?.event.driverId,
        this.data?.event.driverName,
        this.data?.event.terminal
      );
    }
    if (this.data?.driver) {
      this.setDriver(
        this.data?.driver.driverId,
        this.data?.driver.driverName,
        this.data?.driver.terminal
      );
      this.interventionForm.get('driverId')?.setValue(this.data.driver.driverId);
    }
  }

  onOrganizationChange(newOrganization: any): void {
    this.selectedOrganization = newOrganization.value;
    this.interventionForm.get('organization')?.setValue(this.selectedOrganization);
    this.isFormEnabled = true;

    // Enable all form controls
    this.enableAllFormControls();

    // Reset driver data
    this.driver = {
      driverId: null,
      driverName: null,
      terminal: null,
    };
    this.interventionForm.get('driverId')?.setValue(null);

    // Reset the driver search component
    if (this.driverIdSearchComponent) {
      this.driverIdSearchComponent.reset();
    }

    // Reset linked events
    this.resetSelectedEvents();
  }

  private enableAllFormControls(): void {
    Object.keys(this.interventionForm.controls).forEach((controlName) => {
      this.interventionForm.get(controlName)?.enable();
    });
  }

  setDriver(driverId: string, driverName: string, terminal: string): void {
    this.driver.driverId = driverId;
    this.driver.driverName = driverName;
    this.driver.terminal = terminal;
  }

  setInitForm(): void {
    this.interventionForm = this.fb.group({
      organization: [null],
      driverId: [null, Validators.required],
      interventionType: [{ value: 'Coaching', disabled: true }, Validators.required],
      reason: ['', Validators.required],
      assignee: ['', Validators.required],
      coachingScript: [''],
      priority: ['Medium'],
      dueDate: [null],
      linkedEvent: [null],
    });
  }

  setupLinkedEventAutocomplete(): void {
    this.filteredEvents = this.linkedEventControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => {
        const driverId = this.interventionForm.get('driverId')?.value;
        if (driverId) {
          return this.searchEvents(value);
        }
        return of([]);
      })
    );
  }

  searchEvents(searchTerm: string): Observable<any[]> {
    const driverId = this.interventionForm.get('driverId')?.value;
    if (driverId && typeof searchTerm === 'string') {
      const params = {
        searchField: searchTerm,
        driverId,
        organizationId: this.selectedOrganization?.organizationId,
      };
      return this.eventService.getEventsList(params).pipe(
        map((res: any) => res[0].eventSummaries),
        map((events) => {
          if (!events.length) {
            return [];
          }
          return events.filter(
            (event: any) =>
              !this.selectedEvents.some((selectedEvent) => selectedEvent.id === event.id)
          );
        })
      );
    }
    return this.filteredEvents;
  }

  displayFn(event: any): string {
    return event ? `${event.driverId} - ${event.eventType} - ${event.date}` : '';
  }

  getEventSelected(): string {
    const event = this.selectedEvents[0];
    const formattedProcessDate = formatDate(
      event.processDate,
      'M/d/yyyy',
      'en-US',
      'UTC'
    );

    return `${event.driverId} - ${event.type} - ${formattedProcessDate}`;
  }

  selectEvent(event: any): void {
    if (!this.selectedEvents.some((e) => e.id === event.id)) {
      this.selectedEvents.push(event);
      this.interventionForm.get('linkedEvent')?.setValue(this.selectedEvents);
    }
    this.linkedEventControl.setValue(null);
    this.linkedEventInput.nativeElement.value = '';
    this.addEventValueToReason(event?.behavior);
  }

  addEventValueToReason(value: string): void {
    if (value) {
      const currentReason = this.interventionForm.get('reason')?.value || '';
      const newReason = currentReason ? `${currentReason}, ${value}` : value;
      this.interventionForm.get('reason')?.setValue(newReason.substring(0, 100));
    }
  }

  removeEvent(event: any): void {
    const index = this.selectedEvents.indexOf(event);
    if (index >= 0) {
      this.selectedEvents.splice(index, 1);
      if (this.selectedEvents.length) {
        this.interventionForm.get('linkedEvent')?.setValue(this.selectedEvents);
      } else {
        this.interventionForm.get('linkedEvent')?.setValue(null);
      }
      this.linkedEventInput.nativeElement.value = '';
    }
  }

  resetSelectedEvents(): void {
    this.linkedEventInput.nativeElement.value = '';
    this.selectedEvents = [];
    this.interventionForm.get('linkedEvent')?.setValue(null);
  }

  handleDriverIdSelected(driver: any): void {
    if (driver) {
      this.terminal = driver.terminal;
      this.interventionForm.get('driverId')?.setValue(driver.driverId);
      this.isLinkedEventEnabled = true;
      this.linkedEventControl.setValue('');
    } else {
      this.terminal = '';
      this.interventionForm.get('driverId')?.setValue('');
      this.interventionForm.get('linkedEvent')?.setValue(null);
      this.isLinkedEventEnabled = false;
      this.linkedEventControl.setValue(null);
      this.selectedEvents = [];
    }
  }

  getPriorityIcon(value: string): string {
    const priority = this.priorities.find((p) => p.value === value);
    return priority ? priority.icon : '';
  }

  getPriorityLabel(value: string): string {
    const priority = this.priorities.find((p) => p.value === value);
    return priority ? priority.label : '';
  }

  updateDueDate(dueDate: string): void {
    this.interventionForm.get('dueDate')?.setValue(dueDate);
  }

  onCreate(): void {
    let assignee = '';
    if (this.interventionForm.get('assignee')?.value === AssigneeType.UNASSIGNED) {
      assignee = AssigneeType.UNASSIGNED;
    } else {
      assignee = this.interventionForm.get('assignee')?.value?.userId;
    }

    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }

    const organizationId = this.selectedOrganization?.organizationId;

    const request = {
      interventionType: this.interventionForm.get('interventionType')?.value,
      priority: this.interventionForm.get('priority')?.value,
      driverId: this.interventionForm.get('driverId')?.value,
      terminal: this.terminal,
      dueDate: this.interventionForm.get('dueDate')?.value,
      reason: this.interventionForm.get('reason')?.value,
      assignee,
      createdBy: userId,
      lastModifiedBy: userId,
      status: 'Open',
      coachingScript: this.interventionForm.get('coachingScript')?.value,
      organizationId: organizationId,
    };
    this.interventionService.upsertIntervention(request).subscribe({
      next: (res) => {
        const insertedId = res[0].insertedId;
        this.createRelationships(insertedId);

        this.toasterService.showSuccess('The Intervention was created successfully');
        this.dialogRef.close(true);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getUserIdFromLocalStorage(): string {
    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }
    return userId;
  }

  createRelationships(insertedId: string): void {
    setTimeout(() => {
      this.selectedEvents.forEach((event) => {
        const relationshipRequest = {
          eventId: event.id,
          interventionId: insertedId,
          userId: this.getUserIdFromLocalStorage(),
          organizationId: this.selectedOrganization?.organizationId,
        };

        this.interventionService
          .relationshipEventIntervention(relationshipRequest)
          .subscribe({
            next: () => {},
            error: (relationshipError) => {
              console.error(relationshipError);
            },
          });
      });
    }, 2000);
  }

  onEditorContentChange(newContents: string) {
    this.contents = newContents;
    this.interventionForm.get('coachingScript')?.setValue(newContents);
  }

  handleAssigneeSelected(assignee: any): void {
    if (assignee) {
      this.interventionForm.get('assignee')?.setValue(assignee);
    } else {
      this.interventionForm.get('assignee')?.setValue('');
    }
  }
}
