<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>{{ title }}</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="search-input-wrapper">
    <input
      #mobileSearchInput
      type="text"
      [(ngModel)]="searchValue"
      (keydown.enter)="onSearch()"
      [placeholder]="placeholder"
      class="search-input"
    />
    <span class="search-icon" (click)="onSearch()">
      <i class="fas fa-search"></i>
    </span>
    <span *ngIf="searchValue" class="clear-link" (click)="onClear()"> Clear </span>
  </div>
</mat-dialog-content>
