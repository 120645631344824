import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  Observable,
  Subject,
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';

import { Section } from '@app/models';
import { Note } from '@app/models/note.model';
import { TabDetailsSections } from '@app/shared/components/dialog-event/dialog-event.constants';
import { DialogEventService } from '@app/shared/components/dialog-event/dialog-event.service';
import { DialogNewInterventionComponent } from '@app/shared/components/dialog-new-intervention/dialog-new-intervention.component';
import { ActivitySectionName } from '@app/models/grid.model';
import { DialogDriverAccidentComponent } from '@app/drivers/dialog-driver/dialog-driver-accident/dialog-driver-accident.component';
import { DialogDriverInjuryComponent } from '@app/drivers/dialog-driver/dialog-driver-injury/dialog-driver-injury.component';
import { DialogDriverTelematicsComponent } from '@app/drivers/dialog-driver/dialog-driver-telematics/dialog-driver-telematics.component';
import { DialogDriverHoursOfServiceComponent } from '@app/drivers/dialog-driver/dialog-driver-hours-of-service/dialog-driver-hours-of-service.component';
import { DialogDriverInspectionComponent } from '@app/drivers/dialog-driver/dialog-driver-inspection/dialog-driver-inspection.component';
import { DialogDriverSpeedComponent } from '@app/drivers/dialog-driver/dialog-driver-speed/dialog-driver-speed.component';
import { DialogDriverPercentageSpeedingComponent } from '@app/drivers/dialog-driver/dialog-driver-percentage-speeding/dialog-driver-percentage-speeding.component';
import { EventService } from '@app/services/event.service';
import { ToasterService } from '@app/services/toaster.service';
import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';
import { InterventionService } from '@app/services/intervention.service';
import { DialogCoachingDetailsComponent } from '@app/shared/components/dialog-coaching-details/dialog-coaching-details.component';
import {
  calculateDialogWidth,
  getBackgroundClass,
  getInitials,
} from '@app/shared/helpers/functions.helper';
import { FlyoutType } from '@app/models/fly-out.model';
import { FlyoutService } from '@app/services/flyout.service';
import { StatusType } from '@app/models/status.model';
import { convertStringToArray } from '@app/shared/helpers/string.helper';
import { PermissionService } from '@app/services/permission.service';
import { MediaService } from '@app/services/media.service';
import { EventType } from '@app/models/events.model';
import { convertDateFormat } from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-tab-sections-details',
  templateUrl: './tab-sections-details.component.html',
  styleUrls: ['../tab-sections.component.scss'],
})
export class TabSectionsDetailsComponent implements OnInit, OnDestroy {
  @Input() rowData: any;
  @Input() rowEvent: any;
  @Input() driverId!: string;
  @Input() isLoading!: boolean;
  @Input() showMenu!: boolean;

  userId: string = '';
  userName: string = '';
  avatarUrl: string = '';
  contents: string = '';
  readonly sections: Section[] = TabDetailsSections;
  notes: Note[] = [];
  eventDetails!: { title: string; value: string | number }[];
  entityType: string = 'event';
  eventActivities!: any;
  maxDisplayedRecords: number = 2;
  sectionSelected: string = 'event-details';
  selectedRow: { sectionName: string; rowId?: string } | null = null;
  descriptionEdited: boolean = false;
  interventions: any[] = [];

  showSearch: boolean = false;
  searchText: string = '';

  interventionControl = new FormControl();
  filteredInterventions!: Observable<any[]>;
  selectedInterventions: any[] = [];
  isLoadingInterventions: boolean = false;
  assigneeImages: { [assigneeId: string]: string | null } = {};

  selectedOrganization: any = {};

  private destroy$ = new Subject<void>();

  convertStringToArray = convertStringToArray;
  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  @ViewChild('interventionInput')
  interventionInput!: ElementRef<HTMLInputElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private toasterService: ToasterService,
    private interventionService: InterventionService,
    private flyoutService: FlyoutService,
    private dialogEventService: DialogEventService,
    private permissionService: PermissionService,
    private mediaService: MediaService
  ) {
    setTimeout(() => this.dialogEventService.viewInit());
  }

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.setEventDetails();
    this.fetchEventInterventions();
    this.setupInterventionAutocomplete();
    this.setSubscriptionToRefreshList();
    this.interventionControl.setValue('');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  setSubscriptionToRefreshList(): void {
    this.dialogEventService.statusSelectionChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this.fetchEventInterventions(), 200);
      });
  }

  setupInterventionAutocomplete(): void {
    this.filteredInterventions = this.interventionControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      switchMap((value) => this.searchInterventions(value))
    );
  }

  searchInterventions(searchTerm: string): Observable<any[]> {
    if (typeof searchTerm === 'string') {
      const params = {
        searchField: searchTerm,
        driverId: this.driverId,
        organizationId: this.selectedOrganization.organizationId,
      };
      return this.interventionService.getInterventionsSearch(params).pipe(
        map((res: any) => res[0].interventionSummaries),
        map((interventions) => {
          if (!interventions.length) {
            return [];
          }
          return interventions.filter(
            (intervention: any) =>
              !this.selectedInterventions.some(
                (selectedIntervention) => selectedIntervention.id === intervention.id
              )
          );
        })
      );
    }
    return this.filteredInterventions;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setEventDetails();
    this.eventActivities = this.rowEvent?.eventActivities[0];
  }

  resetSelectedInterventions(): void {
    this.interventionInput.nativeElement.value = '';
    this.selectedInterventions = [];
    this.interventionControl.setValue('');
  }

  displayFn(intervention: any): string {
    return intervention ? `${intervention.ticketNum} - ${intervention.ticketNum}` : '';
  }

  selectIntervention(intervention: any): void {
    if (!this.selectedInterventions.some((e) => e.id === intervention.id)) {
      this.selectedInterventions.push(intervention);
    }
    this.interventionControl.setValue(null);
    this.interventionInput.nativeElement.value = '';
  }

  removeIntervention(intervention: any): void {
    const index = this.selectedInterventions.indexOf(intervention);
    if (index >= 0) {
      this.selectedInterventions.splice(index, 1);
      this.interventionInput.nativeElement.value = '';
      this.interventionControl.setValue('');
    }
  }

  getUserIdFromLocalStorage(): string {
    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }
    return userId;
  }

  linkIntervention(): void {
    this.selectedInterventions.forEach((intervention) => {
      const relationshipRequest = {
        eventId: this.rowData.id,
        interventionId: intervention.id,
        userId: this.getUserIdFromLocalStorage(),
        organizationId: this.selectedOrganization?.organizationId,
      };

      this.interventionService
        .relationshipEventIntervention(relationshipRequest)
        .subscribe({
          next: () => {
            this.resetSelectedInterventions();
            this.showSearch = false;
            this.fetchEventInterventions();
            this.changeEventStatusToAssigned();
          },
          error: (relationshipError) => {
            console.error(relationshipError);
          },
        });
    });
  }

  changeEventStatusToAssigned(): void {
    if (this.rowEvent.status === StatusType.OPEN) {
      const request = {
        id: this.rowData.id,
        status: StatusType.ASSIGNED,
        lastModifiedBy: this.getUserIdFromLocalStorage(),
        organizationId: this.selectedOrganization.organizationId,
      };
      this.eventService.updateEvent(request).subscribe({
        next: () => {
          this.dialogEventService.announceStatusChange(StatusType.ASSIGNED);
          this.dialogEventService.announceStatusSelectionChange(StatusType.ASSIGNED);
        },
        error: (error) => console.error(error),
      });
    }
  }

  cancelSearch(): void {
    this.showSearch = false;
  }

  linkToExisting(): void {
    this.showSearch = !this.showSearch;
  }

  fetchEventInterventions(): void {
    this.isLoadingInterventions = true;
    this.interventionService.getEventInterventions(this.rowData.id).subscribe({
      next: (res) => {
        this.interventions = res[0].interventions ? res[0].interventions : [];
        this.loadAssigneeImages(this.interventions);
        this.isLoadingInterventions = false;
      },
      error: (error) => {
        this.isLoadingInterventions = false;
        console.log(error);
      },
    });
  }

  private loadAssigneeImages(interventions: any[]): void {
    const uniqueAssignees = new Set(interventions.map((item) => item.assignedCoach));
    uniqueAssignees.forEach((assignedCoach) => {
      if (
        !this.assigneeImages[assignedCoach] &&
        this.assigneeImages[assignedCoach] !== null
      ) {
        this.mediaService
          .downloadFile('userProfileImage', assignedCoach, 'profile_image')
          .subscribe({
            next: (blob: Blob) => {
              const imageUrl = URL.createObjectURL(blob);
              this.assigneeImages[assignedCoach] = imageUrl;
            },
            error: (error) => {
              if (error.status !== 404) {
                console.error(error);
              }
              this.assigneeImages[assignedCoach] = null;
            },
          });
      }
    });
  }

  getThresholdText(): string {
    let configurableUnit = '';
    if (
      this.rowEvent?.type?.toLowerCase() === EventType.TELEMATICS ||
      this.rowEvent?.type?.toLowerCase() === EventType.SEATBELT
    ) {
      configurableUnit = ' Occurrence(s)';
    } else if (
      this.rowEvent?.type?.toLowerCase() === EventType.HOS ||
      this.rowEvent?.type?.toLowerCase() === EventType.INSPECTIONS
    ) {
      configurableUnit = ' Violation(s)';
    } else if (this.rowEvent?.type?.toLowerCase() === EventType.ACCIDENT) {
      configurableUnit = ' Accident(s)';
    } else if (this.rowEvent?.eventActivities[0]?.speedPercentageActivities) {
      configurableUnit = '%';
    } else {
      configurableUnit = ' Occurrence(s)';
    }
    return `${this.rowEvent?.threshold}${configurableUnit} in ${this.rowEvent?.interval} day(s)`;
  }

  getTooltipText(): string {
    return `Events are created when a driver's activities meet the criteria and threshold within the set time frame, as configured by an admin`;
  }

  getWindowText(): string {
    if (this.rowEvent?.window) {
      return `${convertDateFormat(
        this.rowEvent.window?.intervalStartDate
      )} - ${convertDateFormat(this.rowEvent.window?.intervalEndDate)}`;
    }
    return '';
  }

  setEventDetails(): void {
    this.eventDetails = [
      {
        title: 'Criteria',
        value: this.rowData?.criteria,
      },
      {
        title: 'Threshold',
        value: this.getThresholdText(),
      },
      ...(this.rowEvent?.window
        ? [
            {
              title: 'Window',
              value: this.getWindowText(),
            },
          ]
        : []),
      {
        title: 'Behavior',
        value: this.rowEvent?.behavior,
      },
      {
        title: 'Description',
        value: this.rowEvent?.description,
      },
    ];
  }

  onAddNewClick(): void {
    this.dialog
      .open(DialogNewInterventionComponent, {
        width: '800px',
        panelClass: 'single',
        data: {
          event: this.rowData,
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          this.changeEventStatusToAssigned();
          this.fetchEventInterventions();
        }
      });
  }

  onNavClick(elementId: string): void {
    this.dialogEventService.onNavClick(elementId);
  }

  ngAfterViewInit(): void {
    this.attachScrollListener();
  }

  onRowClick(data: any, sectionName: string): void {
    const clearSelectedRow = (): void => {
      this.selectedRow = null;
    };
    this.selectedRow = { sectionName };
    let component: any;
    switch (sectionName) {
      case ActivitySectionName.ACCIDENTS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverAccidentComponent;
        break;
      case ActivitySectionName.INJURIES:
        this.selectedRow.rowId = data.id;
        component = DialogDriverInjuryComponent;
        break;
      case ActivitySectionName.TELEMATICS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverTelematicsComponent;
        break;
      case ActivitySectionName.HOURS_OF_SERVICE:
        this.selectedRow.rowId = data.id;
        component = DialogDriverHoursOfServiceComponent;
        break;
      case ActivitySectionName.INSPECTIONS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverInspectionComponent;
        break;
      case ActivitySectionName.SPEEDING_EVENTS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverSpeedComponent;
        break;
      case ActivitySectionName.PERCENTAGE_SPEEDING:
        this.selectedRow.rowId = data.id;
        component = DialogDriverPercentageSpeedingComponent;
        break;
      default:
        clearSelectedRow();
    }
    this.dialog
      .open(component, { data, panelClass: 'single', autoFocus: false })
      .afterClosed()
      .subscribe(() => {
        clearSelectedRow();
      });
  }

  private attachScrollListener(): void {
    const articleElement = this.scrollContainer.nativeElement;
    articleElement.addEventListener('scroll', () => {
      this.onContainerScroll();
    });
  }

  private onContainerScroll(): void {
    let closestSection = null;
    let minDistance = Infinity;

    this.sections.forEach((section) => {
      const element = this.scrollContainer.nativeElement.querySelector(`#${section.id}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const distance = Math.abs(
          rect.top - this.scrollContainer.nativeElement.getBoundingClientRect().top
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestSection = section.id;
        }
      }
    });

    if (closestSection !== null) {
      this.sectionSelected = closestSection;
    }
  }

  saveDescription(): void {
    const request = {
      id: this.rowData.id,
      description: this.rowEvent.description,
      lastModifiedBy: this.getUserIdFromLocalStorage(),
      organizationId: this.selectedOrganization.organizationId,
    };
    this.eventService.updateEvent(request).subscribe({
      next: () => this.toasterService.showSuccess('The Status was updated successfully'),
      error: (error) => {
        console.error(error);
      },
    });
  }

  onUnlinkIntervention(event: MouseEvent, intervention: any): void {
    event.stopPropagation();
    const data = {
      title: 'Unlink Intervention',
      text: 'Are you sure you want to unlink the intervention?',
      yesButtonText: 'Unlink',
      noButtonText: 'Cancel',
      noWarn: true,
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '500px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.unlinkIntervention(intervention);
        }
      });
  }

  unlinkIntervention(intervention: any): void {
    const relationshipRequest = {
      id: intervention.id,
      eventId: this.rowData.id,
      interventionId: intervention.interventionId,
      userId: this.getUserIdFromLocalStorage(),
      deleted: true,
      organizationId: this.selectedOrganization?.organizationId,
    };

    this.interventionService
      .relationshipEventIntervention(relationshipRequest)
      .subscribe({
        next: () => {
          this.resetSelectedInterventions();
          this.showSearch = false;
          this.fetchEventInterventions();
          this.changeEventStatusToAssigned();
        },
        error: (relationshipError) => {
          console.error(relationshipError);
        },
      });
  }

  goToDetails(intervention: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.COACHING);

    setTimeout(() => {
      const dialogWidth = calculateDialogWidth(
        this.flyoutService.getFlyoutOffsetBasedOnIndex()
      );
      this.dialog
        .open(DialogCoachingDetailsComponent, {
          data: {
            ...this.getInterventionData(intervention),
            uniqueIdentifier: FlyoutType.COACHING,
          },
          position: {
            right: '0',
            top: '70px',
          },
          width: dialogWidth,
          minWidth: dialogWidth,
          panelClass: ['dialog-event', 'animate-slide-in-left'],
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {});
    }, 100);
  }

  getInterventionData(intervention: any): any {
    return {
      id: intervention.interventionId,
      key: intervention.ticketNum,
      priority: intervention.priority,
      driverID: intervention.driverId,
      driverGuid: this.rowData.driverGuid,
      driverName: intervention.driverName,
      terminal: intervention.terminal,
      dueDate: intervention.dueDate,
      reason: intervention.reason,
      assigneeName: intervention?.assigneeName ? intervention?.assigneeName : '',
      assignee: intervention?.assignedCoach ? intervention?.assignedCoach : '',
      status: intervention.status,
      lastModifiedDate: intervention.lastModifiedDate,
      lastModifiedBy: intervention.lastModifiedBy,
      createdDate: intervention.creationDate,
      createdBy: intervention.createdBy,
      fromEvent: true,
    };
  }

  canLinkEventstoInterventions(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Events');
  }

  canViewInterventions(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Events') ||
      this.permissionService.hasPermission('WRITE', 'Events')
    );
  }

  canOpenInterventions(intervention: any): boolean {
    const userId = this.getUserIdFromLocalStorage();
    return (
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      this.permissionService.hasPermission('READ', 'All Interventions') ||
      (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
        intervention.assignedCoach === userId)
    );
  }

  canViewEvents(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Events') ||
      this.permissionService.hasPermission('WRITE', 'Events')
    );
  }

  canEditEvents(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Events');
  }
}
