<div class="header-block">
  <h2>Speeding Events</h2>
</div>

<table
  *ngIf="!isLoading"
  mat-table
  matSort
  [dataSource]="speedDataFiltered"
  class="table-header-sticky-driver-details"
>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef class="date-column">Date</th>
    <td mat-cell *matCellDef="let element" class="date-column">
      {{ element?.eventDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.eventDate | date : "hh:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="posted">
    <th mat-header-cell *matHeaderCellDef>Posted</th>
    <td mat-cell *matCellDef="let element">{{ element?.posted }}</td>
  </ng-container>

  <ng-container matColumnDef="actual">
    <th mat-header-cell *matHeaderCellDef>Actual</th>
    <td mat-cell *matCellDef="let element">{{ element?.acutal }}</td>
  </ng-container>

  <ng-container matColumnDef="difference">
    <th mat-header-cell *matHeaderCellDef>Difference</th>
    <td mat-cell *matCellDef="let element">{{ element?.difference }}</td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element">{{ element?.category }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="speedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: speedColumns"
    (click)="onRowClick(row)"
    class="clickable"
    [class.row-selected]="isRowSelected('speed', row.id)"
  ></tr>

  <!--No data row-->
  <tr *matNoDataRow>
    <td *ngIf="!isLoading" [attr.colspan]="speedColumns.length" class="no-records-found">
      No records found
    </td>
  </tr>
</table>

<div *ngIf="isLoading" class="spinner-container">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<div class="paginator-container" *ngIf="!isViewMore">
  <div class="paginator-text">
    Showing {{ startPageIdx }} - {{ endPageIdx }} of {{ totalRecordCount }} items
  </div>
  <mat-paginator
    [pageSize]="pageEvent.pageSize"
    [length]="paginationParams.total"
    [hidePageSize]="true"
    [pageIndex]="pageEvent.pageIndex"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>

<hr *ngIf="!isLoading" />

<div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
