import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DriverService } from '@app/services/driver.service';
import { PermissionService } from '@app/services/permission.service';

@Component({
  selector: 'app-drivers-filters-modal',
  templateUrl: './drivers-filters-modal.component.html',
  styleUrls: ['./drivers-filters-modal.component.scss'],
})
export class DriversFiltersModalComponent implements OnInit {
  filtersForm!: FormGroup;
  workStatusList: any[] = [];
  driverTypeList: any[] = [];
  terminalList: any[] = [];
  isLoadingWorkStatus: boolean = false;
  isLoadingDriverType: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<DriversFiltersModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private driverService: DriverService,
    private permissionService: PermissionService
  ) {
    this.filtersForm = this.fb.group({
      active: [data.active],
      inactive: [data.inactive],
      trueOption: [data.trueOption],
      terminal: [data.terminal],
      workStatuses: this.fb.array([]),
      driverTypes: this.fb.array([]),
      driverScoreMin: [data.driverScoreMin || 0],
      driverScoreMax: [data.driverScoreMax || 100],
    });
  }

  get workStatuses(): any {
    return this.filtersForm.get('workStatuses') as FormArray;
  }

  get driverTypes(): any {
    return this.filtersForm.get('driverTypes') as FormArray;
  }

  ngOnInit(): void {
    this.getWorkStatusList();
    this.getDriverTypeList();
    this.getTerminalList();
  }

  getRowItems(array: any[], rowIndex: number, itemsPerRow: number): any[] {
    const start = rowIndex * itemsPerRow;
    const end = start + itemsPerRow;
    return array.slice(start, end);
  }

  getRows(arrayLength: number, itemsPerRow: number = 2): number[] {
    return new Array(Math.ceil(arrayLength / itemsPerRow)).fill(null);
  }

  getWorkStatusList(): void {
    this.isLoadingWorkStatus = true;
    this.driverService.getFiltersList('workStatus').subscribe({
      next: (res) => {
        this.isLoadingWorkStatus = false;
        this.workStatusList = res;
        this.workStatusList.forEach((workStatusListElem) => {
          let selectedValue = false;
          const foundItem = this.data.workStatuses?.find(
            (workStatus: any) => workStatus.name === workStatusListElem.name
          );
          if (foundItem) {
            selectedValue = true;
          }
          this.workStatuses.push(this.fb.control(selectedValue));
        });
      },
      error: (error) => {
        this.isLoadingWorkStatus = false;
        console.log(error);
      },
    });
  }

  getDriverTypeList(): void {
    this.isLoadingDriverType = true;
    this.driverService.getFiltersList('driverType').subscribe({
      next: (res) => {
        this.isLoadingDriverType = false;
        this.driverTypeList = res.filter((item: any) => !item.deleted);
        this.driverTypeList.forEach((driverTypeListElem) => {
          let selectedValue = false;
          const foundItem = this.data.driverTypes?.find(
            (driverType: any) => driverType.name === driverTypeListElem.name
          );
          if (foundItem) {
            selectedValue = true;
          }
          this.driverTypes.push(this.fb.control(selectedValue));
        });
      },
      error: (error) => {
        this.isLoadingDriverType = false;
        console.log(error);
      },
    });
  }

  getTerminalList(): void {
    this.driverService.getFiltersList('terminal').subscribe({
      next: (res) => {
        this.terminalList = res.filter((item: any) => !item.deleted);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  onApply(): void {
    const result = {
      form: this.filtersForm.value,
      workStatuses: this.workStatusList
        .map((status, index) => {
          if (!this.workStatuses.at(index).value) return;
          return {
            name: status.name,
            selected: this.workStatuses.at(index).value,
          };
        })
        .filter(Boolean),
      driverTypes: this.driverTypeList
        .map((status, index) => {
          if (!this.driverTypes.at(index).value) return;
          return {
            name: status.name,
            selected: this.driverTypes.at(index).value,
          };
        })
        .filter(Boolean),
    };
    this.dialogRef.close(result);
  }

  onClearAll(): void {
    this.dialogRef.close('clearAll');
  }

  canViewSDriverScores(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Driver Scores') ||
      this.permissionService.hasPermission('WRITE', 'Driver Scores')
    );
  }
}
