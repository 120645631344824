import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { DialogActions } from '@app/models';
import { AssigneeType } from '@app/models/status.model';
import { DialogEventService } from '@app/shared/components/dialog-event/dialog-event.service';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { convertStringToArray, toTitleCase } from '@app/shared/helpers/string.helper';
import { MediaService } from '@app/services/media.service';
import { PermissionService } from '@app/services/permission.service';
import { DriverService } from '@app/services/driver.service';
import { InterventionService } from '@app/services/intervention.service';
import { UserManagementService } from '@app/services/user-management.service';
import { ExpandViewService } from '@app/services/expand-view.service';
import { CoachingTabs } from '@app/models/dialog-info-sections';
import { AssigneeUsersType } from '@app/models/general.model';
import { convertDateFormat } from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-dialog-coaching-details',
  templateUrl: './dialog-coaching-details.component.html',
  styleUrls: ['./dialog-coaching-details.component.scss'],
})
export class DialogCoachingDetailsComponent implements OnInit, OnDestroy {
  event: any = {
    status: null,
    eventActivities: [],
  };
  currentStatus: string = '';
  selectedTabIndex: number = 0;
  selectedTabName: string = '';
  showMenu: boolean = true;
  editMode: boolean = false;
  fromDriver: boolean = false;
  fromEvent: boolean = false;
  imageUrl: string = '';
  users!: any;
  userSelected!: any;

  cssContent: string = '';
  isLoadingPrintWeb: boolean = false;
  private queryParamsSubscription!: Subscription;

  selectedOrganization: any = {};

  visibleTabs: string[] = [];

  assigneeType = AssigneeType;
  toTitleCase = toTitleCase;
  convertStringToArray = convertStringToArray;
  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;
  convertDateFormat = convertDateFormat;

  private printIframe!: HTMLIFrameElement | null;
  private expandViewCounterSubscription!: Subscription;
  private totalComponentsToExpand = 2;

  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogCoachingDetailsComponent>,
    private dialogEventService: DialogEventService,
    private interventionService: InterventionService,
    private permissionService: PermissionService,
    private mediaService: MediaService,
    private driverService: DriverService,
    private userManagementService: UserManagementService,
    private expandViewService: ExpandViewService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    if (!this.data?.key) {
      this.fetchIntervention();
    }
    if (this.data?.fromDriver) {
      this.fromDriver = this.data.fromDriver;
    }
    if (this.data?.fromEvent) {
      this.fromEvent = this.data.fromEvent;
    }
    if (!this.data.showInfo) {
      this.subscriptionToDialogState();
    }
    this.currentStatus = this.data?.status;
    this.getUsers();
    this.getAssigneeImage();
    this.loadPrintStyles();
    this.updateVisibleTabs();
    this.subscriptionToURLParams();

    this.expandViewCounterSubscription =
      this.expandViewService.expandViewCounter$.subscribe((count: number) => {
        if (count === this.totalComponentsToExpand) {
          this.printCurrentTabContent();
          this.expandViewService.resetExpandView();
        }
      });
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  updateVisibleTabs(): void {
    this.visibleTabs = [
      CoachingTabs.CoachingDetails,
      CoachingTabs.DriverHistory,
      CoachingTabs.DriverInfo,
    ];
  }

  ngOnDestroy(): void {
    if (this.expandViewCounterSubscription) {
      this.expandViewCounterSubscription.unsubscribe();
    }

    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  subscriptionToURLParams(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
      const tabName = params['tab'];

      if (tabName === CoachingTabs.DriverHistory) {
        this.selectedTabIndex = 1;
        this.onTabChange({ index: this.selectedTabIndex } as MatTabChangeEvent);
      }

      if (tabName === CoachingTabs.DriverInfo) {
        this.selectedTabIndex = 2;
        this.onTabChange({ index: this.selectedTabIndex } as MatTabChangeEvent);
      }
    });
  }

  loadPrintStyles(): void {
    this.http
      .get('assets/css/print-screen-coaching.css', { responseType: 'text' })
      .subscribe({
        next: (css) => {
          this.cssContent = css;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  getCurrentUser() {
    const userDetailsString = localStorage.getItem('userData');
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      const { objectId: userId, firstName, lastName } = userDetails.userInfo;
      return { userId, firstName, lastName };
    } else {
      console.error('User details not found in local storage');
      return null;
    }
  }

  isSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome');
  }

  onPrintClick(): void {
    this.isLoadingPrintWeb = true;
    this.expandViewService.triggerExpandView();
  }

  printCurrentTabContent(): void {
    this.isLoadingPrintWeb = true;
    if (this.isSafari()) {
      setTimeout(() => {
        if (this.modalContainer) {
          const modalHTML = this.generateModalHTML();

          // Reuse the iframe if it has already been created
          if (!this.printIframe) {
            this.printIframe = document.createElement('iframe');
            this.printIframe.style.position = 'absolute';
            this.printIframe.style.width = '0px';
            this.printIframe.style.height = '0px';
            this.printIframe.style.border = 'none';
            document.body.appendChild(this.printIframe);
          }

          const iframeDoc =
            this.printIframe.contentWindow?.document || this.printIframe.contentDocument;
          if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(modalHTML);
            iframeDoc.close();

            // Wait for the iframe to load before executing print
            this.printIframe.onload = () => {
              this.printIframe?.contentWindow?.focus();
              this.printIframe?.contentWindow?.print();
            };
          }
        }
        this.isLoadingPrintWeb = false;
      }, 1000);
    } else {
      setTimeout(() => {
        if (this.modalContainer) {
          const modalHTML = this.generateModalHTML();

          const blob = new Blob([modalHTML], { type: 'text/html' });
          const url = URL.createObjectURL(blob);

          const printWindow = window.open(url, '_blank');

          if (printWindow) {
            printWindow.onload = () => {};
          }
        }
        this.isLoadingPrintWeb = false;
      }, 1000);
    }
  }

  generateModalHTML(): string {
    const coachingTitle = `Intervention #${this.data.key}`;

    const currentUser = this.getCurrentUser();
    const userName = currentUser
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : 'Unknown User';

    return `
      <html>
        <head>
          <title>${coachingTitle} (Generated for ${userName})</title>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
          <style>${this.cssContent}</style>
        </head>
        <body>
          <div class="button-container">
            <button onclick="window.close()">Go Back</button>
            <button onclick="window.print()">Print</button>
          </div>
          <div class="printable-modal">
            ${this.modalContainer.nativeElement.innerHTML.replaceAll(
              '/assets/images/fallback.png',
              window.location.origin + '/assets/images/fallback.png'
            )}
          </div>
        </body>
      </html>
    `;
  }

  getUsers(): void {
    const params = {
      type: AssigneeUsersType.INTERVENTIONS,
      organizationId: this.selectedOrganization?.organizationId,
    };
    this.userManagementService.getUsers(params).subscribe({
      next: (res) => {
        this.users = res[0].userResults;
        this.userSelected = this.users.find(
          (user: any) => user.userId === this.data.assignee
        );
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  fetchIntervention(): void {
    this.interventionService.getIntervention(this.data.id).subscribe({
      next: (res) => {
        this.data = res[0];
        this.data.key = this.data.ticketId;
        this.driverService.getDriverProfile(this.data.driverGuid).subscribe({
          next: (res) => {
            this.data.driverName = res[0].driverName;
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getAssigneeImage(): void {
    this.mediaService
      .downloadFile('userProfileImage', this.data?.assignee, 'profile_image')
      .subscribe({
        next: (blob: Blob) => {
          this.imageUrl = URL.createObjectURL(blob);
        },
        error: (error) => {
          if (error.status !== 404) {
            console.error(error);
          }
          this.imageUrl = '';
        },
      });
  }

  subscriptionToDialogState(): void {
    this.checkIfTopDialog();
    this.dialog.afterOpened.subscribe((dialogRef: any) => {
      this.checkIfTopDialog();
      dialogRef.afterClosed().subscribe(() => {
        setTimeout(() => {
          this.checkIfTopDialog();
        });
      });
    });
  }

  checkIfTopDialog(): void {
    const openDialogs = this.dialog.openDialogs;
    const isTopDialog = openDialogs[openDialogs.length - 1] === this.dialogRef;

    if (isTopDialog) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
  }

  saveClicked(event: any): void {
    switch (event) {
      case DialogActions.CANCEL:
        this.editMode = false;
        break;
      case DialogActions.UPDATE_CLOSE:
        this.editMode = false;
        this.interventionService.announceSelectionChange('update');
        break;
      case DialogActions.UPDATE:
        this.interventionService.announceSelectionChange('update');
        break;
      default:
        break;
    }
  }

  updateIntervention(event: any): void {
    this.data = { ...this.data, ...event, driverID: event.driverId };
  }

  editIntervention(): void {
    this.editMode = true;
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    this.updateSelectedTabState();
    const selectedTabName = this.visibleTabs[this.selectedTabIndex];
    const currentUrl = this.location.path();

    const urlSearchParams = new URLSearchParams(currentUrl.split('?')[1] || '');

    urlSearchParams.set('interventionId', this.data.id);
    urlSearchParams.set('tab', selectedTabName);

    this.location.replaceState(
      `${currentUrl.split('?')[0]}?${urlSearchParams.toString()}`
    );

    const container: Element | null = document.querySelector(
      '.dialog-event .mat-mdc-dialog-container .mdc-dialog__surface'
    );
    container?.setAttribute('style', 'overflow: hidden;');
    setTimeout(() => container?.setAttribute('style', 'overflow: visible;'), 1000);
  }

  private updateSelectedTabState(): void {
    this.selectedTabName = this.visibleTabs[this.selectedTabIndex];
  }

  goToDriverProfile(): void {
    this.tabGroup.selectedIndex = 2;
    this.selectedTabIndex = 2;
  }

  goToTerminal(terminal: string): void {
    this.dialog.openDialogs.forEach((dialogRef) => dialogRef.close());
    this.router.navigate(['/drivers', terminal]);
  }

  handleStatusSelectionChange(newStatus: string) {
    this.data.status = newStatus;
    this.dialogEventService.announceStatusSelectionChange(newStatus);
  }

  canEditInterventions(): boolean {
    const userId = localStorage.getItem('objectId');
    return (
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
        this.data?.assignee === userId)
    );
  }

  canViewDriverProfile(): boolean {
    return this.permissionService.hasPermission('READ', 'Driver Profile');
  }
}
