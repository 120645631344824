import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, OnChanges {
  @Input() minDate!: Date;
  @Input() maxDate!: Date;
  @Input() startDate!: Date | null;
  @Input() endDate!: Date | null;
  @Input() positionOffset: 'right' | 'left' | null = null;
  @Input() rounded: boolean = false;
  @Output() dateRangeSelected: EventEmitter<{
    start: Date | null;
    end: Date | null;
  }> = new EventEmitter();
  @Output() calendarOpened = new EventEmitter<void>();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  showButtons = false;
  calendarPositionClass: string | null = null;

  isMobile = false;

  @ViewChild('picker') datePicker!: MatDateRangePicker<Date>;

  constructor(
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.range.setValue({
      start: this.startDate || null,
      end: this.endDate || null,
    });

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe((result) => {
      this.isMobile = result.matches;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['startDate'] || changes['endDate']) {
      this.range.setValue(
        {
          start: this.startDate || null,
          end: this.endDate || null,
        },
        { emitEvent: false }
      );
    }
  }

  onOpen() {
    this.calendarOpened.emit();
    this.showButtons = true;
    this.calendarPositionClass = this.positionOffset;
    const calendarElement = document.querySelector('.mat-datepicker-content');
    if (calendarElement && this.calendarPositionClass) {
      this.renderer.addClass(calendarElement, this.calendarPositionClass);
    }
  }

  onClose() {
    this.showButtons = false;
    this.calendarPositionClass = null;
    const calendarElement = document.querySelector('.mat-datepicker-content');
    if (calendarElement) {
      this.renderer.removeClass(calendarElement, 'right');
      this.renderer.removeClass(calendarElement, 'left');
    }
    this.applyDates();
  }

  writeValue(value: { start: Date | null; end: Date | null }): void {
    const { start, end } = value;
    this.range.setValue({ start, end }, { emitEvent: false });
    this.datePicker.close();
    this.showButtons = false;
  }

  private adjustDatesToMinMax(
    startDate: Date,
    endDate: Date
  ): { start: Date; end: Date } {
    let adjustedStartDate = startDate;
    let adjustedEndDate = endDate;

    if (this.minDate && startDate < this.minDate) {
      adjustedStartDate = this.minDate;
    }
    if (this.maxDate && endDate > this.maxDate) {
      adjustedEndDate = this.maxDate;
    }

    return { start: adjustedStartDate, end: adjustedEndDate };
  }

  setRange(days: number): void {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - days);

    const { start, end } = this.adjustDatesToMinMax(startDate, endDate);
    this.writeValue({ start, end });
  }

  setLast7Days(): void {
    this.setRange(7);
  }

  setLast30Days(): void {
    this.setRange(30);
  }

  setLast90Days(): void {
    this.setRange(90);
  }

  setLast6Months(): void {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setMonth(endDate.getMonth() - 6);

    const { start, end } = this.adjustDatesToMinMax(startDate, endDate);
    this.writeValue({ start, end });
  }

  setLast12Months(): void {
    const endDate = new Date();
    const startDate = new Date(new Date().setFullYear(endDate.getFullYear() - 1));

    const { start, end } = this.adjustDatesToMinMax(startDate, endDate);
    this.writeValue({ start, end });
  }

  setThisYear(): void {
    const endDate = new Date();
    const startDate = new Date(new Date().setFullYear(endDate.getFullYear(), 0, 1));

    const { start, end } = this.adjustDatesToMinMax(startDate, endDate);
    this.writeValue({ start, end });
  }

  setMonthToDate(): void {
    const endDate = new Date();
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

    this.writeValue({ start: startDate, end: endDate });
  }

  setQuarterToDate(): void {
    const endDate = new Date();
    const month = endDate.getMonth();
    let quarterStartMonth;

    if (month < 3) {
      quarterStartMonth = 0;
    } else if (month < 6) {
      quarterStartMonth = 3;
    } else if (month < 9) {
      quarterStartMonth = 6;
    } else {
      quarterStartMonth = 9;
    }

    const startDate = new Date(endDate.getFullYear(), quarterStartMonth, 1);
    this.writeValue({ start: startDate, end: endDate });
  }

  setToday(): void {
    const today = new Date();
    let currentStart = this.range.value.start;
    let currentEnd = this.range.value.end;

    if (!currentStart) {
      this.range.setValue({
        start: today,
        end: currentEnd ? currentEnd : null,
      });
    } else {
      this.range.setValue({ start: currentStart, end: today });
    }

    this.datePicker.close();
    this.showButtons = false;
  }

  clearDates(): void {
    this.range.setValue({ start: null, end: null });
    this.datePicker.close();
    this.showButtons = false;
  }

  applyDates(): void {
    this.datePicker.close();
    this.showButtons = false;
    this.dateRangeSelected.emit({
      start: this.range.value.start!,
      end: this.range.value.end!,
    });
  }
}
