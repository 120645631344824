import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

import { ITelematicsEvent } from '@app/models/telematics.model';
import { PaginationParams } from '@app/models/pagination-params';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { ExpandViewService } from '@app/services/expand-view.service';
import { addSpacesToCamelCase } from '@app/shared/helpers/string.helper';

@Component({
  selector: 'app-telematics-list',
  templateUrl: './telematics-list.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class TelematicsListComponent implements OnInit, OnDestroy {
  @Input() dateRange: any;
  @Input() driverGUID!: string;
  @Input() maxDisplayedRecords!: number;
  @Input() defaultPageSize!: number;
  @Output() rowClicked = new EventEmitter<any>();

  telematicsList: ITelematicsEvent[] = [];
  telematicsDataFiltered: ITelematicsEvent[] = [];
  telematicsColumns = ['date', 'time', 'type', 'video'];
  isLoading = false;
  selectedRow: { sectionName: string; rowId?: string } | null = null;

  isViewMore = true;
  pageSize!: number;
  paginationParams!: PaginationParams;
  pageEvent!: PageEvent;
  startPageIdx = 1;
  endPageIdx!: number;
  showViewMore = false;
  totalRecordCount = 10000;
  isExpandViewTriggered = false;

  private dateRangeSubscription!: Subscription;
  private expandViewSubscription!: Subscription;

  private eventIdFromUrl?: string;

  constructor(
    private dialogInfoService: DialogDriverService,
    private expandViewService: ExpandViewService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initializePaginator();
    this.subscribeToDateRangeChanges();
    this.subscribeToExpandView();
    this.checkUrlParameters();
    this.getTelematicsSummary();
  }

  ngOnDestroy(): void {
    this.dateRangeSubscription?.unsubscribe();
    this.expandViewSubscription?.unsubscribe();

    this.removeQueryParams(['activity', 'eventId']);
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'telematics' });
    this.updateUrlParams({ activity: 'telematics', eventId: row.id });
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  getTelematicsSummary(fetchAllRecords = false): void {
    this.isLoading = true;
    const { start, end } = this.dateRange.value;

    if (!start || !end) {
      this.isLoading = false;
      return;
    }

    const request = {
      startDate: start,
      endDate: end,
      driverGUID: this.driverGUID,
      page: this.pageEvent.pageIndex + 1,
      pageSize: fetchAllRecords ? this.totalRecordCount : this.pageEvent.pageSize,
    };

    this.dialogInfoService.getTelematicsSummary(request).subscribe({
      next: (response: any) => {
        this.handleTelematicsResponse(response, fetchAllRecords);
      },
      error: (error: any) => {
        this.isLoading = false;
        console.error('Error:', error);
      },
    });
  }

  addSpacesToCamelCase(str: string): string {
    return addSpacesToCamelCase(str);
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    this.updatePageIndices();
    this.telematicsDataFiltered = this.telematicsList.slice(
      this.startPageIdx - 1,
      this.endPageIdx
    );
    this.getTelematicsSummary();
  }

  loadMore(expandViewMore = false): void {
    this.isViewMore = expandViewMore ? false : !this.isViewMore;
    this.initializePaginator();
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.getTelematicsSummary(expandViewMore);
  }

  private initializePaginator(): void {
    this.paginationParams = {
      limit: this.defaultPageSize,
      offset: 0,
      total: 0,
    };

    this.pageEvent = {
      length: 0,
      pageIndex: 0,
      pageSize: this.defaultPageSize,
    };

    this.pageSize = this.maxDisplayedRecords;
    this.startPageIdx = 1;
  }

  private subscribeToDateRangeChanges(): void {
    this.dateRangeSubscription = this.dialogInfoService.dateChange$.subscribe(() => {
      this.getTelematicsSummary();
    });
  }

  private subscribeToExpandView(): void {
    this.expandViewSubscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  private checkUrlParameters(): void {
    const params = this.route.snapshot.queryParams;
    if (params['activity'] === 'telematics') {
      this.eventIdFromUrl = params['eventId'];
      this.onRowClick({ id: this.eventIdFromUrl });
      this.eventIdFromUrl = undefined;
    }
  }

  private handleTelematicsResponse(response: any, fetchAllRecords: boolean): void {
    this.isLoading = false;
    const data = response[0];
    this.telematicsList = data.telematicsEvents || [];
    this.totalRecordCount = data.totalRecordCount || 0;
    this.paginationParams.total = this.totalRecordCount;

    if (fetchAllRecords) {
      this.pageSize = this.totalRecordCount;
      this.endPageIdx = this.totalRecordCount;
    }

    if (this.totalRecordCount) {
      this.updateDataFilteredAndIndices();
      this.updateShowViewMore();
    }

    if (this.isExpandViewTriggered) {
      this.expandViewService.incrementExpandView();
      this.isExpandViewTriggered = false;
    }
  }

  private updateDataFilteredAndIndices(): void {
    this.endPageIdx =
      this.endPageIdx || Math.min(this.defaultPageSize, this.totalRecordCount);
    this.telematicsDataFiltered = this.telematicsList.slice(0, this.pageSize);
  }

  private updateShowViewMore(): void {
    this.showViewMore = this.telematicsList.length > this.maxDisplayedRecords;
  }

  private updatePageIndices(): void {
    const startIdx = this.pageEvent.pageIndex * this.pageEvent.pageSize;
    const endIdx = startIdx + this.pageEvent.pageSize;
    this.startPageIdx = startIdx + 1;
    this.endPageIdx = Math.min(endIdx, this.totalRecordCount);
  }

  private updateUrlParams(params: { [key: string]: string }): void {
    const url = new URL(window.location.href);
    Object.keys(params).forEach((key) => {
      url.searchParams.set(key, params[key]);
    });
    window.history.replaceState({}, '', url.toString());
  }

  private removeQueryParams(paramKeys: string[]): void {
    const url = new URL(window.location.href);
    paramKeys.forEach((key) => url.searchParams.delete(key));
    window.history.replaceState({}, '', url.toString());
  }
}
