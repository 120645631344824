import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs';

import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';
import { MatStepper } from '@angular/material/stepper';
import { DriverService } from '@app/services/driver.service';
import { phoneValidator } from '@app/shared/helpers/validators.helper';

@Component({
  selector: 'app-dialog-new-accident',
  templateUrl: './dialog-new-accident.component.html',
  styleUrls: ['./dialog-new-accident.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class DialogNewAccidentComponent implements OnInit {
  accidentForm!: FormGroup;
  step: number = 0;
  isDoneStepEnabled!: boolean;
  insertedId: string = '';

  @ViewChild('stepper') stepper!: MatStepper;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogNewAccidentComponent>,
    private driverService: DriverService
  ) {}

  ngOnInit(): void {
    this.setInitForm();
  }

  ngAfterViewInit(): void {
    this.stepper.selectedIndex = this.step;
  }

  setInitForm(): void {
    let driverId = '';
    if (this.data?.driver?.id) {
      driverId = this.data.driver.id;
    }

    let driverName = '';
    if (this.data?.driver?.driverName) {
      driverName = this.data.driver.driverName;
    }

    let organizationId = '';
    if (this.data?.driver?.organizationId) {
      organizationId = this.data.driver.organizationId;
    }
    this.accidentForm = this.fb.group({
      driverInfo: this.fb.group({
        organization: [organizationId, Validators.required],
        driverId: [{ value: '', disabled: true }, Validators.required],
        driverCode: [driverId],
        cellphone: ['', [Validators.required, phoneValidator()]],
        driverName: [{ value: driverName, disabled: true }],
        terminal: [{ value: null, disabled: true }],
        fleetManager: [{ value: null, disabled: true }],
        safetyManager: [{ value: null, disabled: true }],
        safetyTrainer: [{ value: null, disabled: true }],
      }),
      timePlace: this.fb.group({
        date: ['', Validators.required],
        time: [''],
        addressLine1: [''],
        addressLine2: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zip: [''],
        otherLocationDetails: [''],
      }),
      flaresHazmat: this.fb.group({
        accidentRoadway: [null, Validators.required],
        emergencyTriangles: [null],
        spilled: [null, Validators.required],
      }),
      injury: this.fb.group({
        injury: [null, Validators.required],
        injuryDescription: [null],
        medicalAssistance: [null],
      }),
      otherParty: this.fb.group({
        involved: [null, Validators.required],
        otherIndividualDescription: [null],
        fatalityInvolved: [null],
        otherIndividualInjured: [null],
        extentInjuriesDescription: [null],
        otherMedicalAssistance: [null],
        otherVehicleDamage: [null],
        extentDamagesDescription: [null],
        otherVehicleTowed: [null],
        towDescription: [null],
      }),
      police: this.fb.group({
        called: [null, Validators.required],
        respondingOfficerInfo: [null],
        reportNumber: [null],
        citation: [null],
      }),
      vehicleDamage: this.fb.group({
        truckNumber: [null, Validators.required],
        trailerNumber: [null],
        damage: [null, Validators.required],
        damageDescription: [null],
        cargo: [null],
        towed: [null, Validators.required],
        towInfoDescription: [null],
      }),
      accidentDetails: this.fb.group({
        assetsLocated: [null],
        crashType: [null],
        accidentDescription: [null],
        witnessInfoDescription: [null],
      }),
      done: this.fb.group({
        callHazmatResponse: [false],
        callAdminsInformFatality: [false],
        scheduleDriverTesting: [false],
      }),
    });
  }

  get driverInfoFormGroup(): FormGroup {
    return this.accidentForm.get('driverInfo') as FormGroup;
  }

  get timePlaceFormGroup(): FormGroup {
    return this.accidentForm.get('timePlace') as FormGroup;
  }

  get flaresHazmatFormGroup(): FormGroup {
    return this.accidentForm.get('flaresHazmat') as FormGroup;
  }

  get injuryFormGroup(): FormGroup {
    return this.accidentForm.get('injury') as FormGroup;
  }

  get otherPartyFormGroup(): FormGroup {
    return this.accidentForm.get('otherParty') as FormGroup;
  }

  get policeFormGroup(): FormGroup {
    return this.accidentForm.get('police') as FormGroup;
  }

  get vehicleDamageFormGroup(): FormGroup {
    return this.accidentForm.get('vehicleDamage') as FormGroup;
  }

  get accidentDetailsFormGroup(): FormGroup {
    return this.accidentForm.get('accidentDetails') as FormGroup;
  }

  get doneFormGroup(): FormGroup {
    return this.accidentForm.get('done') as FormGroup;
  }

  get driverNameValue(): string {
    return this.driverInfoFormGroup.get('driverName')?.value;
  }

  get dateValue(): string {
    return this.timePlaceFormGroup.get('date')?.value;
  }

  get showCallHazmat(): boolean {
    return this.flaresHazmatFormGroup.get('spilled')?.value === 'true';
  }
  get showCallGeneOrLaura(): boolean {
    return this.otherPartyFormGroup.get('fatalityInvolved')?.value === 'true';
  }
  get showScheduleAccidentTesting(): boolean {
    return (
      this.otherPartyFormGroup.get('fatalityInvolved')?.value === 'true' ||
      this.otherPartyFormGroup.get('otherMedicalAssistance')?.value === 'true' ||
      this.otherPartyFormGroup.get('otherVehicleTowed')?.value === 'true' ||
      this.accidentDetailsFormGroup.get('assetsLocated')?.value === 1
    );
  }

  onDialogCloseClick(): void {
    if (this.accidentForm.dirty) {
      const data = {
        title: 'Unsaved changes',
        text: 'You have unsaved changes. Are you sure you want to leave this page? The changes you made will not be saved.',
      };
      this.dialog
        .open(DialogConfirmComponent, { data, width: '500px' })
        .afterClosed()
        .pipe(filter((closeModal: boolean) => closeModal))
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  onSaveClick(closeModal: boolean = false, editMode: boolean = false): void {
    const request: any = this.createRequest();
    if (closeModal) {
      request.id = this.insertedId;
    }
    this.driverService.upsertAccident(request).subscribe({
      next: (res) => {
        this.insertedId = res[0].insertedId;
        this.showAccidentCreatedModal(editMode);
        this.isDoneStepEnabled = true;
        if (closeModal) {
          this.closeAccidentCreateModal();
        }
      },
      error: (error) => {
        console.error('Error saving accident', error);
      },
    });
  }

  onDoneClick(): void {
    if (
      this.showCallGeneOrLaura ||
      this.showCallHazmat ||
      this.showScheduleAccidentTesting
    ) {
      this.onSaveClick(true, true);
    } else {
      this.closeAccidentCreateModal();
    }
  }

  createRequest(): any {
    const request: any = {};
    const mapping: any = {
      driverInfo: {
        driverId: 'driverId',
        driverCode: 'driverCode',
        cellphone: 'driverPhone',
        organization: 'organization',
      },
      timePlace: {
        date: 'accidentDate',
        time: 'accidentTime',
        addressLine1: 'accidentAddress1',
        addressLine2: 'accidentAddress2',
        city: 'accidentCity',
        state: 'accidentState',
        zip: 'accidentZip',
        otherLocationDetails: 'accidentLocationOtherDetails',
      },
      flaresHazmat: {
        accidentRoadway: 'accidentOnRoadway',
        emergencyTriangles: 'deployedTriangleFlares',
        spilled: 'fuelHazardousSpilled',
      },
      injury: {
        injury: 'driverInjured',
        injuryDescription: 'driverInjuryDescription',
        medicalAssistance: 'driverRequireMedical',
      },
      otherParty: {
        involved: 'othersInvolved',
        otherIndividualDescription: 'othersInvolvedDetails',
        fatalityInvolved: 'fatalityInvolved',
        otherIndividualInjured: 'othersInjured',
        extentInjuriesDescription: 'othersInjuryDescription',
        otherMedicalAssistance: 'othersRequireMedical',
        otherVehicleDamage: 'othersVehiclePropertyDamage',
        extentDamagesDescription: 'othersVehiclePropertyDamageDescription',
        otherVehicleTowed: 'othersVehicleTowed',
        towDescription: 'othersVehicleTowedInformation',
      },
      police: {
        called: 'policeCalled',
        respondingOfficerInfo: 'policeOfficerInformation',
        reportNumber: 'policeReportNumber',
        citation: 'citationReceived',
      },
      vehicleDamage: {
        truckNumber: 'driverTruckNumber',
        trailerNumber: 'driverTrailerNumber',
        damage: 'driverEquipmentDamage',
        damageDescription: 'driverEquipmentDamageDescription',
        cargo: 'driverCargoCondition',
        towed: 'driverVehicleTowed',
        towInfoDescription: 'driverVehicleTowedInformation',
      },
      accidentDetails: {
        assetsLocated: 'assetsLocated',
        crashType: 'crashType',
        accidentDescription: 'accidentDescription',
        witnessInfoDescription: 'witnessInformation',
      },
      done: {
        callHazmatResponse: 'callHazmatResponse',
        callAdminsInformFatality: 'callAdminsInformFatality',
        scheduleDriverTesting: 'scheduleDriverTesting',
      },
    };

    for (const groupKey in this.accidentForm.controls) {
      const group = this.accidentForm.get(groupKey) as FormGroup;
      if (group && mapping[groupKey]) {
        for (const controlKey in group.controls) {
          const mappedKey = mapping[groupKey][controlKey];
          if (mappedKey) {
            let value = group.get(controlKey)?.value;
            if (value === 'true') {
              value = true;
            } else if (value === 'false') {
              value = false;
            }
            request[mappedKey] = value;
          }
        }
      }
    }
    request.accidentDate = this.convertToDatabaseFormat(
      request.accidentDate,
      request.accidentTime
    );
    request.othersInvolved = Number(request.othersInvolved);
    request.policeCalled = Number(request.policeCalled);
    if (!this.showCallHazmat) {
      request.callHazmatResponse = null;
    }
    if (!this.showCallGeneOrLaura) {
      request.callAdminsInformFatality = null;
    }
    if (!this.showScheduleAccidentTesting) {
      request.scheduleDriverTesting = null;
    }
    request.fatalityInvolved = request.fatalityInvolved === 'true' ? true : false;

    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      request.UserId = parsedData.userInfo.objectId;
    }

    request.organizationId = request?.organization?.organizationId;
    delete request.organization;

    request.driverId = request.driverCode;
    delete request.driverCode;

    return request;
  }

  convertToDatabaseFormat(accidentDate: string, accidentTime: string) {
    const [month, day, year] = accidentDate.split('/');
    const padZero = (value: any) => (value.length === 1 ? `0${value}` : value);

    if (accidentTime) {
      const [hoursAndMinutes, period] = accidentTime.split(' ');
      let [hours, minutes] = hoursAndMinutes.split(':');

      if (period === 'PM' && hours !== '12') {
        hours = (parseInt(hours) + 12).toString();
      } else if (period === 'AM' && hours === '12') {
        hours = '00';
      } else {
        hours = padZero(hours.toString());
      }
      minutes = padZero(minutes);
      return `${year}-${padZero(month)}-${padZero(day)} ${hours}:${minutes}:00`;
    }
    return `${year}-${padZero(month)}-${padZero(day)} 00:00:00`;
  }

  showAccidentCreatedModal(edit: boolean = false): void {
    const driverName = this.driverInfoFormGroup.get('driverName')?.value;
    let modeTitle = 'Created';
    let modeText = 'created';
    if (edit) {
      modeTitle = 'Edited';
      modeText = 'edited';
    }
    const data = {
      title: `Accident ${modeTitle}`,
      text: `The accident for ${driverName} has been successfully ${modeText}. The details captured can be found on the driver\'s activity section.`,
      confirm: true,
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '500px' })
      .afterClosed()
      .subscribe(() => {
        this.nextStep();
      });
  }

  closeAccidentCreateModal(): void {
    this.dialogRef.close();
  }

  nextStep(): void {
    if (this.step < 9) {
      this.step++;
      this.stepper.next();
    }
  }

  prevStep(): void {
    if (this.step > 0) {
      this.step--;
      this.stepper.previous();
    }
  }

  reset(): void {
    this.step = 0;
    this.isDoneStepEnabled = false;
    this.stepper.reset();
  }

  onStepChange(event: StepperSelectionEvent): void {
    this.step = event.selectedIndex;
  }

  onSupportingMediaClick(): void {}
}
