export interface ISpeedList {
  speedEvents: ISpeedElement[];
  totalRecordCount: number;
}

export interface ISpeedElement {
  id: number;
  driverId: string;
  vehicleId: string;
  longitude: string;
  latitude: string;
  acutal: number;
  posted: number;
  difference: number;
  source: string;
  eventDate?: string;
  date?: string;
  time?: string;
  location?: string;
  severe?: boolean;
  category?: string;
}

export interface ISpeedPercentageList {
  speedPercentageSummaries: ISpeedPercentageElement[];
  totalRecordCount: number;
}

export interface ISpeedPercentageElement {
  id?: number;
  driverId: string;
  date: string;
  totalPercentSpeeding: number;
  totalDriveTime: string;
  totalSpeedingEvents: number;
  totalDriveDistance: string;
  source: string;
  lightCriteria?: [
    {
      lightSpeedingPercentage: number;
      lightSpeedingTime: string;
      lightSpeedingCount: number;
    }
  ];
  moderateCriteria?: [
    {
      moderateSpeedingPercentage: number;
      moderateSpeedingTime: string;
      moderateSpeedingCount: number;
    }
  ];
  heavyCriteria?: [
    {
      heavySpeedingPercentage: number;
      heavySpeedingTime: string;
      heavySpeedingCount: 0;
    }
  ];
  severeCriteria?: [
    {
      severeSpeedingPercentage: number;
      severeSpeedingTime: string;
      severeSpeedingCount: number;
    }
  ];
}

export enum PercentageSpeedingSource {
  SPEEDGAUGE = 'SpeedGauge',
  SAMSARA = 'Samsara',
}
