import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { toTitleCase } from '@app/shared/helpers/string.helper';
import { formatDate } from '@angular/common';
import { InterventionService } from '@app/services/intervention.service';
import { ToasterService } from '@app/services/toaster.service';
import { DialogActions } from '@app/models/dialog-info-sections';
import { Observable, debounceTime, map, of, startWith, switchMap } from 'rxjs';
import { DriverService } from '@app/services/driver.service';
import { DriverIdService } from '@app/services/driver-id.service';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { DialogDriverComponent } from '@app/drivers/dialog-driver/dialog-driver.component';
import { FlyoutType } from '@app/models/fly-out.model';
import { PermissionService } from '@app/services/permission.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-edit-intervention',
  templateUrl: './edit-intervention.component.html',
  styleUrls: ['./edit-intervention.component.scss'],
})
export class EditInterventionComponent {
  @Input() data!: any;
  @Output() saveClicked = new EventEmitter<string>();
  @Output() interventionUpdated = new EventEmitter<any>();

  interventionForm!: FormGroup;
  filteredDrivers!: Observable<any[]>;
  driverSelected!: any;
  toTitleCase = toTitleCase;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private interventionService: InterventionService,
    private toasterService: ToasterService,
    private driverService: DriverService,
    private driverIdService: DriverIdService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.setInterventionForm();
    this.setSubsSearchDriverIdControl();
    this.setDriverSelected();
  }

  setInterventionForm(): void {
    this.interventionForm = this.formBuilder.group({
      key: [{ value: this.data?.key, disabled: true }],
      reason: [this.data?.reason, Validators.required],
      driver: [this.capitalizeWords(this.data?.driverName), Validators.required],
    });
  }

  updateIntervention(request: any, field: string, action: string): void {
    this.interventionService.upsertIntervention(request).subscribe({
      next: () => {
        this.saveClicked.emit(action);
        this.interventionUpdated.emit(request);
        this.toasterService.showSuccess(`The ${field} was updated successfully`);
      },
      error: (error) => console.error(error),
    });
  }

  getUserIdFromLocalStorage(): string {
    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }
    return userId;
  }

  save(): void {
    const request = {
      id: this.data?.id,
      driverId: this.driverSelected?.driverId,
      driverName: this.driverSelected?.driverName,
      terminal: this.driverSelected?.terminal,
      reason: this.interventionForm.get('reason')!.value,
      lastModifiedBy: this.getUserIdFromLocalStorage(),
      organizationId: environment.organizationId,
    };
    this.updateIntervention(request, 'Intervention', DialogActions.UPDATE_CLOSE);
  }

  cancel(): void {
    this.saveClicked.emit(DialogActions.CANCEL);
  }

  goToDriverProfile(): void {
    this.driverIdService.setDriverId(this.data?.driverID);
    const dialogWidth = calculateDialogWidth();
    this.dialog
      .open(DialogDriverComponent, {
        data: {
          ...this.data,
          driverId: this.data.driverID,
          fromEvent: true,
          uniqueIdentifier: FlyoutType.DRIVER,
        },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: '810px',
        panelClass: ['dialog-driver', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.driverIdService.setDriverId(null);
      });
  }

  goToTerminal(terminal: string): void {
    this.dialog.openDialogs.forEach((dialogRef) => dialogRef.close());
    this.router.navigate(['/drivers', terminal]);
  }

  // -- Driver methods --
  onSelectDriver(driver: any): void {
    this.driverSelected = driver;
    this.interventionForm
      .get('driver')!
      .setValue(this.capitalizeWords(this.driverSelected.driverName));
  }

  setSubsSearchDriverIdControl(): void {
    this.filteredDrivers = this.interventionForm.get('driver')!.valueChanges.pipe(
      startWith(''),
      debounceTime(700),
      switchMap((searchTerm) => this.searchDriverList(searchTerm))
    );
  }

  searchDriverList(searchTerm: string): Observable<any[]> {
    if (searchTerm) {
      const params = {
        searchField: searchTerm,
        pageSize: 15,
        pageNumber: 1,
        organizationId: environment.organizationId,
      };
      return this.driverService
        .getDriverList(params)
        .pipe(map((res: any) => res[0].driverSummaries));
    } else {
      return of([]);
    }
  }

  capitalizeWords(str: string): string {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  setDriverSelected(): void {
    this.driverSelected = {
      driverName: this.data?.driverName,
      driverId: this.data?.driverID,
      terminal: this.data?.terminal,
    };
  }
  // -- Driver methods --
}
