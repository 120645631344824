<mat-form-field appearance="outline" class="custom-input">
  <mat-label *ngIf="label">{{ label }}</mat-label>

  <!-- Prefix with optional user image or search icon -->
  <span matPrefix>
    <div class="input-img">
      <i *ngIf="!selectedUserImage" class="fa-solid fa-magnifying-glass"></i>
      <img *ngIf="selectedUserDisplay === assigneeType.UNASSIGNED"
           [src]="selectedUserImage"
           appImgFallback
           alt="Avatar"
           class="user-avatar" />
      <img *ngIf="selectedUserDisplay.trim() && assigneeImages[assignee?.userId]"
           [src]="assigneeImages[assignee?.userId]"
           appImgFallback
           alt="Avatar"
           class="user-avatar" />
      <div *ngIf="
          selectedUserImage &&
          selectedUserDisplay.trim() &&
          selectedUserDisplay !== assigneeType.UNASSIGNED &&
          !assigneeImages[assignee?.userId]
        "
           class="initials"
           [ngClass]="getBackgroundClass(selectedUserDisplay)">
        {{ getInitials(selectedUserDisplay) }}
      </div>
    </div>
  </span>

  <!-- Input field for searching or selecting user -->
  <input *ngIf="editable"
         type="text"
         matInput
         [formControl]="searchUserIdControl"
         [matAutocomplete]="auto"
         [hidden]="false"
         (input)="clearImg()" />
  <input *ngIf="!editable"
         type="text"
         matInput
         [formControl]="searchUserIdControl"
         [disabled]="true"
         [hidden]="false"
         (input)="clearImg()" />

  <!-- Reset button and dropdown icon -->
  <span *ngIf="selectedUserImage" class="input-reset" matSuffix (click)="resetUser()">
    <i class="fa-solid fa-circle-xmark" *ngIf="editable && !disabled"></i>
  </span>
  <span *ngIf="!selectedUserImage" matSuffix class="input-reset chevron-icon">
    <i class="fa-solid fa-chevron-down"></i>
  </span>

  <!-- Autocomplete Dropdown with Loading Spinner -->
  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="onSelectUser($event.option.value)">
    <!-- Loading Spinner Option -->
    <mat-option *ngIf="loading" class="loading-option">
      <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
      <span>Loading assignees...</span>
    </mat-option>

    <!-- Unassigned Option -->
    <mat-option *ngIf="!loading && !hideUnassigned" [value]="assigneeType.UNASSIGNED">
      <div class="driver-option-content">
        <div class="driver-avatar">
          <img [src]="getImageUrl('')" appImgFallback alt="Avatar" class="user-avatar" />
        </div>
        <span>{{ assigneeType.UNASSIGNED }}</span>
      </div>
    </mat-option>

    <!-- Users Options -->
    <ng-container *ngIf="!loading">
      <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
        <div class="driver-option-content">
          <div class="driver-avatar">
            <img *ngIf="!user?.firstName.trim()"
                 [src]="getImageUrl(user.assigneeId)"
                 appImgFallback
                 alt="Avatar"
                 class="user-avatar" />
            <img *ngIf="user?.firstName.trim() && assigneeImages[user.userId]"
                 [src]="assigneeImages[user.userId]"
                 appImgFallback
                 alt="Avatar"
                 class="user-avatar" />
            <div *ngIf="user?.firstName.trim() && !assigneeImages[user.userId]"
                 class="initials"
                 [ngClass]="getBackgroundClass(setFullName(user))">
              {{ getInitials(setFullName(user)) }}
            </div>
          </div>
          <span>{{ user.firstName }} {{ user.lastName }}</span>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>


  <!-- Error Message -->
  <mat-error *ngIf="searchUserIdControl.touched && searchUserIdControl.invalid">
    The field is required
  </mat-error>
</mat-form-field>

<!-- "Assign to Me" Link -->
<a *ngIf="canViewAssignToMe() && hideAssignToMe === false && !disabled"
   class="assign-to-me-link"
   [class.disabled]="!allUsers.length"
   (click)="assignToMe()">
  Assign to me
</a>
