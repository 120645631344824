<article>
  <section [attr.id]="'coaching'">
    <div class="header-block">
      <h2>Coaching</h2>

      <button
        *ngIf="canCreateInterventions()"
        mat-flat-button
        color="primary"
        class="new-item-button"
        (click)="onAddNewClick()"
      >
        <i class="fas fa-plus-circle"></i>
        <span class="button-title">Add New</span>
      </button>
    </div>
    <!-- Coaching Table -->
    <div class="container-table">
      <table
        mat-table
        matSort
        matSortActive="dueDate"
        matSortDisableClear
        matSortDirection="desc"
        (matSortChange)="announceSortChange($event)"
        [dataSource]="coachingDataFiltered"
        class="table-header-sticky-driver-details intervention-table"
      >
        <ng-container matColumnDef="key">
          <th mat-header-cell mat-sort-header="key" *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">{{ element?.key }}</td>
        </ng-container>

        <ng-container matColumnDef="eventType">
          <th mat-header-cell mat-sort-header="eventType" *matHeaderCellDef>
            Event Type
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.eventType ? addSpaceAfterComma(element?.eventType) : "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="reason">
          <th mat-header-cell mat-sort-header="reason" *matHeaderCellDef>Summary</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.reason }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header="status" *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <ng-container [ngSwitch]="element?.status">
              <ng-container *ngFor="let status of statuses">
                <span *ngSwitchCase="status.viewValue">
                  <i [class]="status.icon"></i>
                  <span class="status-text">{{ status.viewValue }}</span>
                </span>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="priority">
          <th mat-header-cell mat-sort-header="priority" *matHeaderCellDef>Priority</th>
          <td mat-cell *matCellDef="let element">
            <div class="safety-indicator {{ element?.priority?.toLowerCase() }}">
              <i class="mark mark-first"></i>
              <i class="mark"></i>
              <i class="mark"></i>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="assignee">
          <th mat-header-cell mat-sort-header="assignee" *matHeaderCellDef>Coach</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-row">
              <img
                *ngIf="
                  element?.assigneeName.trim() === '' ||
                  element?.assigneeName.trim() === assigneeType.UNASSIGNED
                "
                [src]="element?.assignee"
                appImgFallback
                alt="Avatar"
                class="user-avatar margin-right-5px"
              />
              <div
                *ngIf="
                  element?.assigneeName.trim() !== '' &&
                  element?.assigneeName.trim() !== assigneeType.UNASSIGNED
                "
                class="initials initials-table margin-right-5px"
                matTooltip="{{ element.assigneeName }}"
                [ngClass]="getBackgroundClass(element.assigneeName)"
              >
                {{ getInitials(element.assigneeName) }}
              </div>
              <span>{{
                element?.assigneeName.trim() !== ""
                  ? element?.assigneeName
                  : assigneeType.UNASSIGNED
              }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <th mat-header-cell mat-sort-header="dueDate" *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.dueDate | utcDate }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="coachingColumns"></tr>
        <tr
          mat-row
          (click)="onRowClick(row)"
          class="clickable"
          [class.row-selected]="isRowSelected(row.id)"
          *matRowDef="let row; columns: coachingColumns"
        ></tr>
      </table>

      <div *ngIf="isLoadingCoaching" class="spinner-overlay">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>

      <div
        *ngIf="!isLoadingCoaching && coachingDataFiltered.length === 0"
        class="no-records-found"
      >
        <p>No records found.</p>
      </div>

      <hr *ngIf="!isLoadingCoaching && showViewMoreCoaching" />

      <div
        class="view-more"
        *ngIf="!isLoadingCoaching && showViewMoreCoaching"
        (click)="loadMore()"
      >
        <span>{{ isViewMore ? "View More" : "View Less" }}</span>
        <mat-icon matIconSuffix>{{
          isViewMore ? "expand_more" : "expand_less"
        }}</mat-icon>
      </div>
    </div>
  </section>
</article>

<ul *ngIf="sections.length > 1">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li (click)="onNavClick(item.id)" [class.active]="item.id === sectionSelected">
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
