import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser/dist/response/AuthenticationResult';
import { Observable, from, of, firstValueFrom } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { tokenScopes } from '../auth-config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isInitialized = false;
  constructor(private msalService: MsalService) {
    this.initializeMsal();
  }
  private async initializeMsal(): Promise<void> {
    if (!this.isInitialized) {
      await this.msalService.instance.initialize();
      this.isInitialized = true;
      this.setActiveAccount();
    }
  }
  public async ensureMsalInitialized(): Promise<void> {
    if (!this.isInitialized) {
      await this.initializeMsal();
    }
  }
  private setActiveAccount(): void {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  getB2cObjectId(): Observable<string | null> {
    return from(this.waitForAccountData()).pipe(
      switchMap(() => {
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length === 0) {
          console.error('No accounts are signed in.');
          return of(null);
        }

        const activeAccount =
          this.msalService.instance.getActiveAccount() || accounts[0];
        this.msalService.instance.setActiveAccount(activeAccount);

        if (activeAccount.localAccountId) {
          localStorage.setItem('objectId', activeAccount.localAccountId);
          return of(activeAccount.localAccountId);
        } else {
          console.error('No Object ID found in token claims.');
          return of(null);
        }
      }),
      catchError((error) => {
        console.error(error);
        return of(null);
      })
    );
  }

  async waitForAccountData(retryCount = 0): Promise<void> {
    const maxRetries = 10;
    const delay = 1000;

    return new Promise((resolve, reject) => {
      const checkAccounts = () => {
        if (
          this.msalService.instance.getAllAccounts().length > 0 ||
          retryCount >= maxRetries
        ) {
          resolve();
        } else {
          setTimeout(() => {
            this.waitForAccountData(retryCount + 1)
              .then(resolve)
              .catch(reject);
          }, delay);
        }
      };

      checkAccounts();
    });
  }

  async acquireGraphToken(): Promise<string | null> {
    try {
      await this.ensureMsalInitialized();

      const account = this.msalService.instance.getActiveAccount() || this.msalService.instance.getAllAccounts()[0];
      if (!account) {
        return null;
      }

      const tokenResponse = await firstValueFrom(
        this.msalService.acquireTokenSilent({
          scopes: [environment.graphTokenScope], 
          account: account,
        })
      );

      return tokenResponse?.accessToken || null;
    } catch (silentError) {
      console.warn('Silent token acquisition failed. Trying interactive login.');

      try {
        const interactiveResponse = await firstValueFrom(
          this.msalService.acquireTokenPopup({
            scopes: [environment.graphTokenScope],
          })
        );
        return interactiveResponse?.accessToken || null;
      } catch (popupError) {
        console.error('Error acquiring token:', popupError);
        return null;
      }
    }
  }

  async acquireTokenWithScope(scopes: string[]): Promise<string | null> {
    try {
      const account = this.msalService.instance.getActiveAccount() ||
        this.msalService.instance.getAllAccounts()[0];
      if (!account) {
        console.error('No active account found.');
        return null;
      }

      const tokenResponse = await firstValueFrom(
        this.msalService.acquireTokenSilent({ scopes, account })
      );
      return tokenResponse?.accessToken || null;
    } catch (silentError) {
      console.warn('Silent token acquisition failed. Trying interactive login.');

      try {
        const interactiveResponse = await firstValueFrom(
          this.msalService.acquireTokenPopup({ scopes })
        );
        return interactiveResponse?.accessToken || null;
      } catch (popupError) {
        console.error('Error acquiring token:', popupError);
        return null;
      }
    }
  }

  // Methods to acquire tokens for specific APIs
  async acquireDataApiToken(): Promise<string | null> {
    return this.acquireTokenWithScope(tokenScopes.dataApiScope);
  }

  async acquireAdminApiToken(): Promise<string | null> {
    return this.acquireTokenWithScope(tokenScopes.adminApiScope);
  }

  async acquireComplianceApiToken(): Promise<string | null> {
    return this.acquireTokenWithScope(tokenScopes.complianceApiScope);
  }

  async acquireGraphApiToken(): Promise<string | null> {
    return this.acquireTokenWithScope(tokenScopes.graphApiScope);
  }


  handleRedirectCallback() {
    this.ensureMsalInitialized().then(() => {
      this.msalService.instance.handleRedirectPromise()
        .then((response) => {
          if (response && response.account) {
            this.msalService.instance.setActiveAccount(response.account); 
          }
          window.location.href = '/'; 
        })
        .catch((error) => {
          console.error('Error handling redirect callback:', error);
        });
    }).catch((error) => {
      console.error('Error initializing MSAL instance:', error);
    });
  }
}
