import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';

import { DialogSettingsComponent } from './dialog-settings/dialog-settings.component';
import { DialogNewAccidentComponent } from '@app/shared/components/dialog-new-accident/dialog-new-accident.component';
import { DialogNewInjuryComponent } from '@app/shared/components/dialog-new-injury/dialog-new-injury.component';

import { ProfileService } from '../../services/profile.service';
import { AuthService } from '@app/services/auth.service';
import { PermissionService } from '@app/services/permission.service';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { DialogProfileComponent } from './dialog-profile/dialog-profile.component';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit {
  @Input() isExpanded!: boolean;
  @Input() type?: string = '';
  @Output() menuItemClicked: EventEmitter<boolean> = new EventEmitter();
  circleColor = '';
  photoUrl = null;
  name = '';
  userData!: any;

  isDropdownMenuOpened = false;
  dialogRef: MatDialogRef<DialogProfileComponent> | null = null;

  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  private readonly colors = [
    '#EB7181', // red
    '#468547', // green
    '#FFD558', // yellow
    '#3670B2', // blue
  ];

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  constructor(
    private profileService: ProfileService,
    private dialog: MatDialog,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService,
    private permissionService: PermissionService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  get isMobile(): boolean {
    return this.type === 'mobile';
  }

  ngOnInit(): void {
    this.authService
      .ensureMsalInitialized()
      .then(() => {
        this.getB2cObjectId();
        this.getUserInfoFromLocalStorage();
        this.profileService.getProfile().subscribe((data) => {
          this.setLoginDisplay();
        });
      })
      .catch((error: any) => {
        console.error('Error initializing MSAL:', error);
      });
  }

  getUserInfoFromLocalStorage(): void {
    setTimeout(() => {
      const storedData = localStorage.getItem('userData');
      if (storedData) {
        this.userData = JSON.parse(storedData);
        this.photoUrl = this.userData.userInfo.previewUrl;
        this.name = `${this.userData.userInfo.firstName} ${this.userData.userInfo.lastName}`;
      }
    }, 1000);
  }

  onMenuItemClick(item: string = ''): void {
    this.isDropdownMenuOpened = false;
    switch (item) {
      case 'settings':
        this.dialog.open(DialogSettingsComponent, {
          panelClass: 'single',
          maxWidth: '450px',
          width: '100%',
          autoFocus: false,
        });
        break;
      default:
        this.menuItemClicked.next(true);
        break;
    }
  }

  openAccountProfileModal(): void {
    this.isDropdownMenuOpened = false;
    this.dialogRef = this.dialog.open(DialogProfileComponent, {
      panelClass: 'single',
      maxWidth: '660px',
      width: '100%',
      autoFocus: false,
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getUserInfoFromLocalStorage();
      }
    });
  }

  onNewAccidentClick(): void {
    this.dialog.open(DialogNewAccidentComponent, {
      panelClass: 'single',
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
    });
  }

  onNewInjuryClick(): void {
    this.dialog.open(DialogNewInjuryComponent, {
      panelClass: 'single',
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
    });
  }

  onProfileMenuToggle(): void {
    this.isDropdownMenuOpened = !this.isDropdownMenuOpened;
  }

  login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  logout(): void {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
    localStorage.removeItem('userData');
    localStorage.removeItem('objectId');
    localStorage.removeItem('selectedOrganization');
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  getB2cObjectId(): void {
    this.authService.getB2cObjectId().subscribe({
      next: (objectId) => {
        if (objectId) {
          this.loadUserPermissionsAndProfile(objectId);
          // this.fetchUserImage(objectId);
        }
      },
      error: (error) => console.log(error),
    });
  }

  loadUserPermissionsAndProfile(objectId: string): void {
    this.permissionService.loadUserData(objectId).subscribe({
      next: (userData) => {
        this.name = `${userData.userInfo.firstName} ${userData.userInfo.lastName}`;
        console.log('User data loaded', userData);
      },
      error: (error) => console.log('Error loading user data:', error),
    });
  }

  canCreateAccidents(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Accident');
  }

  canCreateInjury(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Injury');
  }
}
