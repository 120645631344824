import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { OrganizationService } from '@app/services/organization.service';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private baseUrl = environment.dataApiUrl;

  private selectedOrganization: any = {};

  constructor(
    private http: HttpClient,
    private organizationService: OrganizationService
  ) {
    this.loadSelectedOrganization();
    this.subscribeToOrganizationChange();
  }

  loadSelectedOrganization(): void {
    let retries = 20;

    const tryLoadOrganization = () => {
      const storedOrganization = localStorage.getItem('selectedOrganization');
      if (storedOrganization) {
        this.selectedOrganization = JSON.parse(storedOrganization);
      } else if (retries > 0) {
        retries--;
        setTimeout(tryLoadOrganization, 1000);
      } else {
        console.error('Failed to load organization after maximum retries');
      }
    };

    tryLoadOrganization();
  }

  subscribeToOrganizationChange(): void {
    this.organizationService.organization$.subscribe((organization) => {
      if (organization) {
        this.selectedOrganization = organization;
      }
    });
  }

  upload(
    formData: any,
    entityType: string,
    entityId: string
  ): Observable<HttpResponse<any>> {
    const uploadUrl = `${this.baseUrl}/media/upload?entityType=${entityType}&entityId=${entityId}`;
    return this.http.post<HttpResponse<any>>(uploadUrl, formData, {
      observe: 'response',
      responseType: 'json',
    });
  }

  downloadFileList(entityType: string, entityId: string): Observable<any> {
    const url = `${this.baseUrl}/media/fileList?entityType=${entityType}&entityId=${entityId}`;
    return this.http.get<any>(url);
  }

  downloadFile(
    entityType: string,
    entityId: string,
    fileName?: string
  ): Observable<Blob> {
    let url = `${this.baseUrl}/media/download?entityType=${entityType}&entityId=${entityId}`;

    if (fileName) {
      url += `&fileName=${fileName}`;
    }

    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  deleteFile(entityId: string, entityType: string): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}/media/delete?entityType=${entityType}&entityId=${entityId}`,
      {
        responseType: 'text',
      }
    );
  }

  getDriverImageURL(driverId: string): string {
    const organizationId = this.selectedOrganization?.organizationId;
    return `${
      environment.driverImageBaseUrl
    }/organizations/${organizationId?.toLowerCase()}/driver-images/${driverId}/profile_thumbnail?${
      environment.driverImageToken
    }`;
  }

  downloadHistory(driverGuid: string): Observable<Blob> {
    const url = `${this.baseUrl}/report/driver/event?driverGuid=${driverGuid}`;
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }
}
