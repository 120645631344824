import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StatusType } from '@app/models/status.model';

import { EventService } from '@app/services/event.service';
import { ToasterService } from '@app/services/toaster.service';
import { PermissionService } from '@app/services/permission.service';
import { DialogNewInterventionComponent } from '../dialog-new-intervention/dialog-new-intervention.component';
import { ChangeStatusConfirmModalComponent } from './change-status-confirm-modal/change-status-confirm-modal.component';

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss'],
})
export class StatusSelectComponent implements OnInit {
  @Input() id!: string;
  @Input() driverId!: string;
  @Input() status!: string;
  @Input() oldStatus!: string;
  @Input() border: boolean = false;
  @Input() eventData!: any;
  @Input() disabled: boolean = false;
  @Input() hasInterventionLinked = true;
  @Output() selectionChanged = new EventEmitter<string>();

  statuses = [
    {
      value: StatusType.OPEN.toLowerCase(),
      viewValue: StatusType.OPEN,
      icon: 'fa-solid fa-circle-notch',
      disabled: false,
    },
    {
      value: StatusType.ASSIGNED.toLowerCase(),
      viewValue: StatusType.ASSIGNED,
      icon: 'fa-solid fa-user-circle icon-second-blue',
      disabled: false,
    },
    {
      value: StatusType.CLOSED.toLowerCase(),
      viewValue: StatusType.CLOSED,
      icon: 'fa-solid fa-circle-xmark icon-red icon-default-size',
      disabled: false,
    },
    {
      value: StatusType.NON_EVENT.toLowerCase(),
      viewValue: StatusType.NON_EVENT,
      icon: 'fa-solid fa-triangle-exclamation icon-red icon-default-size',
      disabled: false,
    },
  ];

  statusType = StatusType;

  selectedOrganization: any = {};

  constructor(
    private eventService: EventService,
    private toasterService: ToasterService,
    private dialog: MatDialog,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.filterStatusesByPermissions();
    if (this.status) {
      this.oldStatus = this.status;
      this.status =
        this.statuses.find((s) => s.value === this.status)?.value || this.status;
    }
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  filterStatusesByPermissions(): void {
    const canLink = this.canLinkEventsAndInterventions();
    const canClassify = this.canClassifyNonEvents();

    this.statuses = this.statuses.map((status) => {
      if (status.viewValue === StatusType.OPEN) {
        return { ...status, disabled: !(canLink || canClassify) };
      }
      if (status.viewValue === StatusType.ASSIGNED) {
        return { ...status, disabled: !canLink };
      }
      if (
        status.viewValue === StatusType.CLOSED ||
        status.viewValue === StatusType.NON_EVENT
      ) {
        return { ...status, disabled: !canClassify };
      }
      return status;
    });
  }

  onSelectionChange(newStatus: any): void {
    const { value } = newStatus;
    const hasIntervention = this.hasInterventionLinked;

    // Define actions based on conditions
    // prettier-ignore
    const actions = {
      isNonEvent: () => this.openChangeStatusModal(newStatus),
      isClosedToAssignedWithoutIntervention: () => this.openCreateInterventionModal(newStatus),
      isClosedToAssignedWithIntervention: () => this.updateStatus(newStatus),
      isOpenToAssignedWithoutIntervention: () => this.openCreateInterventionModal(newStatus),
      isOpenToAssignedWithIntervention: () => this.updateStatus(newStatus),
      default: () => {
        this.oldStatus = value;
        this.updateStatus(newStatus);
      },
    };

    // Determine the condition and execute the corresponding action
    // prettier-ignore
    const condition =
      value === StatusType.NON_EVENT || this.oldStatus === StatusType.NON_EVENT
        ? 'isNonEvent'
        : this.oldStatus === StatusType.CLOSED && value === StatusType.ASSIGNED && !hasIntervention
        ? 'isClosedToAssignedWithoutIntervention'
        : this.oldStatus === StatusType.CLOSED && value === StatusType.ASSIGNED && hasIntervention
        ? 'isClosedToAssignedWithIntervention'
        : this.oldStatus === StatusType.OPEN && value === StatusType.ASSIGNED && !hasIntervention
        ? 'isOpenToAssignedWithoutIntervention'
        : this.oldStatus === StatusType.OPEN && value === StatusType.ASSIGNED && hasIntervention
        ? 'isOpenToAssignedWithIntervention'
        : 'default';

    // Execute the determined action
    actions[condition]();
  }

  openCreateInterventionModal(newStatus: any): void {
    this.dialog
      .open(DialogNewInterventionComponent, {
        width: '800px',
        panelClass: 'single',
        data: {
          event: this.eventData,
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          this.updateStatus(newStatus);
          this.oldStatus = newStatus.value;
        } else {
          this.status = this.oldStatus;
        }
      });
  }

  openChangeStatusModal(newStatus: any): void {
    this.dialog
      .open(ChangeStatusConfirmModalComponent, {
        width: '660px',
        data: {
          event: this.eventData,
          newStatus: newStatus.value,
          status: this.oldStatus,
          hasInterventionLinked: this.hasInterventionLinked,
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          this.updateStatus(newStatus);
          this.oldStatus = newStatus.value;
        } else {
          this.status = this.oldStatus;
        }
      });
  }

  getUserIdFromLocalStorage(): string {
    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }
    return userId;
  }

  updateStatus(newStatus: any): void {
    const request = {
      id: this.id,
      status: newStatus.value,
      lastModifiedBy: this.getUserIdFromLocalStorage(),
      organizationId: this.selectedOrganization.organizationId,
    };
    this.selectionChanged.emit(newStatus.value);
    this.eventService.updateEvent(request).subscribe({
      next: () => this.toasterService.showSuccess('The Status was updated successfully'),
      error: (error) => console.error(error),
    });
  }

  canLinkEventsAndInterventions(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Events');
  }

  canClassifyNonEvents(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Classify Non-Events');
  }
}
