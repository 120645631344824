import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private organizationSource = new BehaviorSubject<any>(null);
  organization$ = this.organizationSource.asObservable();

  changeOrganization(organization: any): void {
    this.organizationSource.next(organization);
  }
}
