import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { OrganizationService } from '@app/services/organization.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Output() toggleMobileMenu: EventEmitter<boolean | null> = new EventEmitter();
  isMenuOpened = false;
  loginDisplay = false;

  organizations: any[] = [];
  selectedOrganization: any = null;

  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router, private organizationService: OrganizationService) {
    router.events.subscribe(() => {
      this.isMenuOpened = false;
    });
  }

  async ngOnInit(): Promise<void> {
    await this.loadSelectedOrganization();
    this.getUserOrganizations();
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async loadSelectedOrganization(): Promise<void> {
    let retries = 20;

    while (retries > 0) {
      const storedOrganization = localStorage.getItem('selectedOrganization');

      if (storedOrganization) {
        this.selectedOrganization = JSON.parse(storedOrganization);
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));
      retries--;
    }
  }

  getUserOrganizations(): void {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);

      // Filter organizations to include only those with a permissions array
      this.organizations = (
        parsedData?.permissions?.organizationPermissions || []
      ).filter(
        (org: any) => Array.isArray(org.permissions) && org.permissions.length > 0
      );

      // Sort organizations alphabetically by name
      this.organizations.sort((a: any, b: any) =>
        a.organizationName.localeCompare(b.organizationName)
      );

      // If there is only one organization, select it by default
      if (this.organizations.length === 1) {
        this.selectedOrganization = this.organizations[0];
        this.saveSelectedOrganization();
      } else if (this.selectedOrganization) {
        // Ensure that the selected organization is an object from the list
        const matchingOrganization = this.organizations.find(
          (org) => org.organizationId === this.selectedOrganization.organizationId
        );
        if (matchingOrganization) {
          this.selectedOrganization = matchingOrganization;
        }
      } else {
        // If there is no selected organization, select the first one
        this.selectedOrganization = this.organizations[0];
        this.saveSelectedOrganization();
      }
    }
  }

  onMenuClick(): void {
    this.isMenuOpened = !this.isMenuOpened;
    this.toggleMobileMenu.emit(null);
  }

  onOrganizationChange(newOrganization: any): void {
    this.selectedOrganization = newOrganization.value;
    this.saveSelectedOrganization();
    this.organizationService.changeOrganization(this.selectedOrganization);
  }

  saveSelectedOrganization(): void {
    localStorage.setItem(
      'selectedOrganization',
      JSON.stringify(this.selectedOrganization)
    );
    // this.updateVisibleTabs();
  }
}
