import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Observable,
  catchError,
  debounceTime,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import { AssigneeType } from '@app/models/status.model';
import { UserManagementService } from '@app/services/user-management.service';
import { environment } from '@env/environment';
import { InterventionService } from '@app/services/intervention.service';
import { ToasterService } from '@app/services/toaster.service';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { MediaService } from '@app/services/media.service';

@Component({
  selector: 'app-edit-assigned-coach',
  templateUrl: './edit-assigned-coach.component.html',
  styleUrls: ['./edit-assigned-coach.component.scss'],
})
export class EditAssignedCoachComponent implements OnInit {
  @Input() assigneeName: string = '';
  @Input() assignee!: any;
  @Input() intervention!: any;
  @Input() showDeleted: boolean = false;
  @Output() userIdSelected = new EventEmitter<any>();

  searchUserIdControl = new FormControl();
  filteredUsers!: Observable<any[]>;
  selectedUserImage: string = '';
  selectedUserDisplay: string = '';

  assigneeType = AssigneeType;
  assigneeImages: { [assigneeId: string]: string | null } = {};

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  constructor(
    private userManagementService: UserManagementService,
    private interventionService: InterventionService,
    private toasterService: ToasterService,
    private mediaService: MediaService
  ) {
    this.searchUserIdControl = new FormControl('');
    this.setSubsSearchUserIdControl();
  }

  ngOnInit(): void {
    this.searchUserList('');
    if (this.assigneeName) {
      const user = {
        userId: this.assignee,
        firstName:
          this.assigneeName === ' ' ? AssigneeType.UNASSIGNED : this.assigneeName,
        lastName: '',
      };
      this.onSelectUser(user, false);
    }
  }

  setSubsSearchUserIdControl(): void {
    this.filteredUsers = this.searchUserIdControl.valueChanges.pipe(
      startWith(''),
      debounceTime(700),
      switchMap((searchTerm) => this.searchUserList(searchTerm))
    );
  }

  searchUserList(searchTerm: string): Observable<any> {
    const params = { searchField: searchTerm, type: 'Interventions' };
    return this.userManagementService.getUsers(params).pipe(
      switchMap((res: any) => {
        const users = res[0].userResults.filter(
          (user: any) => this.showDeleted || user.active
        );
        const imageLoadObservables = users.map((user: any) =>
          this.getAssigneeImage(user.userId).pipe(
            map((imageUrl) => {
              user.imageUrl = imageUrl;
              return user;
            })
          )
        );
        return forkJoin(imageLoadObservables);
      })
    );
  }

  getAssigneeImage(userId: string): Observable<any> {
    if (this.assigneeImages[userId]) {
      return of(this.assigneeImages[userId]);
    }

    return this.mediaService.downloadFile('userProfileImage', userId).pipe(
      map((blob: Blob) => {
        const imageUrl = URL.createObjectURL(blob);
        this.assigneeImages[userId] = imageUrl;
        return imageUrl;
      }),
      catchError(() => of(null))
    );
  }

  setFullName(user: any): string {
    return `${user.firstName} ${user.lastName}`;
  }

  onSelectUser(user: any, saveUser: boolean = false): void {
    let assignee = '';
    if (user === AssigneeType.UNASSIGNED) {
      this.selectedUserDisplay = AssigneeType.UNASSIGNED;
      this.selectedUserImage = this.getImageUrl('.');
      assignee = AssigneeType.UNASSIGNED;
    } else {
      this.selectedUserDisplay = `${user.firstName} ${user.lastName}`;
      this.selectedUserImage = this.getImageUrl(user.userId);
      assignee = user.userId;
    }
    this.searchUserIdControl.setValue(this.selectedUserDisplay);

    if (saveUser) {
      let userId = '';
      const storedData = localStorage.getItem('userData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        userId = parsedData.userInfo.objectId;
      }

      const request = {
        id: this.intervention.id,
        assignee,
        lastModifiedBy: userId,
        organizationId: environment.organizationId,
      };
      this.interventionService.upsertIntervention(request).subscribe({
        next: () => {
          this.toasterService.showSuccess(`The Assigned Coach was updated successfully`);
          this.userIdSelected.emit(user);
        },
        error: (error) => console.error(error),
      });
    }
  }

  capitalizeWords(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  resetUser(): void {
    this.selectedUserImage = '';
    this.searchUserIdControl.setValue('');
  }

  clearImg(): void {
    this.selectedUserImage = '';
  }

  getImageUrl(userId: string): string {
    return `${environment.driverImageBaseUrl}/${userId}/profile_thumbnail${environment.driverImageToken}`;
  }
}
