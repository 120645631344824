import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillEditorComponent } from 'ngx-quill';

//Material
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MatMenuModule } from '@angular/material/menu';

import { EditInterventionComponent } from './edit-intervention/edit-intervention.component';
import { TabSectionDetailsComponent } from './tab-section-details/tab-section-details.component';
import { TabSectionHistoryComponent } from './tab-section-history/tab-section-history.component';
import { TabSectionInfoComponent } from './tab-section-info/tab-section-info.component';
import { SectionHistoryEventsComponent } from './tab-section-history/section-history-events/section-history-events.component';
import { SectionHistoryCoachingComponent } from './tab-section-history/section-history-coaching/section-history-coaching.component';
import { SectionHistoryEventsTableComponent } from './tab-section-history/section-history-events-table/section-history-events-table.component';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';
import { StatusCoachingSelectModule } from '@app/shared/components/status-coaching-select/status-coaching-select.module';
import { NotesModule } from '@app/shared/components/notes/notes.module';
import { EditorModule } from '@app/shared/components/editor/editor.module';
import { DialogCoachingDetailsComponent } from './dialog-coaching-details.component';
import { CapitalizePipe } from '@app/shared/pipes/capitalize.pipe';
import { EditAssignedCoachComponent } from './edit-intervention/edit-assigned-coach/edit-assigned-coach.component';
import { ImgFallbackModule } from '@app/shared/directives/img-fallback/img-fallback.module';
import { StarRatingModule } from '@app/shared/components/star-rating/star-rating.module';

@NgModule({
  declarations: [
    DialogCoachingDetailsComponent,
    EditInterventionComponent,
    TabSectionDetailsComponent,
    TabSectionHistoryComponent,
    SectionHistoryEventsComponent,
    SectionHistoryCoachingComponent,
    SectionHistoryEventsTableComponent,
    TabSectionInfoComponent,
    EditAssignedCoachComponent,
  ],
  exports: [DialogCoachingDetailsComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatIconModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    UtcDatePipe,
    StatusCoachingSelectModule,
    NotesModule,
    EditorModule,
    CapitalizePipe,
    QuillEditorComponent,
    ImgFallbackModule,
    MatMenuModule,
    StarRatingModule,
  ],
})
export class DialogCoachingDetailsModule {}
