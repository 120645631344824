import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { DialogEventComponent } from '@app/shared/components/dialog-event/dialog-event.component';
import { EventService } from '@app/services/event.service';
import { addSpaceAfterComma } from '@app/shared/helpers/string.helper';
import { DialogDriverEventsFiltersComponent } from '@app/drivers/dialog-driver/dialog-driver-events-filters/dialog-driver-events-filters.component';
import {
  convertFromDatePickerToDatabaseFormat,
  formatOnlyDate,
} from '@app/shared/helpers/date.helper';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { DialogEventService } from '@app/shared/components/dialog-event/dialog-event.service';
import { FlyoutType } from '@app/models/fly-out.model';
import { FlyoutService } from '@app/services/flyout.service';
import { InterventionService } from '@app/services/intervention.service';
import { DialogCoachingDetailsComponent } from '@app/shared/components/dialog-coaching-details/dialog-coaching-details.component';
import { PermissionService } from '@app/services/permission.service';
import { ExpandViewService } from '@app/services/expand-view.service';
import { MediaService } from '@app/services/media.service';
import { formatDate } from '@angular/common';
import { ToasterService } from '@app/services/toaster.service';

@Component({
  selector: 'app-tab-sections-events',
  templateUrl: './tab-sections-events.component.html',
  styleUrls: ['../tab-sections.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: 0,
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'visible',
          opacity: 1,
        })
      ),
      transition('collapsed <=> expanded', [animate('300ms ease-out')]),
    ]),
  ],
})
export class TabSectionsEventsComponent implements OnInit, OnDestroy {
  @Input() driverId!: string;
  @Input() driverGuid!: string;
  @Input() showSmaller!: boolean;

  isLoading: boolean = false;
  maxDisplayedRecords: number = 10;
  defaultPageSize = 50;
  dataSource: any[] = [];
  dataSourceFiltered: any[] = [];

  displayedColumns: string[] = [
    'type',
    'criteria',
    'severity',
    'interventions',
    'processDate',
    'status',
  ];

  selectedRow: string | null = null;
  addSpaceAfterComma = addSpaceAfterComma;
  formatOnlyDate = formatOnlyDate;

  isViewMore: boolean = true;
  showViewMore: boolean = false;
  pageSize!: number;
  startPageIdx!: number;
  endPageIdx!: number;
  totalRecordCount: number = 0;

  isModalOpen: boolean = false;
  dialogRef: MatDialogRef<DialogDriverEventsFiltersComponent> | null = null;
  isExpanded!: boolean;
  filtersForm!: any;
  filters: string[] = [];

  selectedOption: string = 'list';

  accidentData: any[] = [];
  telematicsData: any[] = [];
  hosData: any[] = [];
  inspectionData: any[] = [];
  speedingData: any[] = [];
  seatbeltData: any[] = [];

  isSectionExpanded: any = {
    accident: false,
    telematics: false,
    hos: false,
    inspection: false,
    speeding: false,
    seatbelt: false,
  };

  subscription!: Subscription;
  isExpandViewTriggered: boolean = false;

  selectedOrganization: any = {};

  private destroy$ = new Subject<void>();

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private eventService: EventService,
    private dialog: MatDialog,
    private dialogEventService: DialogEventService,
    private flyoutService: FlyoutService,
    private interventionService: InterventionService,
    private permissionService: PermissionService,
    private expandViewService: ExpandViewService,
    private mediaService: MediaService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.setSubscriptionToRefreshList();
    this.resetFiltersForm();
    this.setPaginatorConfig();
    this.fetchEventsListData();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  setSubscriptionToRefreshList(): void {
    this.dialogEventService.statusSelectionChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this.fetchEventsListData(), 200);
      });
  }

  toggleSection(section: string): void {
    this.isSectionExpanded[section] = !this.isSectionExpanded[section];
  }

  onOptionChange(option: string): void {
    this.selectedOption = option;
  }

  resetFiltersForm(): void {
    this.filtersForm = {
      startDate: '',
      endDate: '',
      type: [],
      severity: [],
      status: [],
    };
  }

  setPaginatorConfig(): void {
    this.pageSize = this.maxDisplayedRecords;
    this.startPageIdx = 1;
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
      if (this.isExpandViewTriggered) {
        this.expandViewService.incrementExpandView();
        this.isExpandViewTriggered = false;
      }
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.fetchEventsListData();
  }

  setShowViewMore(): void {
    if (this.dataSource.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  private prepareParams(): any {
    let params: any = {
      driverGuid: this.driverGuid.toLowerCase(),
      pageNumber: 1,
      pageSize: 10000,
      severity: this.filtersForm?.severity?.join(','),
      status: this.filtersForm?.status?.join(','),
      type: this.filtersForm?.type?.join(','),
      startDate: this.filtersForm.startDate
        ? convertFromDatePickerToDatabaseFormat(this.filtersForm.startDate)
        : '',
      endDate: this.filtersForm.endDate
        ? convertFromDatePickerToDatabaseFormat(this.filtersForm.endDate)
        : '',
    };
    return params;
  }

  //prettier-ignore
  separateDataByType(): void {
    this.accidentData = this.dataSource.filter(
      (item) => item.type.toLowerCase() === 'accident'
    );
    this.telematicsData = this.dataSource.filter(
      (item) => item.type.toLowerCase() === 'telematics'
    );
    this.hosData = this.dataSource.filter(
      (item) => item.type.toLowerCase() === 'hos'
    );
    this.inspectionData = this.dataSource.filter(
      (item) => item.type.toLowerCase() === 'inspection'
    );
    this.speedingData = this.dataSource.filter(
      (item) => item.type.toLowerCase() === 'speeding'
    );
    this.seatbeltData = this.dataSource.filter(item => item.type.toLowerCase() === 'seatbelt');

    this.isSectionExpanded.accident = this.accidentData.length > 0;
    this.isSectionExpanded.telematics = this.telematicsData.length > 0;
    this.isSectionExpanded.hos = this.hosData.length > 0;
    this.isSectionExpanded.inspection = this.inspectionData.length > 0;
    this.isSectionExpanded.speeding = this.speedingData.length > 0;
    this.isSectionExpanded.seatbelt = this.seatbeltData.length > 0;
  }

  parseAndAddInterventionKeys(eventSummaries: any) {
    eventSummaries.forEach((event: any) => {
      try {
        if (event.interventions) {
          const formattedInterventions =
            '[' + event.interventions.replace(/}\s*,\s*{/g, '},{') + ']';
          const interventions = JSON.parse(formattedInterventions);
          event.interventionObjects = interventions;
        } else {
          event.interventionObjects = [];
        }
      } catch (error) {
        console.error('Error parsing interventions:', error);
      }
    });
  }

  onInterventionSelected(intervention: any, row: any): void {
    this.interventionService.getIntervention(intervention.id).subscribe({
      next: (res) => {
        this.openInterventionDetail(res[0], row);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  openInterventionDetail(intervention: any, row: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.COACHING);
    const dialogWidth = calculateDialogWidth(
      this.flyoutService.getFlyoutOffsetBasedOnIndex()
    );

    this.dialog
      .open(DialogCoachingDetailsComponent, {
        data: {
          key: intervention.ticketId,
          driverName: row.driverName,
          driverId: intervention.driverID,
          driverGuidId: row.driverGuid,
          ...intervention,
          uniqueIdentifier: FlyoutType.COACHING,
        },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: dialogWidth,
        panelClass: ['dialog-event', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedRow = null;
      });
  }

  fetchEventsListData(): void {
    this.isLoading = true;
    const params = this.prepareParams();
    this.eventService.getEventsList(params).subscribe({
      next: (res: any) => {
        this.isLoading = false;
        if (res[0].totalRecordCount) {
          this.parseAndAddInterventionKeys(res[0].eventSummaries);
          this.dataSource = res[0].eventSummaries;
          this.totalRecordCount = res[0].totalRecordCount;
          if (this.totalRecordCount) {
            this.setDataFilteredAndEndPageIdx();
            this.setShowViewMore();
          }
        } else {
          this.dataSource = [];
          this.dataSourceFiltered = [];
          this.totalRecordCount = 0;
          this.showViewMore = false;
        }
        this.separateDataByType();
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  announceSortChange(sortState: Sort): void {
    const data = this.dataSourceFiltered.slice();
    if (!sortState.active || sortState.direction === '') {
      this.dataSourceFiltered = data;
      return;
    }

    this.dataSourceFiltered = data.sort((a, b) => {
      const isAsc = sortState.direction === 'asc';
      switch (sortState.active) {
        case 'type':
          return this.compare(a.type, b.type, isAsc);
        case 'criteria':
          return this.compare(a.criteria, b.criteria, isAsc);
        case 'processDate':
          return this.compareDate(a.processDate, b.processDate, isAsc);
        case 'severity':
          return this.compare(a.severity, b.severity, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  compareDate(date1: string, date2: string, isAsc: boolean): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (d1 < d2 ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  setDataFilteredAndEndPageIdx(): void {
    if (!this.endPageIdx) {
      this.endPageIdx =
        this.defaultPageSize < this.totalRecordCount
          ? this.defaultPageSize
          : this.totalRecordCount;
    }
    this.dataSourceFiltered = this.dataSource?.slice(0, this.pageSize);
  }

  onSelectClick(event: Event): void {
    event.stopPropagation();
  }

  onRowClick(data: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.EVENT);
    this.selectedRow = data?.id;
    setTimeout(() => {
      const dialogWidth = calculateDialogWidth(
        this.flyoutService.getFlyoutOffsetBasedOnIndex()
      );

      this.dialog
        .open(DialogEventComponent, {
          data: {
            ...data,
            showInfo: true,
            uniqueIdentifier: FlyoutType.EVENT,
          },
          position: {
            right: '0',
            top: '70px',
          },
          width: dialogWidth,
          minWidth: dialogWidth,
          panelClass: ['dialog-event', 'animate-slide-in-left'],
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.selectedRow = null;
        });
    }, 100);
  }

  isRowSelected(id: string): boolean {
    return id === this.selectedRow;
  }

  onPageChangeClick(event: PageEvent): void {
    localStorage.setItem(
      'eventsListPage',
      JSON.stringify({ pageIndex: event.pageIndex, pageSize: event.pageSize })
    );
    this.fetchEventsListData();
  }

  openFiltersModal(event: MouseEvent): void {
    const clickTarget = event.target as HTMLElement;
    const rect = clickTarget.getBoundingClientRect();

    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this.dialogRef = this.dialog.open(DialogDriverEventsFiltersComponent, {
        position: {
          top: `${rect.top + 20}px`,
          left: `${rect.right - 50}px`,
        },
        width: '485px',
        height: '',
        autoFocus: false,
        data: this.filtersForm,
        id: 'driver-events-filters',
        panelClass: 'single',
      });

      this.dialogRef.afterClosed().subscribe((result: any) => {
        this.isModalOpen = false;
        if (result) {
          if (result !== 'clearAll') {
            this.clearAll();
            this.setFiltersBox(result);
            this.setFiltersForm(result);
            this.fetchEventsListData();
          } else {
            this.clearAll(true);
          }
        }
      });
    }
  }

  setFiltersForm(result: any) {
    this.filtersForm = {
      startDate: result.form.startDate,
      endDate: result.form.endDate,
      severity: result.form.severity,
      status: result.form.status,
      type: result.form.type,
    };
  }

  setFiltersBox(result: any): void {
    if (result.form.startDate) {
      this.filters.push(`Date Range: ${result.form.startDate} - ${result.form.endDate}`);
    }
    if (result.form.severity && result.form.severity.length > 0) {
      result.form.severity.forEach((severity: any) => {
        this.filters.push(`Severity: ${severity}`);
      });
    }
    if (result.form.status && result.form.status.length > 0) {
      result.form.status.forEach((status: any) => {
        this.filters.push(`Status: ${status}`);
      });
    }
    if (result.form.type && result.form.type.length > 0) {
      result.form.type.forEach((type: any) => {
        this.filters.push(`Event Type: ${type}`);
      });
    }
  }

  removeBox(index: number): void {
    const filterToRemove = this.filters[index];
    if (filterToRemove.startsWith('Severity:')) {
      const typeToRemove = filterToRemove.split(': ')[1];
      this.filtersForm.severity = this.filtersForm.severity.filter(
        (item: any) => item !== typeToRemove
      );
    } else if (filterToRemove.startsWith('Status:')) {
      const typeToRemove = filterToRemove.split(': ')[1];
      this.filtersForm.status = this.filtersForm.status.filter(
        (item: any) => item !== typeToRemove
      );
    } else if (filterToRemove.startsWith('Event Type:')) {
      const typeToRemove = filterToRemove.split(': ')[1];
      this.filtersForm.type = this.filtersForm.type.filter(
        (item: any) => item !== typeToRemove
      );
    } else if (filterToRemove.startsWith('Date Range:')) {
      this.filtersForm.startDate = null;
      this.filtersForm.endDate = null;
    }
    this.filters.splice(index, 1);
    this.fetchEventsListData();
  }

  clearAll(fetchOrders: boolean = false): void {
    this.filters = [];
    this.resetFiltersForm();
    if (fetchOrders) {
      this.fetchEventsListData();
    }
  }

  canEditEvents(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Events');
  }

  onDownloadHistory(): void {
    this.mediaService.downloadHistory(this.driverGuid).subscribe({
      next: (blob: Blob) => {
        const formattedDate = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
        const fileName = `${this.driverId} - Event and Intervention History - ${formattedDate}.csv`;

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      },
      error: (error) => {
        this.toasterService.showError('Unable to download CSV. Please try again.');
        console.error('Error downloading CSV:', error);
        this.isLoading = false;
      },
    });
  }
}
