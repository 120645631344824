import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { DialogDriverService } from '@app/services/dialog-driver.service';
import { addSpacesToCamelCase } from '@app/shared/helpers/string.helper';
import { IInspection } from '@app/models/inspection.model';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-inspection-list',
  templateUrl: './inspection-list.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class InspectionListComponent implements OnInit {
  @Input() dateRange: any;
  @Input() driverGUID!: string;
  @Input() maxDisplayedRecords!: number;
  @Input() defaultPageSize!: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  inspectionList!: IInspection[];
  inspectionDataFiltered: IInspection[] = [];
  inspectionColumns: string[] = [
    'inspectionDate',
    'reportNumber',
    'reportState',
    'level',
    'violation',
  ];
  isLoading: boolean = false;
  selectedRow: { sectionName: string; rowId?: string } | null = null;
  dateRangeSubscription!: Subscription;

  isViewMore: boolean = true;
  pageSize!: number;
  showViewMore: boolean = false;
  isExpandViewTriggered: boolean = false;

  subscription!: Subscription;

  private eventIdFromUrl?: string;

  constructor(
    private dialogInfoService: DialogDriverService,
    private expandViewService: ExpandViewService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.pageSize = this.maxDisplayedRecords;
    this.getInspectionSummary();
    this.setDateRangeSubscription();
    this.checkUrlParameters();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  private checkUrlParameters(): void {
    const params = this.route.snapshot.queryParams;
    if (params['activity'] === 'inspections') {
      this.eventIdFromUrl = params['eventId'];
      this.onRowClick({ reportNumber: this.eventIdFromUrl });
      this.eventIdFromUrl = undefined;
    }
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'inspections' });
    this.updateUrlParams({ activity: 'inspections', eventId: row.reportNumber });
  }

  private updateUrlParams(params: { [key: string]: string }): void {
    const url = new URL(window.location.href);
    Object.keys(params).forEach((key) => {
      url.searchParams.set(key, params[key]);
    });
    window.history.replaceState({}, '', url.toString());
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  setDateRangeSubscription(): void {
    this.dateRangeSubscription = this.dialogInfoService.dateChange$.subscribe(() =>
      this.getInspectionSummary()
    );
  }

  ngOnDestroy(): void {
    if (this.dateRangeSubscription) {
      this.dateRangeSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getInspectionSummary(): void {
    this.isLoading = true;
    const { start, end } = this.dateRange.value;
    if (start && end) {
      const request = {
        startDate: start,
        endDate: end,
        driverGUID: this.driverGUID,
      };
      this.dialogInfoService.getInspectionSummary(request).subscribe({
        next: (response: IInspection[]) => {
          this.isLoading = false;
          this.inspectionList = response;
          if (this.inspectionList.length) {
            this.setDataFilteredAndEndPageIdx();
            this.setShowViewMore();
          }
          if (this.isExpandViewTriggered) {
            this.expandViewService.incrementExpandView();
            this.isExpandViewTriggered = false;
          }
        },
        error: (error: any) => {
          this.isLoading = false;
          console.error('Error:', error);
        },
      });
    }
  }

  setDataFilteredAndEndPageIdx(): void {
    this.inspectionDataFiltered = this.inspectionList?.slice(0, this.pageSize);
  }

  setShowViewMore(): void {
    if (this.inspectionList.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  addSpacesToCamelCase(str: string): string {
    return addSpacesToCamelCase(str);
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.getInspectionSummary();
  }
}
