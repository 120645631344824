import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs';

import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';
import { MatStepper } from '@angular/material/stepper';
import { phoneValidator } from '@app/shared/helpers/validators.helper';
import { DriverService } from '@app/services/driver.service';
import { environment } from '@env/environment';

export interface Item {
  id: string;
  name: string;
  state: boolean;
  x: number;
  y: number;
}

@Component({
  selector: 'app-dialog-new-injury',
  templateUrl: './dialog-new-injury.component.html',
  styleUrls: ['./dialog-new-injury.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class DialogNewInjuryComponent implements OnInit, AfterViewInit {
  step: number = 0;
  injuryForm!: FormGroup;
  maxStep: number = 6;

  @ViewChild('stepper') stepper!: MatStepper;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogNewInjuryComponent>,
    private driverService: DriverService
  ) {}

  ngOnInit(): void {
    this.setInitForm();
  }

  setInitForm(): void {
    let driverId = '';
    if (this.data?.driver?.id) {
      driverId = this.data.driver.id;
    }

    let driverName = '';
    if (this.data?.driver?.driverName) {
      driverName = this.data.driver.driverName;
    }

    let organizationId = '';
    if (this.data?.driver?.organizationId) {
      organizationId = this.data.driver.organizationId;
    }
    this.injuryForm = this.fb.group({
      driverInfo: this.fb.group({
        organization: [organizationId, Validators.required],
        driverId: [{ value: '', disabled: true }, Validators.required],
        driverCode: [driverId],
        cellphone: ['', [Validators.required, phoneValidator()]],
        driverName: [{ value: driverName, disabled: true }],
        terminal: [{ value: null, disabled: true }],
        fleetManager: [{ value: null, disabled: true }],
        safetyManager: [{ value: null, disabled: true }],
      }),
      timePlace: this.fb.group({
        dateReport: ['', Validators.required],
        timeReport: ['', Validators.required],
        dateInjury: ['', Validators.required],
        timeInjury: ['', Validators.required],
        addressLine1: [''],
        addressLine2: [''],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zip: [''],
        otherLocationDetails: [''],
      }),
      injuryDetail: this.fb.group({
        injuryReported: [null, Validators.required],
        injuryReportedTo: [null],
        dateInjuryReported: [null],
        timeInjuryReported: [null],
        howInjuryOccur: [null, Validators.required],
      }),
      injuryDescription: this.fb.group({
        areaBodyInjured: [null, Validators.required],
        extentInjuries: [null, Validators.required],
        medicalAssistanceRequired: [null],
        medicalAssistanceReceived: [null],
      }),
      additionalInfo: this.fb.group({
        winterCleats: [null, Validators.required],
        witness: [null, Validators.required],
        witnessInformation: [null],
      }),
      leave: this.fb.group({
        leaveWork: [null, Validators.required],
        dateLeaveWork: [null],
        employeeReturned: [null],
        dateEmployeeReturned: [null],
      }),
    });
  }

  get driverInfoFormGroup(): FormGroup {
    return this.injuryForm.get('driverInfo') as FormGroup;
  }

  get driverNameValue(): string {
    return this.driverInfoFormGroup.get('driverName')?.value;
  }

  get timePlaceFormGroup(): FormGroup {
    return this.injuryForm.get('timePlace') as FormGroup;
  }

  get dateReport(): string {
    return this.timePlaceFormGroup.get('dateReport')?.value;
  }

  get dateInjury(): string {
    return this.timePlaceFormGroup.get('dateInjury')?.value;
  }

  get injuryDetailFormGroup(): FormGroup {
    return this.injuryForm.get('injuryDetail') as FormGroup;
  }

  get injuryDescriptionFormGroup(): FormGroup {
    return this.injuryForm.get('injuryDescription') as FormGroup;
  }

  get additionalInfoFormGroup(): FormGroup {
    return this.injuryForm.get('additionalInfo') as FormGroup;
  }

  get leaveFormGroup(): FormGroup {
    return this.injuryForm.get('leave') as FormGroup;
  }

  ngAfterViewInit(): void {
    this.stepper.selectedIndex = this.step;
  }

  onDialogCloseClick(): void {
    if (this.injuryForm.dirty) {
      const data = {
        title: 'Unsaved changes',
        text: 'You have unsaved changes. Are you sure you want to leave this page? The changes you made will not be saved.',
      };
      this.dialog
        .open(DialogConfirmComponent, { data, width: '500px' })
        .afterClosed()
        .pipe(filter((closeModal: boolean) => closeModal))
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  onDoneClick(): void {
    const request = this.createRequest();
    this.driverService.upsertInjury(request).subscribe({
      next: () => {
        this.showSavedInjuryMessage();
      },
      error: (error) => {
        console.error('Error saving injury', error);
      },
    });
  }

  showSavedInjuryMessage(): void {
    const formattedDriverName = this.driverNameValue
      .toLowerCase()
      .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    const data = {
      title: 'Injury Created',
      text: `An injury for ${formattedDriverName} has been successfully created. The details captured can be found on the driver\'s activity section.`,
      confirm: true,
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '500px' })
      .afterClosed()
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  createRequest(): any {
    const request: any = {};
    const mapping: any = {
      driverInfo: {
        driverId: 'driverId',
        driverCode: 'driverCode',
        cellphone: 'employeePhone',
        organization: 'organization',
      },
      timePlace: {
        dateReport: 'reportToWorkDate',
        dateInjury: 'injuryDate',
        addressLine1: 'injuryAddress1',
        addressLine2: 'injuryAddress2',
        city: 'injuryCity',
        state: 'injuryState',
        zip: 'injuryZip',
        otherLocationDetails: 'injuryLocationOtherDetails',
      },
      injuryDetail: {
        injuryReported: 'injuryReported',
        injuryReportedTo: 'injuryReportedTo',
        dateInjuryReported: 'injuryReportedDate',
        howInjuryOccur: 'injuryHowOccurred',
      },
      injuryDescription: {
        areaBodyInjured: 'injuryAreaOfBody',
        extentInjuries: 'extentOfInjuryDescription',
        medicalAssistanceRequired: 'medicalAssistanceRequired',
        medicalAssistanceReceived: 'medicalWhereReceived',
      },
      additionalInfo: {
        winterCleats: 'employeeWinterCleats',
        witness: 'witness',
        witnessInformation: 'witnessInformation',
      },
      leave: {
        leaveWork: 'employeeWorkLeave',
        dateLeaveWork: 'employeeWorkLeaveDate',
        employeeReturned: 'employeeWorkReturn',
        dateEmployeeReturned: 'employeeWorkReturnDate',
      },
    };

    for (const groupKey in this.injuryForm.controls) {
      const group = this.injuryForm.get(groupKey) as FormGroup;
      if (group && mapping[groupKey]) {
        for (const controlKey in group.controls) {
          const mappedKey = mapping[groupKey][controlKey];
          if (mappedKey) {
            let value = group.get(controlKey)?.value;
            if (value === 'true') {
              value = true;
            } else if (value === 'false') {
              value = false;
            }
            request[mappedKey] = value;
          }
        }
      }
    }
    request.injuryDate = this.timePlaceFormGroup.get('dateInjury')?.value
      ? this.convertToDatabaseFormat(
          this.timePlaceFormGroup.get('dateInjury')?.value,
          this.timePlaceFormGroup.get('timeInjury')?.value
        )
      : null;
    request.reportToWorkDate = this.timePlaceFormGroup.get('dateReport')?.value
      ? this.convertToDatabaseFormat(
          this.timePlaceFormGroup.get('dateReport')?.value,
          this.timePlaceFormGroup.get('timeReport')?.value
        )
      : null;
    request.injuryReportedDate = this.injuryDetailFormGroup.get('dateInjuryReported')
      ?.value
      ? this.convertToDatabaseFormat(
          this.injuryDetailFormGroup.get('dateInjuryReported')?.value,
          this.injuryDetailFormGroup.get('timeInjuryReported')?.value
        )
      : null;
    request.employeeWorkLeaveDate = request.employeeWorkLeaveDate
      ? this.convertToDatabaseFormat(request.employeeWorkLeaveDate)
      : null;
    request.employeeWorkReturnDate = request.employeeWorkReturnDate
      ? this.convertToDatabaseFormat(request.employeeWorkReturnDate)
      : null;
    request.injuryAreaOfBody = request.injuryAreaOfBody
      ? this.getInjuryAreaOfBodyNames(request.injuryAreaOfBody)
      : null;

    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      request.UserId = parsedData.userInfo.objectId;
    }

    request.organizationId = request?.organization?.organizationId;
    delete request.organization;

    request.driverId = request.driverCode;
    delete request.driverCode;

    return request;
  }

  getInjuryAreaOfBodyNames(injuryAreaOfBody: any): string {
    const injuryAreaOfBodyCopy = injuryAreaOfBody.map((item: any) => ({
      ...item,
    }));
    return injuryAreaOfBodyCopy
      .filter((item: any) => item.state)
      .map((item: any) => item.name)
      .join(', ');
  }

  convertToDatabaseFormat(date: string, accidentTime?: string) {
    const [month, day, year] = date.split('/');
    const padZero = (value: any) => (value.length === 1 ? `0${value}` : value);

    if (accidentTime) {
      const [hoursAndMinutes, period] = accidentTime.split(' ');
      let [hours, minutes] = hoursAndMinutes.split(':');

      if (period === 'PM' && hours !== '12') {
        hours = (parseInt(hours) + 12).toString();
      } else if (period === 'AM' && hours === '12') {
        hours = '00';
      } else {
        hours = padZero(hours.toString());
      }
      minutes = padZero(minutes);
      return `${year}-${padZero(month)}-${padZero(day)} ${hours}:${minutes}:00`;
    }
    return `${year}-${padZero(month)}-${padZero(day)} 00:00:00`;
  }

  onStepChange(event: StepperSelectionEvent): void {
    this.step = event.selectedIndex;
  }

  onSaveClick() {
    this.onDoneClick();
  }

  nextStep(): void {
    if (this.step < 9) {
      this.step++;
      this.stepper.next();
    }
  }

  prevStep(): void {
    if (this.step > 0) {
      this.step--;
      this.stepper.previous();
    }
  }

  reset(): void {
    this.step = 0;
    this.stepper.reset();
  }
}
