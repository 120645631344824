import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { AuthService } from '@app/services/auth.service';
import { Profile } from '@app/models/profile.model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  getProfile(): Observable<any> {
    return from(this.authService.acquireGraphToken()).pipe(
      switchMap((token: string | null) => {
        if (!token) {
          return throwError(() => new Error('Failed to acquire token'));
        }
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.get<any>(environment.graphEndpointBaseUrl, { headers });
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
