export interface Section {
  title: string;
  id: string;
  isActive?: boolean;
}

export enum DialogActions {
  UPDATE = 'update',
  UPDATE_CLOSE = 'update close',
  CANCEL = 'cancel',
}

export enum CoachingTabs {
  CoachingDetails = 'CoachingDetails',
  DriverHistory = 'DriverHistory',
  DriverInfo = 'DriverInfo',
}
