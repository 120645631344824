import { Component, Inject, OnInit, Renderer2, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
declare var UTIF: any;

@Component({
  selector: 'app-files-viewer-modal',
  templateUrl: './files-viewer-modal.component.html',
  styleUrls: ['./files-viewer-modal.component.scss'],
})
export class FilesViewerModalComponent implements OnInit {
  urls: string[];
  currentIndex: number;
  url: string;
  title: string = '';
  fileType: string = '';
  fileSize: number | null = null;

  pdfDocument: pdfjsLib.PDFDocumentProxy | null = null;
  currentPage: number = 1;
  totalPages: number = 0;
  zoomLevel: number = 1;
  zoomStep: number = 0.1;
  maxZoom: number = 3;
  minZoom: number = 0.5;

  constructor(
    public dialogRef: MatDialogRef<FilesViewerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    this.urls = data.urls;
    this.currentIndex = data.currentIndex;
    this.url = this.urls[this.currentIndex];
  }

  ngOnInit() {
    this.setTitleAndFileType(this.url);
  }

  setTitleAndFileType(url: string) {
    const parts = url.split('/');
    this.title = parts[parts.length - 1];

    const extension = this.title.split('.').pop()?.toLowerCase();
    if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
      this.fileType = 'image';
    } else if (extension === 'pdf') {
      this.fileType = 'document';
      this.loadPdf(url);
    } else if (extension === 'tif' || extension === 'tiff') {
      this.fileType = 'tiff';
      this.loadTiff(url);
    } else {
      this.fileType = 'file';
    }

    this.getFileSize(url);
  }

  getFileSize(url: string) {
    this.fileSize = 0;
    this.http.head(url, { observe: 'response' }).subscribe({
      next: (response) => {
        const contentLength = response.headers.get('Content-Length');
        if (contentLength) {
          this.fileSize = Math.round(+contentLength / 1024);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  async loadPdf(url: string) {
    this.pdfDocument = await pdfjsLib.getDocument(url).promise;
    this.totalPages = this.pdfDocument.numPages;
    this.renderPage(this.currentPage);
  }

  async renderPage(pageNumber: number) {
    if (!this.pdfDocument) return;

    this.clearPdfContainer();

    const container = document.getElementById('pdf-container');
    if (!container) return;

    const page = await this.pdfDocument.getPage(pageNumber);
    const viewport = page.getViewport({ scale: this.zoomLevel });
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    container.appendChild(canvas);
    await page.render({ canvasContext: context, viewport }).promise;

    container.style.width = viewport.width + 'px';
    container.style.height = viewport.height + 'px';
  }

  loadTiff(url: string) {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        const tiff = UTIF.decode(buffer);
        UTIF.decodeImage(buffer, tiff[0]);
        const rgba = UTIF.toRGBA8(tiff[0]);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = tiff[0].width;
        canvas.height = tiff[0].height;
        const imageData = ctx!.createImageData(canvas.width, canvas.height);
        imageData.data.set(rgba);
        ctx!.putImageData(imageData, 0, 0);

        const container = document.getElementById('tiff-container');
        if (container) {
          container.innerHTML = '';
          container.appendChild(canvas);
        }
      })
      .catch((error) => {
        console.error('Error loading TIFF:', error);
      });
  }

  nextUrl() {
    if (this.currentIndex < this.urls.length - 1) {
      this.currentIndex++;
      this.url = this.urls[this.currentIndex];
      this.setTitleAndFileType(this.url);
    }
  }

  previousUrl() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.url = this.urls[this.currentIndex];
      this.setTitleAndFileType(this.url);
    }
  }

  nextPage() {
    if (this.pdfDocument && this.currentPage < this.totalPages) {
      this.currentPage++;
      this.renderPage(this.currentPage);
    }
  }

  previousPage() {
    if (this.pdfDocument && this.currentPage > 1) {
      this.currentPage--;
      this.renderPage(this.currentPage);
    }
  }

  clearPdfContainer() {
    const container = document.getElementById('pdf-container');
    if (container) {
      container.innerHTML = '';
    }
  }

  isPreviousDisabled(): boolean {
    return this.currentIndex === 0;
  }

  isNextDisabled(): boolean {
    return this.currentIndex === this.urls.length - 1;
  }

  isPreviousPageDisabled(): boolean {
    return this.currentPage === 1;
  }

  isNextPageDisabled(): boolean {
    return this.currentPage === this.totalPages;
  }

  close() {
    this.dialogRef.close();
  }

  downloadFile() {
    fetch(this.url, { mode: 'cors' })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then((blob) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = this.title;
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 100);
      })
      .catch((error) => {
        console.error('There was an error with the fetch operation:', error);
      });
  }

  zoomIn(): void {
    this.zoomLevel += this.zoomStep;
    if (this.zoomLevel > 3) this.zoomLevel = this.maxZoom;
    this.renderPage(this.currentPage);
  }

  zoomOut(): void {
    this.zoomLevel -= this.zoomStep;
    if (this.zoomLevel < 0.5) this.zoomLevel = this.minZoom;
    this.renderPage(this.currentPage);
  }
}
