import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { DialogEventComponent } from './dialog-event.component';
import { TabSectionsDetailsComponent } from './tab-sections/tab-sections-details/tab-sections-details.component';
import { TabSectionsInfoComponent } from './tab-sections/tab-sections-info/tab-sections-info.component';
import { ActivityAccidentComponent } from './tab-sections/tab-sections-details/activity-accident/activity-accident.component';
import { ActivityHosComponent } from './tab-sections/tab-sections-details/activity-hos/activity-hos.component';
import { ActivityInspectionComponent } from './tab-sections/tab-sections-details/activity-inspection/activity-inspection.component';
import { ActivitySpeedingComponent } from './tab-sections/tab-sections-details/activity-speeding/activity-speeding.component';
import { ActivitySeatbeltComponent } from './tab-sections/tab-sections-details/activity-seatbelt/activity-seatbelt.component';
import { ActivityTelematicsComponent } from './tab-sections/tab-sections-details/activity-telematics/activity-telematics.component';

import { StatusSelectModule } from '@app/shared/components/status-select/status-select.module';
import { ImgFallbackModule } from '@app/shared/directives/img-fallback/img-fallback.module';
import { EditorModule } from '@app/shared/components/editor/editor.module';
import { NotesModule } from '@app/shared/components/notes/notes.module';
import { DateRangePickerModule } from '@app/shared/components/date-range-picker/date-range-picker.module';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';
import { StarRatingModule } from '@app/shared/components/star-rating/star-rating.module';

@NgModule({
  declarations: [
    DialogEventComponent,
    TabSectionsDetailsComponent,
    TabSectionsInfoComponent,
    ActivityAccidentComponent,
    ActivityHosComponent,
    ActivityInspectionComponent,
    ActivitySpeedingComponent,
    ActivitySeatbeltComponent,
    ActivityTelematicsComponent,
  ],
  exports: [DialogEventComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatTabsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    StatusSelectModule,
    ImgFallbackModule,
    EditorModule,
    NotesModule,
    DateRangePickerModule,
    UtcDatePipe,
    FormsModule,
    ReactiveFormsModule,
    StarRatingModule,
  ],
})
export class DialogEventModule {}
