<div class="header-block">
  <h2>Coaching</h2>
</div>
<!-- Coaching Table -->
<div class="container-table">
  <div class="container-only-table">
    <table
      mat-table
      matSort
      matSortActive="due_date"
      matSortDisableClear
      matSortDirection="desc"
      (matSortChange)="announceSortChange($event)"
      [dataSource]="coachingDataFiltered"
      class="intervention-table"
    >
      <ng-container matColumnDef="key">
        <th mat-header-cell mat-sort-header="key" *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let element">{{ element?.key }}</td>
      </ng-container>

      <ng-container matColumnDef="intervention_name">
        <th mat-header-cell mat-sort-header="intervention_name" *matHeaderCellDef>
          Summary
        </th>
        <td
          class="intervention-name-column"
          mat-cell
          *matCellDef="let element"
          matTooltip="{{ element?.reason }}"
        >
          {{ element?.reason }}
        </td>
      </ng-container>

      <ng-container matColumnDef="event_type">
        <th mat-header-cell mat-sort-header="event_type" *matHeaderCellDef>Event Type</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.eventType ? addSpaceAfterComma(element?.eventType) : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th
          class="status-column"
          mat-header-cell
          mat-sort-header="status"
          *matHeaderCellDef
        >
          Status
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container [ngSwitch]="element?.status">
            <ng-container *ngFor="let status of statuses">
              <span *ngSwitchCase="status.viewValue">
                <i [class]="status.icon"></i>
                <span class="status-text">{{ status.viewValue }}</span>
              </span>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell mat-sort-header="priority" *matHeaderCellDef>Priority</th>
        <td mat-cell *matCellDef="let element">
          <div class="safety-indicator {{ element?.priority?.toLowerCase() }}">
            <i class="mark mark-first"></i>
            <i class="mark"></i>
            <i class="mark"></i>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="assignee">
        <th mat-header-cell mat-sort-header="assignee" *matHeaderCellDef>Coach</th>
        <td mat-cell *matCellDef="let element">
          <div class="assignee">
            <img
              *ngIf="!element.assigneeName.trim() && !assigneeImages[element.assignee]"
              [src]="assigneeImages[element.assignee]"
              appImgFallback
              alt="Avatar"
              class="user-avatar"
            />
            <img
              *ngIf="element.assigneeName.trim() && assigneeImages[element.assignee]"
              [src]="assigneeImages[element.assignee]"
              appImgFallback
              alt="Avatar"
              class="user-avatar"
            />
            <div
              *ngIf="element.assigneeName.trim() && !assigneeImages[element.assignee]"
              class="initials initials-table"
              matTooltip="{{ element.assigneeName }}"
              [ngClass]="getBackgroundClass(element.assigneeName)"
            >
              {{ getInitials(element.assigneeName) }}
            </div>
            {{
              element?.assigneeName.trim() !== ""
                ? element?.assigneeName
                : assigneeType.UNASSIGNED
            }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="due_date">
        <th mat-header-cell mat-sort-header="due_date" *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.dueDate | utcDate }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="coachingColumns"></tr>
      <tr
        class="clickable"
        mat-row
        (click)="onRowClick(row)"
        *matRowDef="let row; columns: coachingColumns"
      ></tr>
    </table>
  </div>

  <div *ngIf="isLoadingCoaching" class="spinner-overlay">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"
      class="spinner"
    ></mat-progress-spinner>
  </div>

  <div class="paginator-container" *ngIf="!isViewMore">
    <div class="paginator-text">
      Showing {{ startPageIdx }} - {{ endPageIdx }} of {{ totalRecordCount }} items
    </div>
    <mat-paginator
      [pageSize]="pageEvent.pageSize"
      [length]="paginationParams.total"
      [hidePageSize]="true"
      [pageIndex]="pageEvent.pageIndex"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </div>

  <div
    *ngIf="!isLoadingCoaching && coachingDataFiltered.length === 0"
    class="no-records-found"
  >
    <p>No records found.</p>
  </div>

  <hr *ngIf="!isLoadingCoaching && showViewMoreCoaching" />

  <div
    class="view-more"
    *ngIf="!isLoadingCoaching && showViewMoreCoaching"
    (click)="loadMore()"
  >
    <span>{{ isViewMore ? "View More" : "View Less" }}</span>
    <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
  </div>
</div>
