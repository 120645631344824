import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';

import { FlyoutType } from '@app/models/fly-out.model';
import { PaginationParams } from '@app/models/pagination-params';
import { StatusType } from '@app/models/status.model';
import { EventService } from '@app/services/event.service';
import { FlyoutService } from '@app/services/flyout.service';
import { InterventionService } from '@app/services/intervention.service';
import { DialogEventComponent } from '@app/shared/components/dialog-event/dialog-event.component';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { addSpaceAfterComma } from '@app/shared/helpers/string.helper';
import { DialogCoachingDetailsComponent } from '@app/shared/components/dialog-coaching-details/dialog-coaching-details.component';
import { Subscription } from 'rxjs';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-section-history-events',
  templateUrl: './section-history-events.component.html',
  styleUrls: ['./section-history-events.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'collapsed',
        style({
          height: '0',
          overflow: 'hidden',
          opacity: 0,
        })
      ),
      state(
        'expanded',
        style({
          height: '*',
          overflow: 'visible',
          opacity: 1,
        })
      ),
      transition('collapsed <=> expanded', [animate('300ms ease-out')]),
    ]),
  ],
})
export class SectionHistoryEventsComponent implements OnInit {
  @Input() driverId!: string;
  @Input() driverGuid!: string;
  @Input() showSmaller!: boolean;

  eventsColumns: string[] = [
    'type',
    'criteria',
    'severity',
    'interventions',
    'processDate',
    'status',
  ];
  eventsDataFiltered: any[] = [];
  eventsList!: any[];
  isLoading: boolean = false;
  showViewMore: boolean = false;
  isViewMore: boolean = true;

  startPageIdx!: number;
  endPageIdx!: number;
  pageEvent!: PageEvent;
  paginationParams!: PaginationParams;
  totalRecordCount: number = 0;

  pageSize!: number;
  maxDisplayedRecords: number = 5;
  defaultPageSize: number = 10000;

  selectedOption: string = 'list';
  selectedRow: string | null = null;
  addSpaceAfterComma = addSpaceAfterComma;

  accidentData: any[] = [];
  telematicsData: any[] = [];
  hosData: any[] = [];
  inspectionData: any[] = [];
  speedingData: any[] = [];
  seatbeltData: any[] = [];

  isSectionExpanded: any = {
    accident: false,
    telematics: false,
    hos: false,
    inspection: false,
    speeding: false,
    seatbelt: false,
  };

  statuses = [
    {
      value: StatusType.OPEN.toLowerCase(),
      viewValue: StatusType.OPEN,
      icon: 'fa-solid fa-circle-notch',
    },
    {
      value: StatusType.ASSIGNED.toLowerCase(),
      viewValue: StatusType.ASSIGNED,
      icon: 'fa-solid fa-user-circle icon-second-blue',
    },
    {
      value: StatusType.CLOSED.toLowerCase(),
      viewValue: StatusType.CLOSED,
      icon: 'fa-solid fa-circle-xmark icon-red icon-default-size',
    },
  ];

  isExpandViewTriggered: boolean = false;
  selectedOrganization: any = {};

  subscription!: Subscription;

  constructor(
    private eventService: EventService,
    private dialog: MatDialog,
    private flyoutService: FlyoutService,
    private interventionService: InterventionService,
    private expandViewService: ExpandViewService
  ) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.setPaginatorConfig();
    this.getEventsList();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  toggleSection(section: string): void {
    this.isSectionExpanded[section] = !this.isSectionExpanded[section];
  }

  separateDataByType(): void {
    this.accidentData = this.eventsList.filter(
      (item) => item.type.toLowerCase() === 'accident'
    );
    this.telematicsData = this.eventsList.filter(
      (item) => item.type.toLowerCase() === 'telematics'
    );
    this.hosData = this.eventsList.filter((item) => item.type.toLowerCase() === 'hos');
    this.inspectionData = this.eventsList.filter(
      (item) => item.type.toLowerCase() === 'inspection'
    );
    this.speedingData = this.eventsList.filter(
      (item) => item.type.toLowerCase() === 'speeding'
    );
    this.seatbeltData = this.eventsList.filter(
      (item) => item.type.toLowerCase() === 'seatbelt'
    );

    this.isSectionExpanded.accident = this.accidentData.length > 0;
    this.isSectionExpanded.telematics = this.telematicsData.length > 0;
    this.isSectionExpanded.hos = this.hosData.length > 0;
    this.isSectionExpanded.inspection = this.inspectionData.length > 0;
    this.isSectionExpanded.speeding = this.speedingData.length > 0;
    this.isSectionExpanded.seatbelt = this.seatbeltData.length > 0;
  }

  getEventsList(): void {
    this.isLoading = true;
    const params = {
      pageNumber: 1,
      pageSize: 10000,
      driverGuid: this.driverGuid.toLowerCase(),
    };
    this.eventService.getEventsList(params).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.totalRecordCount = res[0].totalRecordCount;
        this.paginationParams.total = this.totalRecordCount;
        if (this.totalRecordCount) {
          this.parseAndAddInterventionKeys(res[0].eventSummaries);
          this.eventsList = res[0].eventSummaries;
          this.eventsDataFiltered = this.eventsList?.slice(0, this.pageSize);
          this.setDataFilteredAndEndPageIdx();
          this.setShowViewMore();
        } else {
          this.eventsList = [];
        }
        this.setShowViewMore();
        this.separateDataByType();
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  parseAndAddInterventionKeys(eventSummaries: any) {
    eventSummaries.forEach((event: any) => {
      try {
        if (event?.interventions) {
          const formattedInterventions =
            '[' + event?.interventions?.replace(/}\s*,\s*{/g, '},{') + ']';
          const interventions = JSON.parse(formattedInterventions);
          event.interventionObjects = interventions;
        }
      } catch (error) {
        console.error('Error parsing interventions:', error);
      }
    });
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
      if (this.isExpandViewTriggered) {
        this.expandViewService.incrementExpandView();
        this.isExpandViewTriggered = false;
      }
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.setPaginatorConfig();
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.eventsDataFiltered = this.eventsList?.slice(0, this.pageSize);
  }

  setShowViewMore(): void {
    if (this.eventsList.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    const startIdx = event.pageIndex * event.pageSize;
    const endIdx = startIdx + event.pageSize;
    this.startPageIdx = startIdx + 1;
    this.endPageIdx = endIdx < this.totalRecordCount ? endIdx : this.totalRecordCount;
    this.getEventsList();
  }

  setPaginatorConfig(): void {
    this.paginationParams = {
      limit: this.defaultPageSize,
      offset: 0,
      total: 0,
    };
    this.pageEvent = {
      length: this.paginationParams.total || 0,
      pageIndex: 0,
      pageSize: this.paginationParams.limit,
    };
    this.pageSize = this.maxDisplayedRecords;
    this.startPageIdx = 1;
  }

  setDataFilteredAndEndPageIdx(): void {
    if (!this.endPageIdx) {
      this.endPageIdx =
        this.defaultPageSize < this.totalRecordCount
          ? this.defaultPageSize
          : this.totalRecordCount;
    }
    this.eventsDataFiltered = this.eventsList?.slice(0, this.pageSize);
  }

  onRowClick(data: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.EVENT);
    this.selectedRow = data?.id;
    const dialogWidth = calculateDialogWidth(
      this.flyoutService.getFlyoutOffsetBasedOnIndex()
    );

    this.dialog
      .open(DialogEventComponent, {
        data: { ...data, showInfo: true, uniqueIdentifier: FlyoutType.EVENT },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: dialogWidth,
        panelClass: ['dialog-event', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedRow = null;
      });
  }

  onOptionChange(option: string): void {
    this.selectedOption = option;
  }

  announceSortChange(sortState: Sort): void {
    const data = this.eventsDataFiltered.slice();

    this.eventsDataFiltered = data.sort((a, b) => {
      const isAsc = sortState.direction === 'asc';
      switch (sortState.active) {
        case 'key':
          return this.compare(a.key, b.key, isAsc);
        case 'severity':
          return this.compare(a.severity, b.severity, isAsc);
        case 'type':
          return this.compareString(a.type, b.type, isAsc);
        case 'criteria':
          return this.compare(a.criteria, b.criteria, isAsc);
        case 'interventions':
          return this.compare(a.interventions, b.interventions, isAsc);
        case 'processDate':
          return this.compareString(a.processDate, b.processDate, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareString(str1: string, str2: string, isAsc: boolean): number {
    if (str1 === undefined || str1 === null) {
      return str2 === undefined || str2 === null ? 0 : isAsc ? -1 : 1;
    }
    if (str2 === undefined || str2 === null) {
      return isAsc ? 1 : -1;
    }
    return str1.localeCompare(str2) * (isAsc ? 1 : -1);
  }

  isRowSelected(id: string): boolean {
    return id === this.selectedRow;
  }

  onInterventionSelected(intervention: any, row: any): void {
    this.interventionService.getIntervention(intervention.id).subscribe({
      next: (res) => {
        this.openInterventionDetail(res[0], row);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  openInterventionDetail(intervention: any, row: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.COACHING);
    setTimeout(() => {
      const dialogWidth = calculateDialogWidth(
        this.flyoutService.getFlyoutOffsetBasedOnIndex()
      );

      this.dialog
        .open(DialogCoachingDetailsComponent, {
          data: {
            key: intervention.ticketId,
            driverName: row.driverName,
            driverId: intervention.driverID,
            driverGuid: this.driverGuid,
            ...intervention,
            uniqueIdentifier: FlyoutType.COACHING,
          },
          position: {
            right: '0',
            top: '70px',
          },
          width: dialogWidth,
          minWidth: dialogWidth,
          panelClass: ['dialog-event', 'animate-slide-in-left'],
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.selectedRow = null;
        });
    }, 100);
  }
}
