<quill-editor
  [(ngModel)]="contents"
  (onContentChanged)="contentChanged($event)"
  [readOnly]="disabled"
  [placeholder]="placeholder"
  [ngClass]="{ 'toolbar-hidden': hideToolbar || disabled }"
>
  <div quill-editor-toolbar [style.display]="hideToolbar || disabled ? 'none' : 'block'">
    <span class="ql-formats">
      <button class="ql-bold" quill-button>Bold</button>
      <button class="ql-italic" quill-button>Italic</button>
      <button class="ql-underline" quill-button>Underline</button>
    </span>
    <span class="ql-formats">
      <button class="ql-link" quill-button>Link</button>
      <!-- <button class="ql-image" quill-button>Image</button> -->
    </span>
    <!-- <span class="ql-formats">
      <button class="custom-button" (click)="handlePdfUpload()">
        <i class="fas fa-file-pdf"></i>
      </button>
      <input
        type="file"
        accept="application/pdf"
        #pdfUploadInput
        (change)="onFileInputChange($event)"
        hidden="true"
      />
    </span> -->
  </div>
</quill-editor>
