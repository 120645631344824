<div [formGroup]="driverInfoForm">
  <div class="stepper-container">
    <!--Organization Name -->
    <mat-form-field
      *ngIf="organizations.length > 1"
      appearance="outline"
      class="organization-dropdown"
    >
      <mat-label>Organization</mat-label>
      <mat-select
        formControlName="organization"
        (selectionChange)="onOrganizationChange($event)"
      >
        <mat-option *ngFor="let organization of organizations" [value]="organization">
          {{ organization.organizationName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!--Driver ID-->
    <app-driver-search
      *ngIf="showDriverSearch"
      label="Driver ID*"
      [organization]="selectedOrganization"
      [isEnabled]="isDriverSearchEnabled"
      [assignee]="assignee"
      (driverSelected)="handleDriverSelected($event)"
    ></app-driver-search>

    <div class="stepper-container driver">
      <h3>Driver Info</h3>
      <div class="row">
        <mat-label class="label">Driver Name</mat-label>
        <mat-form-field class="custom-form-field">
          <input matInput formControlName="driverName" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label class="label">Terminal</mat-label>
        <mat-form-field class="custom-form-field">
          <input matInput formControlName="terminal" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label class="label">Fleet Manager</mat-label>
        <mat-form-field class="custom-form-field">
          <input matInput formControlName="fleetManager" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label class="label">Safety Trainer</mat-label>
        <mat-form-field class="custom-form-field">
          <input matInput formControlName="safetyManager" />
        </mat-form-field>
      </div>
    </div>
    <h3 class="contact">Contact Info</h3>
    <mat-form-field appearance="outline">
      <mat-label>Cell #</mat-label>
      <input matInput formControlName="cellphone" />
    </mat-form-field>
    <mat-error
      *ngIf="
        driverInfoForm.get('cellphone')?.hasError('required') &&
        driverInfoForm.get('cellphone')?.touched
      "
    >
      This field is required
    </mat-error>
    <mat-error
      *ngIf="
        driverInfoForm.get('cellphone')?.hasError('invalidPhone') &&
        !driverInfoForm.get('cellphone')?.hasError('required') &&
        driverInfoForm.get('cellphone')?.touched
      "
    >
      Invalid phone number
    </mat-error>
  </div>
</div>
