<mat-form-field
  appearance="outline"
  class="custom-input"
  [ngClass]="{ disabled: !isEnabled }"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <span matPrefix>
    <div class="input-img">
      <i *ngIf="!selectedUserImage" class="fa-solid fa-magnifying-glass"></i>
      <img
        *ngIf="selectedUserDisplay.trim() && assignee?.driverId"
        [src]="getImageUrl(assignee.driverId)"
        appImgFallback
        alt="Avatar"
        class="user-avatar"
      />
    </div>
  </span>
  <input
    type="text"
    matInput
    [formControl]="searchDriverControl"
    [matAutocomplete]="auto"
    (input)="clearImg()"
  />
  <span *ngIf="selectedUserImage" class="input-reset" matSuffix (click)="resetUser()">
    <i class="fa-solid fa-circle-xmark"></i>
  </span>
  <span *ngIf="!selectedUserImage" matSuffix class="input-reset chevron-icon">
    <i class="fa-solid fa-chevron-down"></i>
  </span>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelectUser($event.option.value)"
  >
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
      <div class="driver-option-content">
        <div class="driver-avatar">
          <img
            [src]="getImageUrl(user.driverId)"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
        </div>
        <span>{{ user.driverName | capitalize }} - {{ user.driverId }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="searchDriverControl.touched && searchDriverControl.invalid">
    The field is required
  </mat-error>
</mat-form-field>
