import { Configuration, LogLevel } from '@azure/msal-browser';
import { environment } from '@env/environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: `https://${environment.tenantSubDomain}.ciamlogin.com/`,
    redirectUri: environment.redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage', 
    storeAuthStateInCookie: window.navigator.userAgent.indexOf('MSIE ') > -1 ||
      window.navigator.userAgent.indexOf('Trident/') > -1,
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
};

export const tokenScopes = {
  dataApiScope: [environment.dataTokenScope],
  adminApiScope: [environment.adminTokenScope],
  complianceApiScope: [environment.complianceTokenScope],
  graphApiScope: ['https://graph.microsoft.com/.default']
};
