import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { PaginationParams } from '@app/models/pagination-params';
import { InterventionService } from '@app/services/intervention.service';
import {
  calculateDialogWidth,
  getBackgroundClass,
  getInitials,
} from '@app/shared/helpers/functions.helper';
import { addSpaceAfterComma } from '@app/shared/helpers/string.helper';
import { DialogCoachingDetailsComponent } from '../../dialog-coaching-details.component';
import { FlyoutType } from '@app/models/fly-out.model';
import { FlyoutService } from '@app/services/flyout.service';
import { ExpandViewService } from '@app/services/expand-view.service';
import { MediaService } from '@app/services/media.service';
import { AssigneeType } from '@app/models/status.model';

@Component({
  selector: 'app-section-history-coaching',
  templateUrl: './section-history-coaching.component.html',
  styleUrls: ['./section-history-coaching.component.scss'],
})
export class SectionHistoryCoachingComponent implements OnInit {
  @Input() driverId!: string;
  @Input() driverGuid!: string;
  @Input() showSmaller!: boolean;

  coachingColumns: string[] = [
    'key',
    'intervention_name',
    'event_type',
    'priority',
    'assignee',
    'due_date',
    'status',
  ];
  coachingDataFiltered: any[] = [];
  coachingList!: any[];
  isLoadingCoaching: boolean = false;
  showViewMoreCoaching: boolean = false;
  isViewMore: boolean = true;
  pageSize!: number;
  maxDisplayedRecords: number = 5;
  defaultPageSize: number = 10;

  startPageIdx!: number;
  endPageIdx!: number;
  pageEvent!: PageEvent;
  paginationParams!: PaginationParams;
  totalRecordCount: number = 0;

  statuses = [
    {
      value: 'open',
      viewValue: 'Open',
      icon: 'fa-solid fa-circle-notch icon-default-size',
    },
    {
      value: 'scheduled',
      viewValue: 'Scheduled',
      icon: 'fa-solid fa-calendar-days icon-orange icon-default-size',
    },
    {
      value: 'in-progress',
      viewValue: 'In Progress',
      icon: 'fa-solid fa-clock icon-second-blue icon-default-size',
    },
    {
      value: 'completed',
      viewValue: 'Completed',
      icon: 'fa-solid fa-circle-check icon-green icon-default-size',
    },
  ];
  selectedRow: string | null = null;
  isExpandViewTriggered: boolean = false;
  assigneeImages: { [assigneeId: string]: string | null } = {};

  subscription!: Subscription;

  addSpaceAfterComma = addSpaceAfterComma;
  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;
  assigneeType = AssigneeType;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private interventionService: InterventionService,
    private dialog: MatDialog,
    private flyoutService: FlyoutService,
    private expandViewService: ExpandViewService,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {
    this.pageSize = this.maxDisplayedRecords;
    this.setPaginatorConfig();
    this.getCoachingList();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  getCoachingList(fetchAllRecords: boolean = false): void {
    this.isLoadingCoaching = true;

    const params: any = {
      pageNumber: this.pageEvent.pageIndex + 1,
      pageSize: fetchAllRecords ? this.totalRecordCount : this.pageEvent.pageSize,
      driverGuid: this.driverGuid,
    };

    if (this.sort) {
      params.sortField = this.sort.active;
      params.sortValue = this.sort.direction.toUpperCase();
    }
    this.interventionService.getInterventionsList(params).subscribe({
      next: (res) => {
        this.isLoadingCoaching = false;
        this.totalRecordCount = res[0]?.totalRecordCount;
        this.paginationParams.total = this.totalRecordCount;
        if (fetchAllRecords) {
          this.pageEvent.pageSize = this.totalRecordCount;
          this.pageSize = this.totalRecordCount;
          this.endPageIdx = this.totalRecordCount;
        }
        if (this.totalRecordCount) {
          this.coachingList = res[0].AllInterventions;
          this.loadAssigneeImages(this.coachingList);
          this.coachingDataFiltered = this.coachingList?.slice(0, this.pageSize);
          this.setDataFilteredAndEndPageIdx();
          this.setShowViewMoreCoaching();
        } else {
          this.coachingList = [];
        }
        if (this.isExpandViewTriggered) {
          this.expandViewService.incrementExpandView();
          this.isExpandViewTriggered = false;
        }
      },
      error: (error) => {
        this.isLoadingCoaching = false;
        console.log(error);
      },
    });
  }

  private loadAssigneeImages(interventions: any[]): void {
    const uniqueAssignees = new Set(interventions.map((item) => item.assignee));
    uniqueAssignees.forEach((assignedCoach) => {
      if (
        !this.assigneeImages[assignedCoach] &&
        this.assigneeImages[assignedCoach] !== null
      ) {
        this.mediaService
          .downloadFile('userProfileImage', assignedCoach, 'profile_image')
          .subscribe({
            next: (blob: Blob) => {
              const imageUrl = URL.createObjectURL(blob);
              this.assigneeImages[assignedCoach] = imageUrl;
            },
            error: (error) => {
              if (error.status !== 404) {
                console.error(error);
              }
              this.assigneeImages[assignedCoach] = null;
            },
          });
      }
    });
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
      this.getCoachingList(true);
      return;
    }

    this.isViewMore = !this.isViewMore;
    this.pageEvent.pageIndex = 0;

    if (this.isViewMore) {
      this.pageSize = this.maxDisplayedRecords;
    } else {
      this.pageSize = this.defaultPageSize;
    }

    this.setPaginatorConfig();
    this.getCoachingList(false);
  }

  setShowViewMoreCoaching(): void {
    if (this.totalRecordCount > this.maxDisplayedRecords) {
      this.showViewMoreCoaching = true;
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    const startIdx = event.pageIndex * event.pageSize;
    const endIdx = startIdx + event.pageSize;
    this.startPageIdx = startIdx + 1;
    this.endPageIdx = endIdx < this.totalRecordCount ? endIdx : this.totalRecordCount;
    this.getCoachingList();
  }

  setPaginatorConfig(): void {
    this.paginationParams = {
      limit: this.pageSize,
      offset: 0,
      total: this.totalRecordCount,
    };

    this.pageEvent = {
      length: this.paginationParams.total || 0,
      pageIndex: 0,
      pageSize: this.pageSize,
    };

    this.startPageIdx = 1;
  }

  setDataFilteredAndEndPageIdx(): void {
    if (!this.endPageIdx) {
      this.endPageIdx =
        this.defaultPageSize < this.totalRecordCount
          ? this.defaultPageSize
          : this.totalRecordCount;
    }
    this.coachingDataFiltered = this.coachingList?.slice(0, this.pageSize);
  }

  announceSortChange(sortState: Sort): void {
    this.getCoachingList();
  }

  onRowClick(row: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.COACHING);
    this.selectedRow = row?.driverID;

    setTimeout(() => {
      const dialogWidth = calculateDialogWidth(
        this.flyoutService.getFlyoutOffsetBasedOnIndex()
      );

      this.dialog
        .open(DialogCoachingDetailsComponent, {
          data: { ...row, uniqueIdentifier: FlyoutType.COACHING },
          position: {
            right: '0',
            top: '70px',
          },
          width: dialogWidth,
          minWidth: dialogWidth,
          panelClass: ['dialog-event', 'animate-slide-in-left'],
          autoFocus: false,
        })
        .afterClosed()
        .subscribe(() => {
          this.selectedRow = null;
        });
    }, 100);
  }
}
