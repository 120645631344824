<div class="container-table">
  <table
    mat-table
    matSort
    matSortActive="processDate"
    matSortDisableClear
    matSortDirection="desc"
    (matSortChange)="announceSortChange($event)"
    [dataSource]="eventData"
    class="table-events-header-sticky events-table"
  >
    <ng-container matColumnDef="criteria">
      <th mat-header-cell mat-sort-header="criteria" *matHeaderCellDef>Criteria</th>
      <td
        class="criteria-column"
        mat-cell
        *matCellDef="let element"
        matTooltip="{{ addSpaceAfterComma(element?.criteria) }}"
      >
        {{ addSpaceAfterComma(element?.criteria) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="severity">
      <th mat-header-cell mat-sort-header="severity" *matHeaderCellDef>Severity</th>
      <td mat-cell *matCellDef="let element">
        <i
          *ngIf="element.severity === 'Critical'"
          [matTooltip]="element.severity"
          class="fa-solid fa-angle-double-up icon-red icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Significant'"
          [matTooltip]="element.severity"
          class="fa-solid fa-chevron-up icon-red icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Moderate'"
          [matTooltip]="element.severity"
          class="fa-solid fa-equals icon-orange icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Minor'"
          [matTooltip]="element.severity"
          class="fa-solid fa-chevron-down icon-gray icon-default-size"
        ></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="interventions">
      <th mat-header-cell mat-sort-header="interventions" *matHeaderCellDef>
        Interventions
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element?.interventionIds }}
      </td>
    </ng-container>

    <ng-container matColumnDef="processDate">
      <th
        mat-header-cell
        mat-sort-header="processDate"
        *matHeaderCellDef
        class="date-column"
      >
        Date
      </th>
      <td mat-cell *matCellDef="let element" class="date-column">
        {{ element?.processDate | utcDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        mat-header-cell
        mat-sort-header="status"
        *matHeaderCellDef
        class="status-column"
      >
        Status
      </th>
      <td mat-cell *matCellDef="let element" class="status-column">
        <ng-container [ngSwitch]="element?.status">
          <ng-container *ngFor="let status of statuses">
            <span *ngSwitchCase="status.viewValue">
              <i [class]="status.icon"></i>
              <span class="status-text">{{ status.viewValue }}</span>
            </span>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsGroups"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumnsGroups"
      class="clickable"
      (click)="onRowClick(row)"
      [class.row-selected]="isRowSelected(row.id)"
    ></tr>
  </table>
</div>
