export const environment = {
  production: false,
  dataApiUrl: 'https://test.api.brocadetech.com/safety',
  adminApiUrl: 'https://test.api.brocadetech.com/safety-admin',
  complianceApiUrl: 'https://test.api.brocadetech.com/safety-compliance',
  dataTokenScope: 'api://9431e349-8eb3-4b7f-97c1-fe542c4e9dc5/access_as_user',
  complianceTokenScope: 'api://b0e189da-e242-4926-8155-3d7091043096/access_as_user',
  adminTokenScope: 'api://79297b73-181a-4a6d-8f18-9b92e986da8c/access_as_user',
  graphTokenScope: 'https://graph.microsoft.com/.default',
  graphEndpointBaseUrl: 'https://graph.microsoft.com/v1.0/me',
  driverImageToken:
    'sp=r&st=2024-11-21T21:25:55Z&se=2027-03-13T05:25:55Z&spr=https&sv=2022-11-02&sr=c&sig=cuw2Xtu%2FoIL%2BdBRsqMlPUQP8zGVwSB1cwU2pBFZ49yQ%3D',
  driverImageBaseUrl: 'https://stsafetytesteastus.blob.core.windows.net',
  googleApiKey: 'AIzaSyC2Fbs_Cg7adG4gScUzT2jLnXFl8y1OtKM',
  googleMapStartUrl: 'https://www.google.com/maps/embed/v1/search?q=',
  redirectUri: 'https://test.cowan.safety.brocadetech.com/home',
  clientId: 'cc00e12b-d0c8-46b8-9393-e7f99267772e',
  tenantId: 'a3768ebc-6d15-4dd3-b02e-85ed81e15f35',
  tenantSubDomain: 'safetybrocadetest',
  emailPattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  organizationId: '7d8087e6-4448-49b2-90f6-289571e8bd05',
};
