import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ITelematicsEvent } from '@app/models/telematics.model';
import { addSpacesToCamelCase } from '@app/shared/helpers/string.helper';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { Subscription } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { PaginationParams } from '@app/models/pagination-params';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-telematics-list',
  templateUrl: './telematics-list.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class TelematicsListComponent implements OnInit, OnDestroy {
  @Input() dateRange: any;
  @Input() driverGUID!: string;
  @Input() maxDisplayedRecords!: number;
  @Input() defaultPageSize!: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  telematicsList!: ITelematicsEvent[];
  telematicsDataFiltered: ITelematicsEvent[] = [];
  telematicsColumns: string[] = ['date', 'time', 'type', 'video'];
  isLoading: boolean = false;
  selectedRow: { sectionName: string; rowId?: string } | null = null;
  dateRangeSubscription!: Subscription;

  isViewMore: boolean = true;
  pageSize!: number;
  paginationParams!: PaginationParams;
  pageEvent!: PageEvent;
  startPageIdx!: number;
  endPageIdx!: number;
  showViewMore: boolean = false;
  totalRecordCount: number = 10000;
  isExpandViewTriggered: boolean = false;

  subscription!: Subscription;

  constructor(
    private dialogInfoService: DialogDriverService,
    private expandViewService: ExpandViewService
  ) {}

  ngOnInit(): void {
    this.setPaginatorConfig();
    this.getTelematicsSummary();
    this.setDateRangeSubscription();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  setPaginatorConfig(): void {
    this.paginationParams = {
      limit: this.defaultPageSize,
      offset: 0,
      total: 0,
    };
    this.pageEvent = {
      length: this.paginationParams.total || 0,
      pageIndex: 0,
      pageSize: this.paginationParams.limit,
    };
    this.pageSize = this.maxDisplayedRecords;
    this.startPageIdx = 1;
  }

  setDateRangeSubscription(): void {
    this.dateRangeSubscription = this.dialogInfoService.dateChange$.subscribe(() =>
      this.getTelematicsSummary()
    );
  }

  ngOnDestroy(): void {
    if (this.dateRangeSubscription) {
      this.dateRangeSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'telematics' });
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  getTelematicsSummary(fetchAllRecords: boolean = false): void {
    this.isLoading = true;
    const { start, end } = this.dateRange.value;
    if (start && end) {
      const request = {
        startDate: start,
        endDate: end,
        driverGUID: this.driverGUID,
        page: this.pageEvent.pageIndex + 1,
        pageSize: fetchAllRecords ? this.totalRecordCount : this.pageEvent.pageSize,
      };
      this.dialogInfoService.getTelematicsSummary(request).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.telematicsList = response[0].telematicsEvents;
          this.totalRecordCount = response[0].totalRecordCount;
          this.paginationParams.total = this.totalRecordCount;
          if (fetchAllRecords) {
            this.pageEvent.pageSize = this.totalRecordCount;
            this.pageSize = this.totalRecordCount;
            this.endPageIdx = this.totalRecordCount;
          }
          if (this.totalRecordCount) {
            this.setDataFilteredAndEndPageIdx();
            this.setShowViewMore();
          }
          if (this.isExpandViewTriggered) {
            this.expandViewService.incrementExpandView();
            this.isExpandViewTriggered = false;
          }
        },
        error: (error: any) => {
          this.isLoading = false;
          console.error('Error:', error);
        },
      });
    }
  }

  setDataFilteredAndEndPageIdx(): void {
    if (!this.endPageIdx) {
      this.endPageIdx =
        this.defaultPageSize < this.totalRecordCount
          ? this.defaultPageSize
          : this.totalRecordCount;
    }
    this.telematicsDataFiltered = this.telematicsList?.slice(0, this.pageSize);
  }

  setShowViewMore(): void {
    if (this.telematicsList.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  addSpacesToCamelCase(str: string): string {
    return addSpacesToCamelCase(str);
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    const startIdx = event.pageIndex * event.pageSize;
    const endIdx = startIdx + event.pageSize;
    this.startPageIdx = startIdx + 1;
    this.endPageIdx = endIdx < this.totalRecordCount ? endIdx : this.totalRecordCount;
    this.telematicsDataFiltered = this.telematicsList.slice(startIdx, endIdx);
    this.getTelematicsSummary();
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.setPaginatorConfig();
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.getTelematicsSummary(expandViewMore);
  }
}
