import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { DialogDriverService } from '@app/services/dialog-driver.service';
import {
  addSpaceAfterComma,
  addSpacesToCamelCase,
} from '@app/shared/helpers/string.helper';
import { IInjury } from '@app/models/injury.model';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-injury-list',
  templateUrl: './injury-list.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class InjuryListComponent implements OnInit {
  @Input() dateRange: any;
  @Input() driverGUID!: string;
  @Input() maxDisplayedRecords!: number;
  @Input() defaultPageSize!: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  injuryList!: IInjury[];
  injuryDataFiltered: IInjury[] = [];
  injuryColumns: string[] = [
    'injuryDate',
    'injuryAreaOfBody',
    'medicalAssistanceRequired',
    'employeeWorkReturn',
  ];
  isLoading: boolean = false;
  selectedRow: { sectionName: string; rowId?: string } | null = null;
  dateRangeSubscription!: Subscription;
  refreshGridSubscription!: Subscription;

  isViewMore: boolean = true;
  pageSize!: number;
  showViewMore: boolean = false;
  isExpandViewTriggered: boolean = false;

  subscription!: Subscription;

  addSpaceAfterComma = addSpaceAfterComma;

  constructor(
    private dialogInfoService: DialogDriverService,
    private expandViewService: ExpandViewService
  ) {}

  ngOnInit(): void {
    this.pageSize = this.maxDisplayedRecords;
    this.getInjurySummary();
    this.setDateRangeSubscription();
    this.setRefreshGridSubscription();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  setRefreshGridSubscription(): void {
    this.refreshGridSubscription = this.dialogInfoService
      .getInjuryGridRefreshNeeded()
      .subscribe(() => {
        this.getInjurySummary();
      });
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'injuries' });
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  setDateRangeSubscription(): void {
    this.dateRangeSubscription = this.dialogInfoService.dateChange$.subscribe(() =>
      this.getInjurySummary()
    );
  }

  ngOnDestroy(): void {
    if (this.dateRangeSubscription) {
      this.dateRangeSubscription.unsubscribe();
    }
    if (this.refreshGridSubscription) {
      this.refreshGridSubscription.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getInjurySummary(): void {
    this.isLoading = true;
    const { start, end } = this.dateRange.value;
    if (start && end) {
      const request = {
        startDate: start,
        endDate: end,
        driverGUID: this.driverGUID,
      };
      this.dialogInfoService.getInjurySummary(request).subscribe({
        next: (response: IInjury[]) => {
          this.isLoading = false;
          this.injuryList = response;
          if (this.injuryList.length) {
            this.setDataFilteredAndEndPageIdx();
            this.setShowViewMore();
          }
          if (this.isExpandViewTriggered) {
            this.expandViewService.incrementExpandView();
            this.isExpandViewTriggered = false;
          }
        },
        error: (error: any) => {
          this.isLoading = false;
          console.error('Error:', error);
        },
      });
    }
  }

  setDataFilteredAndEndPageIdx(): void {
    this.injuryDataFiltered = this.injuryList?.slice(0, this.pageSize);
  }

  setShowViewMore(): void {
    if (this.injuryList.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  addSpacesToCamelCase(str: string): string {
    return addSpacesToCamelCase(str);
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.getInjurySummary();
  }
}
