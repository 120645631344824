<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>Inspections</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="content-container">
    <div class="content-block double-block">
      <div>
        <div class="content-block details">
          <div class="first-text_block">
            <div class="title-left_block">Time and Location</div>
            <div class="text-left_block">Report State</div>
            <div class="text-left_block">Report #</div>
            <div class="text-left_block">Inspection Date</div>
          </div>
          <div class="second-text_block" *ngIf="!isLoading">
            <div class="text-right_block">
              {{ inspectionDetail.reportState }}
            </div>
            <div class="text-right_block">
              {{ inspectionDetail.reportNumber }}
            </div>
            <div class="text-right_block">
              {{ inspectionDetail.inspectionDate | utcDate }}
            </div>
          </div>
          <div *ngIf="isLoading" class="second-text_block spinner-container">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="30"
              class="spinner"
            ></mat-progress-spinner>
          </div>
        </div>
      </div>
      <div>
        <div class="content-block details">
          <div class="first-text_block">
            <div class="title-left_block">Inspection Info</div>
            <div class="text-left_block">Inspection Level</div>
          </div>
          <div class="second-text_block" *ngIf="!isLoading">
            <div class="text-right_block">{{ inspectionDetail.level }}</div>
          </div>
          <div *ngIf="isLoading" class="second-text_block spinner-container">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="30"
              class="spinner"
            ></mat-progress-spinner>
          </div>
        </div>
      </div>
    </div>

    <div class="content-block double-block">
      <div>
        <div class="content-block driver">
          <div class="first-text_block">
            <div class="title-left_block">Driver Info</div>
            <div class="text-left_block">Name</div>
            <div class="text-left_block">DOB</div>
            <div class="text-left_block">Age</div>
          </div>
          <div class="second-text_block" *ngIf="!isLoading">
            <div class="text-right_block">
              {{ inspectionDetail.Driver[0].driverName | titlecase }}
            </div>
            <div class="text-right_block">
              {{ inspectionDetail.Driver[0].dateOfBirth | date : "M/d/yyy" }}
            </div>
            <div class="text-right_block">
              {{ inspectionDetail.Driver[0].age }}
            </div>
          </div>
          <div *ngIf="isLoading" class="second-text_block spinner-container">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="30"
              class="spinner"
            ></mat-progress-spinner>
          </div>
        </div>
      </div>
      <div>
        <div class="content-block details">
          <div class="first-text_block">
            <div class="no-title-left_block"></div>
            <div class="text-left_block">Driver ID</div>
            <div class="text-left_block">License State</div>
            <div class="text-left_block">License #</div>
          </div>
          <div class="second-text_block" *ngIf="!isLoading">
            <div class="text-right_block">
              {{ inspectionDetail.Driver[0].driverId }}
            </div>
            <div class="text-right_block">
              {{ inspectionDetail.Driver[0].licenseState }}
            </div>
            <div class="text-right_block">
              {{ inspectionDetail.Driver[0].licenseNumber }}
            </div>
          </div>
          <div *ngIf="isLoading" class="second-text_block spinner-container">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="30"
              class="spinner"
            ></mat-progress-spinner>
          </div>
        </div>
      </div>
    </div>

    <div class="title-vehicle_block">Vehicle Info</div>

    <div class="container-table">
      <table mat-table [dataSource]="vehicles">
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef>Unit</th>
          <td mat-cell *matCellDef="let element">{{ element?.unit }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element?.unit_type }}</td>
        </ng-container>

        <ng-container matColumnDef="make">
          <th mat-header-cell *matHeaderCellDef>Make</th>
          <td mat-cell *matCellDef="let element">{{ element?.make }}</td>
        </ng-container>

        <ng-container matColumnDef="plate">
          <th mat-header-cell *matHeaderCellDef>Plate #</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.license_number }}
          </td>
        </ng-container>

        <ng-container matColumnDef="vin">
          <th mat-header-cell *matHeaderCellDef>Vin #</th>
          <td mat-cell *matCellDef="let element">{{ element?.vin }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="vehiclesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: vehiclesColumns"></tr>
      </table>

      <div *ngIf="isLoading" class="spinner-container-table">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>
    </div>

    <div class="title-violation_block" *ngIf="violations.length">Violation Info</div>

    <div class="container-table" *ngIf="violations.length">
      <table mat-table [dataSource]="violations">
        <ng-container matColumnDef="unitNumber">
          <th mat-header-cell *matHeaderCellDef>Unit Number</th>
          <td mat-cell *matCellDef="let element">{{ element?.unit }}</td>
        </ng-container>

        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>Code</th>
          <td mat-cell *matCellDef="let element">{{ element?.code }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element">{{ element?.basic }}</td>
        </ng-container>

        <ng-container matColumnDef="violDescription">
          <th mat-header-cell *matHeaderCellDef>Viol Description</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="origOos">
          <th mat-header-cell *matHeaderCellDef>Out of Service</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.outOfService ? "Yes" : "No" }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="violationColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: violationColumns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
