import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private baseUrl = environment.adminApiUrl;
  private userUrl = 'user';

  private selectionChangeSource = new Subject<string>();
  private userChangeSource = new Subject<void>();

  private rolesSubject = new BehaviorSubject<any[]>([]);

  selectionChanged$ = this.selectionChangeSource.asObservable();
  userChange$ = this.userChangeSource.asObservable();

  roles$ = this.rolesSubject.asObservable();

  constructor(private http: HttpClient) {}

  updateRoles(roles: any[]): void {
    this.rolesSubject.next(roles);
  }

  getRoles(): Observable<any[]> {
    return this.roles$;
  }

  announceSelectionChange(status: string) {
    this.selectionChangeSource.next(status);
  }

  announceUserChange() {
    this.userChangeSource.next();
  }

  getUsers(params: any): Observable<any> {
    const paramMappings: any = {
      clientId: 'clientId',
      organizationId: 'organizationId',
      searchField: 'searchField',
      type: 'type',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      groupId: 'groupId',
      tagId: 'tagId',
      // sortField: 'sortField',
      // sortValue: 'sortValue',
    };
    const url = `${this.baseUrl}/${this.userUrl}?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getUser(userId: string): Observable<any> {
    const url = `${this.baseUrl}/profile/user/${userId}`;
    return this.http.get<any>(url);
  }

  assignUserToGroup(request: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/group/user`, request);
  }

  deleteUserFromGroup(request: { groupId: string; objectId: string }[]): Observable<any> {
    const url = `${this.baseUrl}/group/user`;
    const options = {
      body: request,
    };
    return this.http.delete(url, options);
  }

  deleteUser(objectId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/user/${objectId}`);
  }

  upsertUser(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/${this.userUrl}`, request);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => request[key] || request[key] === 0)
      .map((key) => `${paramMappings[key]}=${request[key]}`)
      .join('&');
  }

  getGroups(organizationId: string): Observable<any> {
    const url = `${this.baseUrl}/org/groups/${organizationId}`;
    return this.http.get<any>(url);
  }

  getGroupPermissions(groupId: string): Observable<any> {
    const url = `${this.baseUrl}/group/permissions/${groupId}`;
    return this.http.get<any>(url);
  }

  getAllPermissions(): Observable<any> {
    const url = `${this.baseUrl}/resources`;
    return this.http.get<any>(url);
  }

  upsertGroup(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/org/group`, request);
  }

  deleteGroup(groupId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/org/group/${groupId}`);
  }
}
