<mat-toolbar class="custom-toolbar">
  <div class="branding">
    <div routerLink="/home" class="logo"></div>
    <a routerLink="/home">Driver Safety</a>
    <!-- Organization -->
    <mat-form-field
      appearance="outline"
      class="organization-dropdown"
      *ngIf="organizations.length > 1"
    >
      <span matPrefix>
        <i class="fa-solid fa-warehouse"></i>
      </span>
      <mat-select
        [(value)]="selectedOrganization"
        (selectionChange)="onOrganizationChange($event)"
      >
        <mat-option *ngFor="let organization of organizations" [value]="organization">
          {{ organization.organizationName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="actions">
    <app-sub-header (menuItemClicked)="onMenuClick()"></app-sub-header>
    <div class="mobile">
      <button mat-fab color="primary" (click)="onMenuClick()" class="inverse mat-fab">
        <i class="fas fa-{{ isMenuOpened ? 'xmark' : 'bars' }}"></i>
      </button>
    </div>
  </div>
</mat-toolbar>
