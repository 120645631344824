import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CoachingStatusType } from '@app/models/status.model';

import { InterventionService } from '@app/services/intervention.service';
import { ToasterService } from '@app/services/toaster.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-status-coaching-select',
  templateUrl: './status-coaching-select.component.html',
  styleUrls: ['./status-coaching-select.component.scss'],
})
export class StatusCoachingSelectComponent {
  @Input() intervention!: any;
  @Input() status!: string;
  @Input() border: boolean = false;
  @Input() disabled: boolean = false;
  @Output() selectionChanged = new EventEmitter<string>();

  statuses = [
    {
      value: CoachingStatusType.OPEN.toLowerCase(),
      viewValue: CoachingStatusType.OPEN,
      icon: 'fa-solid fa-circle-notch icon-default-size',
    },
    {
      value: CoachingStatusType.SCHEDULED.toLowerCase(),
      viewValue: CoachingStatusType.SCHEDULED,
      icon: 'fa-solid fa-calendar-days icon-orange icon-default-size',
    },
    {
      value: CoachingStatusType.IN_PROGRESS.toLowerCase(),
      viewValue: CoachingStatusType.IN_PROGRESS,
      icon: 'fa-solid fa-clock icon-second-blue icon-default-size',
    },
    {
      value: CoachingStatusType.COMPLETED.toLowerCase(),
      viewValue: CoachingStatusType.COMPLETED,
      icon: 'fa-solid fa-circle-check icon-green icon-default-size',
    },
  ];

  constructor(
    private interventionService: InterventionService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    if (this.status) {
      this.status =
        this.statuses.find((s) => s.value === this.status)?.value || this.status;
    }
  }

  onSelectionChange(element: any): void {
    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }

    let completed = false;
    if (element.value.toLowerCase() === CoachingStatusType.COMPLETED.toLowerCase()) {
      completed = true;
    }

    const request = {
      id: this.intervention.id,
      status: element.value,
      lastModifiedBy: userId,
      completed,
      organizationId: environment.organizationId,
    };
    this.selectionChanged.emit(element.value);
    this.interventionService.upsertIntervention(request).subscribe({
      next: () => {
        this.interventionService.announceSelectionChange(element.value);
        this.toasterService.showSuccess('The Status was updated successfully');
      },
      error: (error) => console.error(error),
    });
  }
}
