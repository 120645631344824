<div #modalContainer>
  <!-- Desktop Flyout -->
  <ng-template #desktopHeaderActions>
    <div class="dialog-close">
      <div *ngIf="isLoadingPrintWeb" class="spinner-container">
        <mat-progress-spinner mode="indeterminate" [diameter]="20" class="spinner">
        </mat-progress-spinner>
      </div>
      <!-- Print Buttons -->
      <i
        *ngIf="
          selectedTabName === driverTabs.DriverFiles ||
          selectedTabName === driverTabs.DriverActivity ||
          selectedTabName === driverTabs.DriverInterventions ||
          selectedTabName === driverTabs.DriverEvents
        "
        class="fa-solid fa-print print-icon"
        (click)="onPrintClick()"
      ></i>
      <i
        *ngIf="
          selectedTabName !== driverTabs.DriverFiles &&
          selectedTabName !== driverTabs.DriverActivity &&
          selectedTabName !== driverTabs.DriverInterventions &&
          selectedTabName !== driverTabs.DriverEvents
        "
        class="fa-solid fa-print print-icon"
        (click)="printCurrentTabContent()"
      ></i>
      <!-- Close Buttons -->
      <i
        *ngIf="isDialogMode; else pageCloseDesktop"
        mat-dialog-close
        class="fas fa-times"
      ></i>

      <ng-template #pageCloseDesktop>
        <i class="fas fa-times" (click)="onClosePage()"></i>
      </ng-template>
    </div>

    <div class="dialog-title">
      <div class="driver-avatar">
        <img
          [src]="getImageUrl(data?.driverId)"
          appImgFallback
          alt="Avatar"
          class="user-avatar"
        />
      </div>
      <div class="driver-info">
        <h2>{{ data.driverName ? toTitleCase(data?.driverName) : "" }}</h2>
        <div class="driver-details">
          <button class="driver-id-button" mat-button (click)="goToInfoTab()">
            <i class="fas fa-id-card"></i>
            <span>{{ data?.driverId }}</span>
          </button>

          <button
            class="terminal-button"
            mat-button
            (click)="goToTerminal(data?.terminal)"
          >
            <i class="fas fa-map-marker-alt"></i>
            <span>{{ data?.terminal }}</span>
          </button>
        </div>
      </div>

      <div class="driver-actions actions-desktop">
        <button
          mat-flat-button
          color="primary"
          *ngIf="canCreateInterventions()"
          (click)="onNewInterventionClick()"
        >
          <i class="fas fa-plus-circle"></i>
          <span>New Intervention</span>
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="canCreateFile()"
          (click)="onNewFileClick()"
        >
          <i class="fas fa-plus-circle"></i>
          <span>New File</span>
        </button>
        <button
          mat-flat-button
          color="primary"
          *ngIf="canCreateHOS()"
          (click)="onNewHOSClick()"
        >
          <i class="fas fa-plus-circle"></i>
          <span>New HOS Violation</span>
        </button>
      </div>
    </div>
  </ng-template>

  <!-- Mobile Page -->
  <ng-template #mobileHeaderTemplate>
    <div class="dialog-close">
      <div *ngIf="isLoadingPrintWeb" class="spinner-container">
        <mat-progress-spinner mode="indeterminate" [diameter]="20" class="spinner">
        </mat-progress-spinner>
      </div>
      <i
        *ngIf="
          selectedTabName === driverTabs.DriverFiles ||
          selectedTabName === driverTabs.DriverActivity ||
          selectedTabName === driverTabs.DriverInterventions ||
          selectedTabName === driverTabs.DriverEvents
        "
        class="fa-solid fa-print print-icon"
        (click)="onPrintClick()"
      ></i>
      <i
        *ngIf="
          selectedTabName !== driverTabs.DriverFiles &&
          selectedTabName !== driverTabs.DriverActivity &&
          selectedTabName !== driverTabs.DriverInterventions &&
          selectedTabName !== driverTabs.DriverEvents
        "
        class="fa-solid fa-print print-icon"
        (click)="printCurrentTabContent()"
      ></i>
      <!-- Close Buttons -->
      <i
        *ngIf="isDialogMode; else pageCloseMobile"
        mat-dialog-close
        class="fas fa-times"
      ></i>

      <ng-template #pageCloseMobile>
        <i class="fas fa-times" (click)="onClosePage()"></i>
      </ng-template>
    </div>

    <div class="dialog-title">
      <div class="driver-avatar avatar">
        <img
          [src]="getImageUrl(data?.driverId)"
          appImgFallback
          alt="Avatar"
          class="user-avatar"
        />
      </div>

      <div class="driver-info info">
        <h2>{{ data.driverName ? toTitleCase(data?.driverName) : "" }}</h2>

        <div class="driver-details">
          <button
            class="driver-id-button short-button"
            mat-button
            (click)="goToInfoTab()"
          >
            <i class="fas fa-id-card grey-icon"></i>
            <span>{{ data?.driverId }}</span>
          </button>
        </div>
        <div class="driver-details">
          <button
            class="terminal-button short-button"
            mat-button
            (click)="goToTerminal(data?.terminal)"
          >
            <i class="fas fa-map-marker-alt grey-icon"></i>
            <span>{{ data?.terminal }}</span>
          </button>
        </div>

        <!-- Mobile Actions -->
        <div class="actions-mobile actions" [matMenuTriggerFor]="mobileMenu">
          <button mat-flat-button color="primary" class="new-button-menu">
            <i class="fas fa-plus-circle"></i>
            <span class="mobile-button-text">New</span>
          </button>
        </div>
        <mat-menu #mobileMenu="matMenu">
          <button
            mat-menu-item
            *ngIf="canCreateInterventions()"
            (click)="onNewInterventionClick()"
          >
            <i class="fas fa-plus-circle"></i>
            <span class="mobile-button-text">New Intervention</span>
          </button>
          <button mat-menu-item *ngIf="canCreateFile()" (click)="onNewFileClick()">
            <i class="fas fa-plus-circle"></i>
            <span class="mobile-button-text">New File</span>
          </button>
          <button mat-menu-item *ngIf="canCreateHOS()" (click)="onNewHOSClick()">
            <i class="fas fa-plus-circle"></i>
            <span class="mobile-button-text">New HOS Violation</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </ng-template>

  <!-- Tabs -->
  <ng-template #tabsTemplate>
    <mat-tab-group
      #tabGroup
      class="tab-group-drivers"
      *ngIf="data"
      (selectedTabChange)="onTabChange($event)"
      animationDuration="0ms"
      [(selectedIndex)]="selectedTabIndex"
    >
      <!-- Info -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverProfile)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverProfile)"></i>
          <span>Info</span>
        </ng-template>
        <app-tab-sections-info
          [rowData]="data"
          [showMenu]="showMenu"
          *ngIf="isActiveTab(driverTabs.DriverProfile)"
        >
        </app-tab-sections-info>
      </mat-tab>

      <!-- Files -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverFiles)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverFiles)"></i>
          <span>Files</span>
        </ng-template>
        <app-tab-sections-files
          *ngIf="isActiveTab(driverTabs.DriverFiles)"
          [driverGuid]="data?.id"
        >
        </app-tab-sections-files>
      </mat-tab>

      <!-- Activity -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverActivity)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverActivity)"></i>
          <span>Activity</span>
        </ng-template>
        <app-tab-sections-activity
          *ngIf="isActiveTab(driverTabs.DriverActivity)"
          [rowData]="rowData"
        >
        </app-tab-sections-activity>
      </mat-tab>

      <!-- Intervention -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverInterventions)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverInterventions)"></i>
          <span>Intervention</span>
        </ng-template>
        <app-tab-sections-intervention
          *ngIf="isActiveTab(driverTabs.DriverInterventions)"
          [rowData]="rowData"
          [driverId]="data?.driverId"
          [driverProfile]="data"
        >
        </app-tab-sections-intervention>
      </mat-tab>

      <!-- Events -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverEvents)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverEvents)"></i>
          <span>Events</span>
        </ng-template>
        <app-tab-sections-events
          *ngIf="isActiveTab(driverTabs.DriverEvents)"
          [driverId]="data?.driverId"
          [driverGuid]="data?.id"
          [showSmaller]="showSmaller"
        >
        </app-tab-sections-events>
      </mat-tab>

      <!-- Notes -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverNotes)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverNotes)"></i>
          <span>Notes</span>
        </ng-template>
        <app-tab-sections-notes
          *ngIf="isActiveTab(driverTabs.DriverNotes)"
          [driverId]="data?.id"
        >
        </app-tab-sections-notes>
      </mat-tab>
    </mat-tab-group>
  </ng-template>

  <!-- Dialog Mode -->
  <ng-container *ngIf="isDialogMode; else pageMode">
    <mat-dialog-actions class="layout-desktop">
      <ng-container *ngTemplateOutlet="desktopHeaderActions"></ng-container>
    </mat-dialog-actions>

    <mat-dialog-content>
      <div class="layout-mobile">
        <ng-container *ngTemplateOutlet="mobileHeaderTemplate"></ng-container>
      </div>

      <!-- Tabs -->
      <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>
    </mat-dialog-content>
  </ng-container>

  <!-- Page Mode -->
  <ng-template #pageMode>
    <div class="layout-desktop">
      <ng-container *ngTemplateOutlet="desktopHeaderActions"></ng-container>
    </div>

    <div class="layout-mobile">
      <ng-container *ngTemplateOutlet="mobileHeaderTemplate"></ng-container>
    </div>
    <!-- Tabs -->
    <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>
  </ng-template>
</div>
