import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import {
  OrganizationPermission,
  UserPermission,
  UserProfileAndPermissions,
} from '@app/models/userProfileAndPermissions.model';
import { Injectable } from '@angular/core';
import { MediaService } from '@app/services/media.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private globalPermissions: UserPermission[] = [];
  private organizationPermissions: OrganizationPermission[] = [];
  private baseUrl = environment.adminApiUrl;
  private userProfileUrl = 'profile/user';

  // Subject to notify when user data is loaded
  userDataLoaded = new Subject<void>();

  constructor(private http: HttpClient, private mediaService: MediaService) {
    this.globalPermissions = [];
    this.organizationPermissions = [];
  }

  loadUserData(objectId: string): Observable<UserProfileAndPermissions> {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.globalPermissions = parsedData.permissions.globalPermissions || [];
      this.organizationPermissions = parsedData.permissions.organizationPermissions || [];
      return of(parsedData);
    }

    return this.http
      .get<UserProfileAndPermissions>(
        `${this.baseUrl}/${this.userProfileUrl}/${objectId}`
      )
      .pipe(
        tap((data: any) => {
          this.globalPermissions = data.permissions.globalPermissions || [];
          this.organizationPermissions = data.permissions.organizationPermissions || [];
          localStorage.setItem('userData', JSON.stringify(data));

          // Emit event after loading user data
          this.userDataLoaded.next();

          this.mediaService
            .downloadFile('userProfileImage', data.userInfo.objectId)
            .subscribe({
              next: (blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                  const base64data = reader.result;
                  data.userInfo.previewUrl = base64data;
                  localStorage.setItem('userData', JSON.stringify(data));
                };
              },
              error: (error) => {
                if (error.status !== 404) {
                  console.log(error);
                }
              },
            });
        })
      );
  }

  hasPermission(permissionType: string, resourceName: string): boolean {
    const hasGlobalPerm = this.hasGlobalPermission(permissionType, resourceName);
    if (hasGlobalPerm) {
      return true;
    }
    const hasOrgPerm = this.hasOrganizationPermission(permissionType, resourceName);
    return hasOrgPerm;
  }

  hasGlobalPermission(permissionType: string, resourceName: string): boolean {
    return this.globalPermissions.some(
      (p) => p.permissionType === permissionType && p.resourceName === resourceName
    );
  }

  hasOrganizationPermission(permissionType: string, resourceName: string): boolean {
    const storedOrganization = localStorage.getItem('selectedOrganization');

    if (storedOrganization) {
      const selectedOrganization = JSON.parse(storedOrganization);
      const organization = this.organizationPermissions.find(
        (org) => org.organizationId === selectedOrganization.organizationId
      );

      if (organization) {
        return organization.permissions.some(
          (p) => p.permissionType === permissionType && p.resourceName === resourceName
        );
      }

      return false;
    }

    return this.organizationPermissions.some((orgPermission) =>
      orgPermission.permissions.some(
        (p) => p.permissionType === permissionType && p.resourceName === resourceName
      )
    );
  }
}
