import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {
  private baseUrl = environment.complianceApiUrl;

  constructor(private http: HttpClient) {}

  getOrganizationRecordTypeSettings(params: any): Observable<any> {
    const paramMappings: any = {
      // sortColumn: 'sortColumn',
      // sortDirection: 'sortDirection',
      organizationId: 'organizationId',
    };
    const url = `${this.baseUrl}/GetOrganizationRecordTypeSettings?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getOrganizationRecordTypeDetail(params: any): Observable<any> {
    const paramMappings: any = {
      recordTypeId: 'recordTypeId',
      organizationId: 'organizationId',
    };
    const url = `${this.baseUrl}/getOrganizationRecordType?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => request[key] || request[key] === 0)
      .map((key) => `${paramMappings[key]}=${request[key]}`)
      .join('&');
  }

  upsertRecordType(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/upsertOrganizationRecordType`, request);
  }

  upsertCategory(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/UpsertOrganizationCategory`, request);
  }

  deleteCategory(categoryId: string): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}/DeleteOrganizationCategory?organizationId=${environment.organizationId}&categoryId=${categoryId}`
    );
  }

  uploadDriverFile(params: any, formData: any): Observable<any> {
    const paramMappings: any = {
      driverId: 'driverId',
      recordTypeId: 'recordTypeId',
      organizationId: 'organizationId',
      expiration: 'expiration',
      uploadedBy: 'uploadedBy',
    };
    return this.http.post(
      `${this.baseUrl}/UploadDriverFile?${this.getQueryParams(paramMappings, params)}`,
      formData
    );
  }
}
