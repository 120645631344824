<div class="toggle-container">
  <mat-slide-toggle
    [(ngModel)]="onlyCompliance"
    (change)="toggleOnlyCompliance($event)"
  ></mat-slide-toggle>
  <span>Only show Compliance Schema records</span>
</div>
<article class="article-files" #scrollContainer>
  <section *ngIf="isLoading && !sections.length">
    <div class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
  </section>
  <section *ngFor="let section of sections">
    <div class="header-block">
      <h2 [attr.id]="section.id">{{ section.title }}</h2>
    </div>

    <div class="container-table">
      <table mat-table [dataSource]="dataSources[section.id]" matSort>
        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef class="view-column">View</th>
          <td mat-cell *matCellDef="let record" class="view-column">
            <span
              *ngIf="record.status !== complianceStatus.MISSING"
              class="pdf-icon"
              (click)="onIconClick(record)"
            >
              <i class="fas fa-file-pdf clickable"></i>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="recordType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Record Type</th>
          <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
            {{ record.recordType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="uploaded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded</th>
          <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
            {{ record.uploaded ? extractDate(record.uploaded) : "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="expires">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Expires</th>
          <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
            {{ getExpiresValue(record) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="complianceSchema">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Schema</th>
          <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
            {{ record.complianceSchema }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
            <span [ngClass]="getStatusClass(record.status)">
              <i [ngClass]="getStatusIcon(record.status)"></i>
              {{ record.status }}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [ngClass]="{
            clickable:
              !row.archive || (row.archive && row.status !== complianceStatus.MISSING),
            disabled: row.archive && row.status === complianceStatus.MISSING
          }"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>

        <!--No data row-->
        <tr *matNoDataRow>
          <td
            *ngIf="!isLoading"
            [attr.colspan]="displayedColumns.length"
            class="no-records-found"
          >
            No records found
          </td>
        </tr>
      </table>
    </div>

    <div *ngIf="isLoading" class="spinner-overlay">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <div
      class="view-more"
      *ngIf="!isLoading && dataSources[section.id].data.length > maxDisplayedRecords"
      (click)="toggleViewMore(section)"
    >
      <span>{{ section.isViewMore ? "View More" : "View Less" }}</span>
      <mat-icon matIconSuffix>{{
        section.isViewMore ? "expand_more" : "expand_less"
      }}</mat-icon>
    </div>
  </section>
</article>

<ul *ngIf="sections.length > 1">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li (click)="onNavClick(item.id)" [class.active]="item.id === sectionSelected">
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
