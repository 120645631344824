<div #modalContainer>
  <!-- Dialog Mode -->
  <ng-container *ngIf="isDialogMode; else pageMode">
    <mat-dialog-actions class="layout-desktop desktop-header">
      <ng-container *ngTemplateOutlet="desktopHeaderActions"></ng-container>
    </mat-dialog-actions>
    <ng-container *ngTemplateOutlet="desktopHeaderInfo"></ng-container>
    <ng-container *ngTemplateOutlet="headerColumns"></ng-container>
    <mat-dialog-content>
      <div class="layout-mobile">
        <ng-container *ngTemplateOutlet="mobileHeaderTemplate"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>
    </mat-dialog-content>
  </ng-container>
  <!-- Page Mode -->
  <ng-template #pageMode>
    <div class="layout-desktop">
      <ng-container *ngTemplateOutlet="desktopHeaderActions"></ng-container>
    </div>
    <div class="layout-mobile">
      <ng-container *ngTemplateOutlet="mobileHeaderTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="mobileHeaderInfo"></ng-container>
    <ng-container *ngTemplateOutlet="headerColumns"></ng-container>
    <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>
  </ng-template>

  <ng-template #editModeTemplate>
    <app-edit-intervention
      [data]="data"
      (saveClicked)="saveClicked($event)"
      (interventionUpdated)="updateIntervention($event)"
    ></app-edit-intervention>
  </ng-template>

  <!-- Desktop Header Actions -->
  <ng-template #desktopHeaderActions>
    <div class="title-container">
      <h2 class="title">Intervention #{{ data.key }}</h2>
    </div>
    <div class="dialog-close">
      <i
        *ngIf="selectedTabIndex === 1"
        class="fa-solid fa-print print-icon"
        (click)="onPrintClick()"
      ></i>
      <i
        *ngIf="selectedTabIndex !== 1"
        class="fa-solid fa-print print-icon"
        (click)="printCurrentTabContent()"
      ></i>
      <i mat-dialog-close class="fas fa-times"></i>
    </div>
    <div class="date-container">
      <span class="date" *ngIf="data?.createdDate">
        Created {{ data?.createdDate | date : "MMM d, y" }} at
        {{ data?.createdDate | date : "h:mm a" }}
      </span>
      <span class="date" *ngIf="data?.completedDate">
        Completed {{ data?.completedDate | date : "MMM d, y" }} at
        {{ data?.completedDate | date : "h:mm a" }}
      </span>
    </div>
  </ng-template>

  <!-- Template: Desktop Header Info -->
  <ng-template #desktopHeaderInfo>
    <ng-container *ngIf="!editMode; else editModeTemplate">
      <div class="info">
        <div class="subtitle-wrapper">
          <div class="subtitle">
            {{ data.reason }}
          </div>
          <span
            class="edit-action"
            *ngIf="canEditInterventions()"
            (click)="editIntervention()"
          >
            <i class="fa-solid fa-pen-to-square"></i>
            <span class="edit-text">Edit</span>
          </span>
        </div>
        <div class="driver-info">
          <span class="firstname-lastname">
            {{ data?.driverName ? toTitleCase(data?.driverName) : "" }}
          </span>
          <button mat-button (click)="goToDriverProfile()">
            <i class="fas fa-id-card"></i>
            <span>{{ data?.driverID }}</span>
          </button>
          <button mat-button (click)="goToTerminal(data?.terminal)">
            <i class="fas fa-map-marker-alt"></i>
            <span>{{ data?.terminal }}</span>
          </button>
        </div>
        <div class="card-type">
          <span *ngFor="let eventType of convertStringToArray(data.eventType)">
            {{ eventType }}
          </span>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <!-- Template: Header Columns -->
  <ng-template #headerColumns>
    <div class="custom-columns">
      <!-- Assigned Coach -->
      <div class="column assigned-couch">
        <span class="hide-text">{{ data?.assigneeName }}</span>
        <app-edit-assigned-coach
          *ngIf="!isLoading"
          [assignee]="data?.assignee"
          [assigneeName]="data?.assigneeName"
          [intervention]="data"
          (userIdSelected)="updateAssignedCoach($event)"
        ></app-edit-assigned-coach>
      </div>
      <!-- Due Date -->
      <div class="column clickable" (click)="openDatePicker()">
        <div class="due-date-container">
          <div class="due-date-left">
            <span class="due-date-text">{{ dueDate | date : "shortDate" }}</span>
            <label class="column-label">Due Date</label>
          </div>
          <div class="due-date-right">
            <i class="fa-solid fa-calendar-day calendar"></i>
          </div>
        </div>

        <input
          [ngxMatDatetimePicker]="picker"
          [(ngModel)]="dueDate"
          (ngModelChange)="updateDueDate($event)"
          [style.visibility]="'hidden'"
          [style.position]="'absolute'"
        />
        <ngx-mat-datetime-picker
          #picker
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="1"
          [hideTime]="true"
          [stepMinute]="1"
          [touchUi]="false"
          [enableMeridian]="true"
        >
          <ngx-mat-datepicker-actions>
            <button mat-raised-button ngxMatDatepickerCancel>
              <i class="fas fa-times"></i>
              <span class="button-title">Cancel</span>
            </button>
            <button mat-flat-button color="primary" ngxMatDatepickerApply>
              <i class="fas fa-check"></i>
              <span class="button-title">Apply</span>
            </button>
          </ngx-mat-datepicker-actions>
        </ngx-mat-datetime-picker>
      </div>
      <!-- Priority -->
      <div class="column clickable priority-select-container">
        <mat-select
          *ngIf="priority"
          #prioritySelect
          [(ngModel)]="priority"
          class="priority-select"
          (selectionChange)="onPrioritySelectionChange()"
        >
          <mat-select-trigger>
            <span class="column-span column-span-priority">
              <div class="select-trigger-content">
                <div
                  [ngClass]="
                    'safety-indicator ' + (priority ? priority.toLowerCase() : '')
                  "
                >
                  <i class="mark"></i>
                  <i class="mark"></i>
                  <i class="mark"></i>
                </div>
              </div>
              {{ priority }}
            </span>
            <label class="column-label">Priority</label>
          </mat-select-trigger>
          <mat-option *ngFor="let priority of priorities" [value]="priority.value">
            <div class="select-trigger-content">
              <div
                [ngClass]="'safety-indicator ' + (priority?.label?.toLowerCase() || '')"
              >
                <i class="mark mark-first"></i>
                <i class="mark"></i>
                <i class="mark"></i>
              </div>
              {{ priority.label }}
            </div>
          </mat-option>
        </mat-select>
      </div>
      <!-- Status -->
      <div
        *ngIf="data.status"
        class="column special-border"
        [ngClass]="{
          'black-border': data.status ? data.status.toLowerCase() === 'open' : '',
          'orange-border': data.status ? data.status.toLowerCase() === 'scheduled' : '',
          'blue-border': data.status ? data.status.toLowerCase() === 'in progress' : '',
          'green-border': data.status ? data.status.toLowerCase() === 'completed' : ''
        }"
      >
        <app-status-coaching-select
          [intervention]="data"
          [status]="data.status"
          (selectionChanged)="handleStatusSelectionChange($event)"
          [disabled]="!canEditInterventions()"
        ></app-status-coaching-select>
      </div>
    </div>
  </ng-template>

  <!-- Template: Mobile Header -->
  <ng-template #mobileHeaderTemplate>
    <div class="mobile-header-container">
      <div class="title-container">
        <h2 class="title">Intervention #{{ data.key }}</h2>
      </div>
      <div class="dialog-close">
        <i
          *ngIf="selectedTabIndex === 1"
          class="fa-solid fa-print print-icon"
          (click)="onPrintClick()"
        ></i>
        <i
          *ngIf="selectedTabIndex !== 1"
          class="fa-solid fa-print print-icon mobile-icon"
          (click)="printCurrentTabContent()"
        ></i>
        <i mat-dialog-close class="fas fa-times mobile-icon-big" (click)="close()"></i>
      </div>
      <div class="date-container">
        <span class="date" *ngIf="data?.createdDate">
          Created {{ data?.createdDate | date : "MMM d, y" }} at
          {{ data?.createdDate | date : "h:mm a" }}
        </span>
        <span class="date" *ngIf="data?.completedDate">
          Completed {{ data?.completedDate | date : "MMM d, y" }} at
          {{ data?.completedDate | date : "h:mm a" }}
        </span>
      </div>
    </div>
  </ng-template>

  <!-- Template: Mobile Header Info -->
  <ng-template #mobileHeaderInfo>
    <ng-container *ngIf="!editMode; else editModeTemplate">
      <div class="info">
        <div class="subtitle-wrapper">
          <div class="subtitle">
            {{ data.reason }}
          </div>
          <span
            class="edit-action"
            *ngIf="canEditInterventions()"
            (click)="editIntervention()"
          >
            <i class="fa-solid fa-pen-to-square mobile-icon"></i>
          </span>
        </div>
        <div class="driver-info mobile-info">
          <span class="firstname-lastname">
            {{ data?.driverName ? toTitleCase(data?.driverName) : "" }}
          </span>
          <div class="mobile-driver-buttons">
            <button mat-button (click)="goToDriverProfile()">
              <i class="fas fa-id-card"></i>
              <span>{{ data?.driverID }}</span>
            </button>
            <button mat-button (click)="goToTerminal(data?.terminal)">
              <i class="fas fa-map-marker-alt"></i>
              <span>{{ data?.terminal }}</span>
            </button>
          </div>
        </div>
        <div class="card-type">
          <span *ngFor="let eventType of convertStringToArray(data.eventType)">
            {{ eventType }}
          </span>
        </div>
      </div></ng-container
    >
  </ng-template>

  <!-- Template: Tabs -->
  <ng-template #tabsTemplate>
    <mat-tab-group
      #tabGroup
      class="tab-group-interventions"
      (selectedTabChange)="onTabChange($event)"
      animationDuration="0ms"
      [(selectedIndex)]="selectedTabIndex"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fas fa-triangle-exclamation"></i>
          <span>Details</span>
        </ng-template>
        <app-tab-section-details
          [showMenu]="showMenu"
          [interventionId]="data?.id"
          [driverId]="data?.driverID"
          [dataRow]="data"
        ></app-tab-section-details>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fas fa-clock-rotate-left"></i>
          <span>Driver History</span>
        </ng-template>
        <app-tab-section-history
          [showMenu]="showMenu"
          [driverId]="data?.driverID"
          [driverGuid]="data?.driverGuid"
        ></app-tab-section-history>
      </mat-tab>
      <mat-tab *ngIf="canViewDriverProfile()">
        <ng-template mat-tab-label>
          <i class="fas fa-th-list"></i>
          <span>Driver Info</span>
        </ng-template>
        <app-tab-section-info
          [showMenu]="showMenu"
          [rowData]="data"
        ></app-tab-section-info>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
