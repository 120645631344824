<table mat-table matSort [dataSource]="dataFiltered" class="activity-table">
  <ng-container matColumnDef="video">
    <th mat-header-cell *matHeaderCellDef>Video</th>
    <td mat-cell *matCellDef="let element">
      <i class="fa-solid fa-circle-play video-icon" *ngIf="element.video"></i>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.standardEventType }}
    </td>
  </ng-container>

  <ng-container matColumnDef="classification">
    <th mat-header-cell *matHeaderCellDef>Classification</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.classification }}
    </td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "h:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columns"
    class="clickable"
    (click)="onRowClick(row)"
  ></tr>
</table>

<div *ngIf="dataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr />

<div class="view-more" *ngIf="showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
