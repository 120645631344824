<table mat-table matSort [dataSource]="dataFiltered" class="activity-table">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="startTime">
    <th mat-header-cell *matHeaderCellDef>Start Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.start | date : "hh:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="endTime">
    <th mat-header-cell *matHeaderCellDef>End Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.end | date : "hh:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef>Duration</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.duration !== "" ? convertToHourMinuteFormat(element?.duration) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef>Distance</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.distance | number : "1.2-2" }} mi
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>

<div *ngIf="dataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr />

<div class="view-more" *ngIf="showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
