import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';

import { ImgFallbackModule } from '@app/shared/directives/img-fallback/img-fallback.module';
import { DriverSearchComponent } from './driver-search.component';
import { CapitalizePipe } from '@app/shared/pipes/capitalize.pipe';

@NgModule({
  declarations: [DriverSearchComponent],
  exports: [DriverSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ImgFallbackModule,
    MatChipsModule,
    CapitalizePipe,
  ],
})
export class DriverSearchModule {}
