import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverService } from '@app/services/driver.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-step-driver-info',
  templateUrl: './step-driver-info.component.html',
  styleUrls: ['./step-driver-info.component.scss'],
})
export class StepDriverInfoComponent implements OnInit {
  @Input() driverInfoForm!: FormGroup;

  driverData!: any;

  selectedOrganization: any;
  isDriverSearchEnabled: boolean = false;
  showDriverSearch: boolean = false;

  assignee: any;

  constructor(private driverService: DriverService) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.driverInfoForm.get('organization')?.setValue(this.selectedOrganization);
    this.showDriverSearch = true;
    this.isDriverSearchEnabled = true;
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  onOrganizationChange(newOrganization: any): void {
    this.selectedOrganization = newOrganization.value;
    this.driverInfoForm.get('organization')?.setValue(this.selectedOrganization);
    this.isDriverSearchEnabled = true;

    // Reset driver fields when the organization changes
    this.driverInfoForm.get('driverCode')?.reset();
    this.handleDriverSelected(null);
    this.assignee = null;
  }

  private parseManagers(managersString: string): { [key: string]: string } {
    const managerMap: { [key: string]: string } = {};
    const managerEntries = managersString.split(',');

    managerEntries.forEach((entry) => {
      const [role, name] = entry.split(':').map((part) => part.trim());
      if (role && name) {
        managerMap[role] = name;
      }
    });

    return managerMap;
  }

  handleDriverSelected(driver: any): void {
    if (driver) {
      this.driverData = driver;
      this.driverInfoForm.get('cellphone')?.setValue(this.driverData.phone);
      this.driverInfoForm.get('driverName')?.setValue(this.driverData?.driverName);
      this.driverInfoForm.get('driverId')?.setValue(this.driverData?.id);
      this.driverInfoForm.get('driverCode')?.setValue(this.driverData?.driverId);
      this.driverInfoForm.get('terminal')?.setValue(this.driverData?.terminal);

      let managerMap: { [key: string]: string } = {};

      if (typeof this.driverData.managers === 'string') {
        // When managers is a string, parse it using parseManagers
        const managersString = this.driverData.managers;
        managerMap = this.parseManagers(managersString);
      } else if (Array.isArray(this.driverData.Managers)) {
        // When Managers is an array, process each manager object
        managerMap = this.driverData.Managers.reduce(
          (map: { [key: string]: string }, manager: any) => {
            if (manager.title && manager.managerName) {
              map[manager.title] = manager.managerName;
            }
            return map;
          },
          {}
        );
      }

      this.driverInfoForm
        .get('fleetManager')
        ?.setValue(managerMap['Fleet Manager'] || '');
      this.driverInfoForm
        .get('safetyManager')
        ?.setValue(managerMap['Safety Manager'] || '');
    } else {
      this.driverInfoForm.get('cellphone')?.reset();
      this.driverInfoForm.get('driverName')?.reset();
      this.driverInfoForm.get('driverId')?.reset();
      this.driverInfoForm.get('driverCode')?.reset();
      this.driverInfoForm.get('fleetManager')?.reset();
      this.driverInfoForm.get('safetyManager')?.reset();
      this.driverInfoForm.get('terminal')?.reset();
      this.driverData = undefined;
    }
  }

  private fetchDriverByCode(driverCode: string): void {
    if (!this.selectedOrganization) {
      return;
    }

    this.driverService.getDriverProfile(driverCode).subscribe({
      next: (driverData: any) => {
        if (driverData && driverData.length > 0) {
          const driver = driverData[0]; // Take the first element of the array
          this.assignee = driver;
          this.handleDriverSelected(driver);
        } else {
          // Handle driver not found
          this.assignee = null;
          this.handleDriverSelected(null);
        }
        this.showDriverSearch = true;
      },
      error: (error) => {
        console.error(error);
        // Handle error
        this.assignee = null;
        this.handleDriverSelected(null);
      },
    });
  }
}
