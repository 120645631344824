<div class="header-block">
  <h2>Injuries</h2>

  <!-- <button mat-flat-button color="primary" class="new-item-button">
    <i class="fas fa-plus-circle"></i>
    <span class="button-title">Add New</span>
  </button> -->
</div>

<table
  *ngIf="!isLoading"
  mat-table
  matSort
  [dataSource]="injuryDataFiltered"
  class="table-header-sticky-driver-details"
>
  <ng-container matColumnDef="injuryDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.injuryDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="injuryAreaOfBody">
    <th mat-header-cell *matHeaderCellDef>Injury Type</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.injuryAreaOfBody ? addSpaceAfterComma(element.injuryAreaOfBody) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="medicalAssistanceRequired">
    <th mat-header-cell *matHeaderCellDef>Medical Treatment</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.medicalAssistanceRequired ? "Yes" : "No" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="employeeWorkReturn">
    <th mat-header-cell *matHeaderCellDef>Return to Work</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.employeeWorkReturn ? "Yes" : "No" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="injuryColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: injuryColumns"
    (click)="onRowClick(row)"
    class="clickable"
    [class.row-selected]="isRowSelected('injuries', row.id)"
  ></tr>

  <!--No data row-->
  <tr *matNoDataRow>
    <td *ngIf="!isLoading" [attr.colspan]="injuryColumns.length" class="no-records-found">
      No records found
    </td>
  </tr>
</table>

<div *ngIf="isLoading" class="spinner-container">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<hr *ngIf="!isLoading" />

<div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
