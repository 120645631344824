<mat-dialog-actions>
  <div class="dialog-close">
    <i class="fas fa-times" (click)="onDialogCloseClick()"></i>
  </div>
  <div mat-dialog-title>New Accident</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="info">
    For safety assistance, please call Laura Hall: 443-997-0541
    <br />If you need after hours substance testing, please call 866-457-4009 Account #101
    432 77
  </div>

  <mat-stepper #stepper (selectionChange)="onStepChange($event)">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <!-- Driver Info Step -->
    <mat-step [stepControl]="driverInfoFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Driver Info</ng-template>
        <app-step-driver-info
          [driverInfoForm]="driverInfoFormGroup"
        ></app-step-driver-info>
      </form>
    </mat-step>

    <!-- Time & Place Step -->
    <mat-step [stepControl]="timePlaceFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Time & Place</ng-template>
        <app-step-time-place [timePlaceForm]="timePlaceFormGroup"></app-step-time-place>
      </form>
    </mat-step>

    <!-- Flares & Hazmat Step -->
    <mat-step [stepControl]="flaresHazmatFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Flares & Hazmat</ng-template>
        <app-step-flares-hazmat
          [flaresHazmatForm]="flaresHazmatFormGroup"
        ></app-step-flares-hazmat>
      </form>
    </mat-step>

    <!-- Injury Step -->
    <mat-step [stepControl]="injuryFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Injury</ng-template>
        <app-step-injury [injuryForm]="injuryFormGroup"></app-step-injury>
      </form>
    </mat-step>

    <!-- Other Party Step -->
    <mat-step [stepControl]="otherPartyFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Other Party</ng-template>
        <app-step-other-party
          [otherPartyForm]="otherPartyFormGroup"
        ></app-step-other-party>
      </form>
    </mat-step>

    <!-- Police Step -->
    <mat-step [stepControl]="policeFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Police</ng-template>
        <app-step-police [policeForm]="policeFormGroup"></app-step-police>
      </form>
    </mat-step>

    <!-- Vehicle Damage Step -->
    <mat-step [stepControl]="vehicleDamageFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Vehicle Damage</ng-template>
        <app-step-vehicle-damage
          [vehicleDamageForm]="vehicleDamageFormGroup"
        ></app-step-vehicle-damage>
      </form>
    </mat-step>

    <!-- Accident Details Step -->
    <mat-step [stepControl]="accidentDetailsFormGroup">
      <form [formGroup]="accidentForm">
        <ng-template matStepLabel>Accident Details</ng-template>
        <app-step-accident-details
          [accidentDetailsForm]="accidentDetailsFormGroup"
        ></app-step-accident-details>
      </form>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div class="stepper-container w-500" [formGroup]="doneFormGroup">
        <h3>
          <span *ngIf="isDoneStepEnabled"
            >A new accident for
            <span class="mark">{{
              driverInfoFormGroup.get("driverCode")?.value | uppercase
            }}</span>
            has been created.</span
          >
          <span
            *ngIf="showCallHazmat || showCallGeneOrLaura || showScheduleAccidentTesting"
            >Please complete the following action item(s).</span
          >
        </h3>
        <div class="column">
          <mat-checkbox
            color="primary"
            formControlName="callHazmatResponse"
            *ngIf="showCallHazmat"
            >Call Hazmat Response that fuel or hazardous materials have spilled:
            888-742-2885
          </mat-checkbox>
          <mat-checkbox
            color="primary"
            formControlName="callAdminsInformFatality"
            *ngIf="showCallGeneOrLaura"
            >Call Gene or Laura to inform them of the fatality.</mat-checkbox
          >
          <mat-checkbox
            color="primary"
            formControlName="scheduleDriverTesting"
            *ngIf="showScheduleAccidentTesting"
            >Schedule accident testing with the driver.</mat-checkbox
          >
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
  <button
    mat-flat-button
    color="primary"
    (click)="onDoneClick()"
    *ngIf="step === 8"
    [disabled]="!driverNameValue || !dateValue || !isDoneStepEnabled"
  >
    Done
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSaveClick()"
    *ngIf="step === 7"
    [disabled]="!driverNameValue || !dateValue"
  >
    Save
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="nextStep()"
    *ngIf="step < 7"
    [disabled]="!driverNameValue"
  >
    Next
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSaveClick(true)"
    *ngIf="step < 8 && step > 0"
    [disabled]="!driverNameValue || !dateValue"
  >
    Save and Finish Later
  </button>
  <button
    mat-stroked-button
    color="primary"
    *ngIf="step !== 0 && step !== 8"
    (click)="prevStep()"
  >
    Back
  </button>
  <button
    mat-stroked-button
    color="warn"
    (click)="onDialogCloseClick()"
    *ngIf="step === 0"
  >
    Cancel
  </button>
  <button mat-stroked-button color="warn" (click)="reset()" *ngIf="step === 8">
    Reset
  </button>
</mat-dialog-actions>
