import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { DriversListComponent } from './drivers-list/drivers-list.component';
import { DialogDriverComponent } from './dialog-driver/dialog-driver.component';

const routes: Routes = [
  { path: '', component: DriversListComponent, children: [] },
  { path: 'details', component: DialogDriverComponent },
  /* { path: ':terminal', component: DriversListComponent }, */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DriversRoutingModule {}
