import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';

import { IHoursService } from '@app/models/hours-service.model';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { convertToHourMinuteFormat } from '@app/shared/helpers/date.helper';
import { PaginationParams } from '@app/models/pagination-params';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-hours-service-list',
  templateUrl: './hours-service-list.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class HoursServiceListComponent implements OnInit, OnDestroy {
  @Input() dateRange: any;
  @Input() driverGUID!: string;
  @Input() maxDisplayedRecords!: number;
  @Input() defaultPageSize!: number;
  @Output() rowClicked = new EventEmitter<any>();

  hoursServiceList: IHoursService[] = [];
  hoursServiceDataFiltered: IHoursService[] = [];
  hoursServiceColumns: string[] = [
    'summaryDate',
    'day',
    'offDuty',
    'sleeper',
    'driving',
    'onDuty',
    'personalConveyance',
    'violations',
  ];
  isLoading = false;
  selectedRow: { sectionName: string; rowId?: string } | null = null;

  isViewMore = true;
  pageSize!: number;
  paginationParams!: PaginationParams;
  pageEvent!: PageEvent;
  startPageIdx = 1;
  endPageIdx!: number;
  showViewMore = false;
  totalRecordCount = 10000;
  isExpandViewTriggered = false;

  convertToHourMinuteFormat = convertToHourMinuteFormat;

  private dateRangeSubscription!: Subscription;
  private expandViewSubscription!: Subscription;

  private eventIdFromUrl?: string;

  constructor(
    private dialogInfoService: DialogDriverService,
    private expandViewService: ExpandViewService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initializePaginator();
    this.subscribeToDateRangeChanges();
    this.subscribeToExpandView();

    this.checkUrlParameters();

    this.getHoursServiceSummary();
  }

  ngOnDestroy(): void {
    this.dateRangeSubscription?.unsubscribe();
    this.expandViewSubscription?.unsubscribe();
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'hoursOfService' });
    this.updateUrlParams({ activity: 'hos', eventId: row.id });
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  getHoursServiceSummary(fetchAllRecords: boolean = false): void {
    this.isLoading = true;
    const { start, end } = this.dateRange.value;

    if (!start || !end) {
      this.isLoading = false;
      return;
    }

    const request = {
      startDate: start,
      endDate: end,
      driverGUID: this.driverGUID,
      page: this.pageEvent.pageIndex + 1,
      pageSize: fetchAllRecords ? this.totalRecordCount : this.pageEvent.pageSize,
    };

    this.dialogInfoService.getHoursServiceSummary(request).subscribe({
      next: (response: any) => {
        this.handleHoursServiceResponse(response, fetchAllRecords);
      },
      error: (error: any) => {
        this.isLoading = false;
        console.error('Error:', error);
      },
    });
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    this.updatePageIndices();
    this.getHoursServiceSummary();
  }

  loadMore(expandViewMore: boolean = false): void {
    this.isViewMore = expandViewMore ? false : !this.isViewMore;
    this.initializePaginator();
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.getHoursServiceSummary(expandViewMore);
  }

  private initializePaginator(): void {
    this.paginationParams = {
      limit: this.defaultPageSize,
      offset: 0,
      total: 0,
    };

    this.pageEvent = {
      length: 0,
      pageIndex: 0,
      pageSize: this.defaultPageSize,
    };

    this.pageSize = this.maxDisplayedRecords;
    this.startPageIdx = 1;
  }

  private subscribeToDateRangeChanges(): void {
    this.dateRangeSubscription = this.dialogInfoService.dateChange$.subscribe(() => {
      this.getHoursServiceSummary();
    });
  }

  private subscribeToExpandView(): void {
    this.expandViewSubscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  private checkUrlParameters(): void {
    const params = this.route.snapshot.queryParams;
    if (params['activity'] === 'hos') {
      this.eventIdFromUrl = params['eventId'];
      this.onRowClick({ id: this.eventIdFromUrl });
      this.eventIdFromUrl = undefined;
    }
  }

  private handleHoursServiceResponse(response: any, fetchAllRecords: boolean): void {
    this.isLoading = false;
    const data = response[0];
    this.hoursServiceList = data.hosSummaries || [];
    this.totalRecordCount = data.totalRecordCount || 0;
    this.paginationParams.total = this.totalRecordCount;

    if (fetchAllRecords) {
      this.pageSize = this.totalRecordCount;
      this.endPageIdx = this.totalRecordCount;
    }

    if (this.totalRecordCount) {
      this.updateDataFilteredAndIndices();
      this.updateShowViewMore();
    }

    if (this.isExpandViewTriggered) {
      this.expandViewService.incrementExpandView();
      this.isExpandViewTriggered = false;
    }
  }

  private updateDataFilteredAndIndices(): void {
    this.endPageIdx =
      this.endPageIdx || Math.min(this.defaultPageSize, this.totalRecordCount);
    this.hoursServiceDataFiltered = this.hoursServiceList.slice(0, this.pageSize);
  }

  private updateShowViewMore(): void {
    this.showViewMore = this.hoursServiceList.length > this.maxDisplayedRecords;
  }

  private updatePageIndices(): void {
    const startIdx = this.pageEvent.pageIndex * this.pageEvent.pageSize;
    const endIdx = startIdx + this.pageEvent.pageSize;
    this.startPageIdx = startIdx + 1;
    this.endPageIdx = Math.min(endIdx, this.totalRecordCount);
  }

  private updateUrlParams(params: { [key: string]: string }): void {
    const url = new URL(window.location.href);
    Object.keys(params).forEach((key) => {
      url.searchParams.set(key, params[key]);
    });
    window.history.replaceState({}, '', url.toString());
  }
}
