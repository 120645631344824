import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//material
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { RadioButtonModule } from '@app/shared/components/radio-button/radio-button.module';
import { TimePickerModule } from '@app/shared/components/time-picker/time-picker.module';
import { InputModule } from '@app/shared/components/input/input.module';
import { DateTimePickerModule } from '@app/shared/components/date-time-picker/date-time-picker.module';

import { DialogNewAccidentComponent } from '@app/shared/components/dialog-new-accident/dialog-new-accident.component';
import { StepDriverInfoComponent } from './step-driver-info/step-driver-info.component';
import { StepTimePlaceComponent } from './step-time-place/step-time-place.component';
import { StepFlaresHazmatComponent } from './step-flares-hazmat/step-flares-hazmat.component';
import { StepInjuryComponent } from './step-injury/step-injury.component';
import { StepOtherPartyComponent } from './step-other-party/step-other-party.component';
import { StepPoliceComponent } from './step-police/step-police.component';
import { StepVehicleDamageComponent } from './step-vehicle-damage/step-vehicle-damage.component';
import { StepAccidentDetailsComponent } from './step-accident-details/step-accident-details.component';

import { DriverSearchModule } from '@app/shared/components/driver-search/driver-search.module';

@NgModule({
  declarations: [
    DialogNewAccidentComponent,
    StepDriverInfoComponent,
    StepTimePlaceComponent,
    StepFlaresHazmatComponent,
    StepInjuryComponent,
    StepOtherPartyComponent,
    StepPoliceComponent,
    StepVehicleDamageComponent,
    StepAccidentDetailsComponent,
  ],
  exports: [DialogNewAccidentComponent],
  imports: [
    CommonModule,
    MatStepperModule,
    InputModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
    TimePickerModule,
    MatCheckboxModule,
    CheckboxModule,
    RadioButtonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    DateTimePickerModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    DriverSearchModule,
  ],
})
export class DialogNewAccidentModule {}
