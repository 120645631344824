<div class="date-range-picker-container">
  <div
    [ngStyle]="{
      transform:
        positionOffset === 'right'
          ? 'translateX(133px)'
          : positionOffset === 'left'
          ? 'translateX(-30px)'
          : 'none'
    }"
  >
    <!-- Range buttons -->
    <div
      class="date-range-buttons"
      *ngIf="showButtons"
      [ngStyle]="{ top: rounded ? '36px' : '56px' }"
    >
      <button mat-flat-button (click)="setLast7Days()" class="my-custom-button">
        Last 7 days
      </button>
      <button mat-flat-button (click)="setLast30Days()" class="my-custom-button">
        Last 30 days
      </button>
      <button mat-flat-button (click)="setLast90Days()" class="my-custom-button">
        Last 90 days
      </button>
      <button mat-flat-button (click)="setLast6Months()" class="my-custom-button">
        Last 6 months
      </button>
      <button mat-flat-button (click)="setLast12Months()" class="my-custom-button">
        Last 12 months
      </button>
      <button mat-flat-button (click)="setMonthToDate()" class="my-custom-button">
        Month to Date
      </button>
      <button mat-flat-button (click)="setQuarterToDate()" class="my-custom-button">
        Quarter to Date
      </button>
      <button mat-flat-button (click)="setThisYear()" class="my-custom-button">
        Year to Date
      </button>
    </div>

    <!-- Clear and Today buttons -->
    <div
      class="additional-buttons"
      *ngIf="showButtons"
      [ngStyle]="{ top: rounded ? '392px' : '412px' }"
    >
      <button
        mat-flat-button
        color="#006600"
        (click)="setToday()"
        class="my-custom-button green"
      >
        Today
      </button>
      <button
        mat-stroked-button
        color="primary"
        (click)="clearDates()"
        class="my-custom-button"
      >
        Clear
      </button>
    </div>

    <mat-date-range-picker #picker (opened)="onOpen()" (closed)="onClose()">
      <mat-date-range-picker-actions *ngIf="isMobile">
        <button mat-button matDateRangePickerCancel>Cancel</button>
        <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </div>
  <!-- Calendar with outline appearance -->
  <mat-form-field *ngIf="!rounded" appearance="outline" style="width: 100%">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input
      [formGroup]="range"
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
    >
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
      >Invalid start date</mat-error
    >
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
      >Invalid end date</mat-error
    >
  </mat-form-field>

  <!-- Calendar without any appearance -->
  <mat-form-field *ngIf="rounded" [ngClass]="{ 'date-picker': rounded }">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input
      [formGroup]="range"
      [rangePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
    >
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>

    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
      >Invalid start date</mat-error
    >
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
      >Invalid end date</mat-error
    >
  </mat-form-field>
  <!-- Calendar -->
</div>
