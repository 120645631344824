import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { PaginationParams, Section } from '@app/models';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { TabInterventionSections } from '@app/drivers/dialog-driver/dialog-driver.constants';
import { addSpaceAfterComma } from '@app/shared/helpers/string.helper';
import { InterventionService } from '@app/services/intervention.service';
import { DialogNewInterventionComponent } from '@app/shared/components/dialog-new-intervention/dialog-new-intervention.component';
import { DialogCoachingDetailsComponent } from '@app/shared/components/dialog-coaching-details/dialog-coaching-details.component';
import {
  calculateDialogWidth,
  getBackgroundClass,
  getInitials,
} from '@app/shared/helpers/functions.helper';
import { FlyoutType } from '@app/models/fly-out.model';
import { FlyoutService } from '@app/services/flyout.service';
import { PermissionService } from '@app/services/permission.service';
import { AssigneeType } from '@app/models/status.model';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-tab-sections-intervention',
  templateUrl: './tab-sections-intervention.component.html',
  styleUrls: ['./tab-sections-intervention.component.scss'],
})
export class TabSectionsInterventionComponent implements OnInit, OnDestroy {
  @Input() driverId!: string;
  @Input() driverProfile!: any;
  @Input() rowData!: any;

  coachingColumns: string[] = [
    'key',
    'reason',
    'eventType',
    'priority',
    'assignee',
    'dueDate',
    'status',
  ];
  coachingDataFiltered: any[] = [];
  coachingList!: any[];
  isLoadingCoaching: boolean = false;
  showViewMoreCoaching: boolean = false;
  isViewMore: boolean = true;
  pageSize!: number;
  maxDisplayedRecords: number = 5;
  defaultPageSize: number = 10000;

  startPageIdx!: number;
  endPageIdx!: number;
  pageEvent!: PageEvent;
  paginationParams!: PaginationParams;
  totalRecordCount: number = 0;

  statuses = [
    {
      value: 'open',
      viewValue: 'Open',
      icon: 'fa-solid fa-circle-notch icon-default-size',
    },
    {
      value: 'scheduled',
      viewValue: 'Scheduled',
      icon: 'fa-solid fa-calendar-days icon-orange icon-default-size',
    },
    {
      value: 'in-progress',
      viewValue: 'In Progress',
      icon: 'fa-solid fa-clock icon-second-blue icon-default-size',
    },
    {
      value: 'completed',
      viewValue: 'Completed',
      icon: 'fa-solid fa-circle-check icon-green icon-default-size',
    },
  ];
  addSpaceAfterComma = addSpaceAfterComma;
  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;
  assigneeType = AssigneeType;

  sections: Section[] = TabInterventionSections;
  sectionSelected: string = 'coaching';
  selectedRow: string | null = null;

  isExpandViewTriggered: boolean = false;
  subscription!: Subscription;

  selectedOrganization: any = {};

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private dialogInfoService: DialogDriverService,
    private interventionService: InterventionService,
    private dialog: MatDialog,
    private flyoutService: FlyoutService,
    private permissionService: PermissionService,
    private expandViewService: ExpandViewService
  ) {}

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.setPaginatorConfig();
    this.getCoachingList();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  getCoachingList(): void {
    this.isLoadingCoaching = true;
    const params = {
      driverGuid: this.driverProfile.id,
      sortField: 'due_date',
      sortValue: 'desc',
    };
    this.interventionService.getInterventionsList(params).subscribe({
      next: (res) => {
        this.isLoadingCoaching = false;
        this.totalRecordCount = res[0]?.totalRecordCount;
        this.paginationParams.total = this.totalRecordCount;
        if (this.totalRecordCount) {
          this.coachingList = res[0].AllInterventions;
          this.coachingDataFiltered = this.coachingList?.slice(0, this.pageSize);
          this.setDataFilteredAndEndPageIdx();
          this.setShowViewMoreCoaching();
        } else {
          this.coachingList = [];
        }
        this.setShowViewMoreCoaching();
      },
      error: (error) => {
        this.isLoadingCoaching = false;
        console.log(error);
      },
    });
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
      if (this.isExpandViewTriggered) {
        this.expandViewService.incrementExpandView();
        this.isExpandViewTriggered = false;
      }
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.setPaginatorConfig();
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.coachingDataFiltered = this.coachingList?.slice(0, this.pageSize);
  }

  setShowViewMoreCoaching(): void {
    if (this.coachingList.length > this.maxDisplayedRecords) {
      this.showViewMoreCoaching = true;
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageEvent = event;
    const startIdx = event.pageIndex * event.pageSize;
    const endIdx = startIdx + event.pageSize;
    this.startPageIdx = startIdx + 1;
    this.endPageIdx = endIdx < this.totalRecordCount ? endIdx : this.totalRecordCount;
    this.getCoachingList();
  }

  setPaginatorConfig(): void {
    this.paginationParams = {
      limit: this.defaultPageSize,
      offset: 0,
      total: 0,
    };
    this.pageEvent = {
      length: this.paginationParams.total || 0,
      pageIndex: 0,
      pageSize: this.paginationParams.limit,
    };
    this.pageSize = this.maxDisplayedRecords;
    this.startPageIdx = 1;
  }

  setDataFilteredAndEndPageIdx(): void {
    if (!this.endPageIdx) {
      this.endPageIdx =
        this.defaultPageSize < this.totalRecordCount
          ? this.defaultPageSize
          : this.totalRecordCount;
    }
    this.coachingDataFiltered = this.coachingList?.slice(0, this.pageSize);
  }

  onNavClick(elementId: string): void {
    this.dialogInfoService.onNavClick(elementId);
  }

  announceSortChange(sortState: Sort): void {
    const data = this.coachingDataFiltered.slice();

    this.coachingDataFiltered = data.sort((a, b) => {
      const isAsc = sortState.direction === 'asc';
      switch (sortState.active) {
        case 'key':
          return this.compare(a.key, b.key, isAsc);
        case 'reason':
          return this.compare(a.reason, b.reason, isAsc);
        case 'eventType':
          return this.compareString(a.eventType, b.eventType, isAsc);
        case 'priority':
          return this.compare(a.priority, b.priority, isAsc);
        case 'assignee':
          return this.compare(a.assignee, b.assignee, isAsc);
        case 'dueDate':
          return this.compareString(a.dueDate, b.dueDate, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareString(str1: string, str2: string, isAsc: boolean): number {
    if (str1 === undefined || str1 === null) {
      return str2 === undefined || str2 === null ? 0 : isAsc ? -1 : 1;
    }
    if (str2 === undefined || str2 === null) {
      return isAsc ? 1 : -1;
    }
    return str1.localeCompare(str2) * (isAsc ? 1 : -1);
  }

  onAddNewClick(): void {
    this.dialog
      .open(DialogNewInterventionComponent, {
        width: '800px',
        panelClass: 'single',
        data: {
          driver: {
            driverId: this.driverId,
            driverName: this.driverProfile.driverName,
            terminal: this.driverProfile.terminal,
            organizationId: this.selectedOrganization.organizationId,
          },
        },
      })
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          this.getCoachingList();
        }
      });
  }

  isRowSelected(id: string): boolean {
    return id === this.selectedRow;
  }

  onRowClick(row: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.COACHING);
    this.selectedRow = row?.driverID;
    const dialogWidth = calculateDialogWidth(
      this.flyoutService.getFlyoutOffsetBasedOnIndex()
    );

    this.dialog
      .open(DialogCoachingDetailsComponent, {
        data: {
          ...row,
          fromDriver: true,
          uniqueIdentifier: FlyoutType.COACHING,
        },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: dialogWidth,
        panelClass: ['dialog-event', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedRow = null;
      });
  }

  canCreateInterventions(): boolean {
    return this.permissionService.hasPermission('WRITE', 'All Interventions');
  }
}
