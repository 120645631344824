<article #scrollContainer>
  <section>
    <div class="header-block info-block">
      <h2 [attr.id]="'general-info'">General Info</h2>
    </div>

    <div
      *ngIf="!isLoadingDriverProfile && !isLoadingDriverScoring"
      class="grid general-info"
    >
      <ng-container *ngFor="let item of dataGeneralInfo">
        <div
          class="grid-item"
          [class.has-avatar]="item?.avatarUrl || item?.avatarUrl === null"
        >
          <img
            *ngIf="item?.avatarUrl || item?.avatarUrl === null"
            [src]="item?.avatarUrl"
            appImgFallback
            alt="Avatar"
            class="avatar"
          />
          <div class="title">
            <i *ngIf="item?.icon" class="fas fa-{{ item.icon }}"></i>
            <span
              >{{ item?.title }}
              <i *ngIf="item?.scoreIcon" class="fa-solid fa-caret-up icon-green"></i
            ></span>
          </div>
          <label>{{ item?.label }}</label>
        </div>
      </ng-container>
    </div>
    <!-- Spinner -->
    <div
      *ngIf="isLoadingDriverProfile || isLoadingDriverScoring"
      class="spinner-container"
    >
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
  </section>

  <section>
    <div class="header-block info-block">
      <h2 [attr.id]="'personal-info'">Personal Info</h2>
    </div>

    <div *ngIf="!isLoadingDriverProfile" class="grid personal-info">
      <ng-container *ngFor="let item of dataPersonalInfo">
        <div class="grid-item">
          <div class="title">{{ item?.title !== "" ? item?.title : "-" }}</div>
          <label>{{ item?.label }}</label>
        </div>
      </ng-container>
    </div>
    <!-- Spinner -->
    <div *ngIf="isLoadingDriverProfile" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
  </section>

  <section>
    <div class="header-block-safety">
      <h2 [attr.id]="'safety'" class="title-with-spinner">
        Safety
        <div *ngIf="isLoadingDriverScoring" class="spinner-container spinner-title">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="20"
            class="spinner"
          ></mat-progress-spinner>
        </div>
      </h2>
      <!-- TBD -->
      <!--<div class="block-level-driver">
        <div class="round-span">2</div>
        <h2 class="level-driver">Level 2 Driver</h2>
      </div>-->
    </div>
    <div class="top-point">
      <div>+2</div>
      <i class="fa-solid fa-circle-arrow-up"></i>
    </div>
    <div class="full-width-div" [ngStyle]="styles.principalBar">
      <span class="point-vale-level" [ngStyle]="styles.pointValeLevel">{{
        (scoring?.currentScore ?? 0) / 10 | number : "1.0-1"
      }}</span>
    </div>
    <div class="bottom-point" [ngStyle]="styles.bottomPoint">
      <div class="triangle"></div>
      <p [ngStyle]="styles.averageScoringText">
        Driver Avg
        <span>{{ (scoring?.averageScore ?? 0) / 10 | number : "1.0-1" }}</span>
      </p>
    </div>
    <table mat-table matSort [dataSource]="tableData.safety.data" class="block-safety">
      <ng-container matColumnDef="factors">
        <th mat-header-cell *matHeaderCellDef>Factors</th>
        <td mat-cell *matCellDef="let element">{{ element?.factors }}</td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef>Deductions</th>
        <td mat-cell *matCellDef="let element">
          <div
            class="score-item"
            [class]="getBarClass(element?.empty)"
            [ngStyle]="getBarStyle(element?.empty)"
          ></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="empty">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.empty ?? 0) / 10 | number : "1.0-1" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="average">
        <th mat-header-cell *matHeaderCellDef>Average</th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.average ?? 0) / 10 | number : "1.0-1" }}
        </td>
      </ng-container>
      <!-- TBD -->
      <!--<ng-container matColumnDef="change">
        <th mat-header-cell *matHeaderCellDef>Change</th>
        <td mat-cell *matCellDef="let element">
          <div class="block-count-change">
            <span class="count-change">{{ element?.change }}</span>
            <i
              class="fa-solid fa-circle-arrow-{{
                +element?.change > 0 ? 'up' : 'down'
              }} {{ +element?.change > 0 ? 'icon-green' : 'icon-red' }} "
            ></i>
          </div>
        </td>
      </ng-container>-->
      <tr mat-header-row *matHeaderRowDef="tableData.safety.columns"></tr>
      <tr
        class="no-hover"
        mat-row
        *matRowDef="let row; columns: tableData.safety.columns"
      ></tr>
    </table>
    <hr />
    <div class="more-info" *ngIf="canViewScoreHistory()">
      <button mat-button (click)="openScoreHistoryModal()">
        <i class="fas fa-solid fa-magnifying-glass-chart"></i>
        <span class="score-history-text">Score History</span>
      </button>
    </div>
  </section>
</article>

<ul *ngIf="sections.length > 1 && showMenu">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li (click)="onNavClick(item.id)" [class.active]="item.id === sectionSelected">
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
