<div #modalContainer>
  <mat-dialog-actions>
    <div class="dialog-close">
      <div *ngIf="isLoadingPrintWeb" class="spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="20"
          class="spinner"
        ></mat-progress-spinner>
      </div>
      <i
        *ngIf="
          selectedTabName === driverTabs.DriverFiles ||
          selectedTabName === driverTabs.DriverActivity ||
          selectedTabName === driverTabs.DriverInterventions ||
          selectedTabName === driverTabs.DriverEvents
        "
        class="fa-solid fa-print print-icon"
        (click)="onPrintClick()"
      ></i>
      <i
        *ngIf="
          selectedTabName !== driverTabs.DriverFiles &&
          selectedTabName !== driverTabs.DriverActivity &&
          selectedTabName !== driverTabs.DriverInterventions &&
          selectedTabName !== driverTabs.DriverEvents
        "
        class="fa-solid fa-print print-icon"
        (click)="printCurrentTabContent()"
      ></i>
      <i mat-dialog-close class="fas fa-times"></i>
    </div>
    <div class="dialog-title">
      <div class="driver-avatar">
        <img
          [src]="getImageUrl(data?.driverId)"
          appImgFallback
          alt="Avatar"
          class="user-avatar"
        />
      </div>

      <div class="driver-info">
        <h2>{{ data.driverName ? toTitleCase(data?.driverName) : "" }}</h2>

        <button mat-button (click)="goToInfoTab()">
          <i class="fas fa-id-card"></i>
          <span>{{ data?.driverId }}</span>
        </button>

        <button mat-button (click)="goToTerminal(data?.terminal)">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ data?.terminal }}</span>
        </button>
      </div>

      <div class="driver-actions">
        <button
          mat-flat-button
          color="primary"
          *ngIf="canCreateInterventions()"
          (click)="onNewInterventionClick()"
        >
          <i class="fas fa-plus-circle"></i>
          <span>New Intervention</span>
        </button>

        <button
          mat-flat-button
          color="primary"
          *ngIf="canCreateFile()"
          (click)="onNewFileClick()"
        >
          <i class="fas fa-plus-circle"></i>
          <span>New File</span>
        </button>
      </div>
    </div>
  </mat-dialog-actions>
  <mat-dialog-content>
    <mat-tab-group
      #tabGroup
      *ngIf="data?.terminal"
      (selectedTabChange)="onTabChange($event)"
      animationDuration="0ms"
      [(selectedIndex)]="selectedTabIndex"
    >
      <!-- Info -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverProfile)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverProfile)"></i>
          <span>Info</span>
        </ng-template>
        <app-tab-sections-info
          [rowData]="data"
          [showMenu]="showMenu"
          *ngIf="isActiveTab(driverTabs.DriverProfile)"
        >
        </app-tab-sections-info>
      </mat-tab>

      <!-- Files -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverFiles)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverFiles)"></i>
          <span>Files</span>
        </ng-template>
        <app-tab-sections-files *ngIf="isActiveTab(driverTabs.DriverFiles)">
        </app-tab-sections-files>
      </mat-tab>

      <!-- Activity -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverActivity)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverActivity)"></i>
          <span>Activity</span>
        </ng-template>
        <app-tab-sections-activity
          *ngIf="isActiveTab(driverTabs.DriverActivity)"
          [rowData]="rowData"
        >
        </app-tab-sections-activity>
      </mat-tab>

      <!-- Intervention -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverInterventions)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverInterventions)"></i>
          <span>Intervention</span>
        </ng-template>
        <app-tab-sections-intervention
          *ngIf="isActiveTab(driverTabs.DriverInterventions)"
          [rowData]="rowData"
          [driverId]="data?.driverId"
          [driverProfile]="data"
        >
        </app-tab-sections-intervention>
      </mat-tab>

      <!-- Events -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverEvents)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverEvents)"></i>
          <span>Events</span>
        </ng-template>
        <app-tab-sections-events
          *ngIf="isActiveTab(driverTabs.DriverEvents)"
          [driverId]="data?.driverId"
          [driverGuid]="data?.id"
          [showSmaller]="showSmaller"
        >
        </app-tab-sections-events>
      </mat-tab>

      <!-- Notes -->
      <mat-tab *ngIf="shouldShowTabItem(driverTabs.DriverNotes)">
        <ng-template mat-tab-label>
          <i class="fas" [ngClass]="getTabIcon(driverTabs.DriverNotes)"></i>
          <span>Notes</span>
        </ng-template>
        <app-tab-sections-notes
          *ngIf="isActiveTab(driverTabs.DriverNotes)"
          [driverId]="data?.id"
        >
        </app-tab-sections-notes>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</div>
