import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  ISpeedPercentageElement,
  PercentageSpeedingSource,
} from '@app/models/speed.model';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import {
  convertDateFormat,
  convertMinutesToHHmm,
  convertSecondsToHHmmss,
} from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-dialog-driver-speeding',
  templateUrl: './dialog-driver-percentage-speeding.component.html',
  styleUrls: ['./dialog-driver-percentage-speeding.component.scss'],
})
export class DialogDriverPercentageSpeedingComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  percentageSpeedingRowData!: ISpeedPercentageElement;
  percentageSpeedingDetail!: any;
  activityDetailsColumns: string[] = [
    'startDate',
    'endDate',
    'totalDistanceTraveled',
    'driveTime',
    'maxSpeed',
  ];
  activityDetailsSummary!: any;
  source!: string;
  breakdownColumnsSamsara: string[] = ['type', 'time', 'percentage'];
  breakdownColumnsSpeedGauge: string[] = [
    'type',
    'incidents',
    'observations',
    'percentage',
  ];
  breakdownSummary!: any;

  percentageSpeedingSource = PercentageSpeedingSource;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogInfoService: DialogDriverService
  ) {
    this.percentageSpeedingRowData = data;
  }

  ngOnInit(): void {
    if (this.percentageSpeedingRowData && this.percentageSpeedingRowData.id) {
      this.dialogInfoService
        .getPercentageSpeedingDetail(this.percentageSpeedingRowData.id)
        .subscribe({
          next: (response: ISpeedPercentageElement[]) => {
            this.percentageSpeedingDetail = response[0];
            this.setSource();
            this.setActivityDetailsSummary();
            this.setBreakdownSummary();
            this.isLoading = false;
          },
          error: (error: any) => {
            console.error('Error:', error);
            this.isLoading = false;
          },
        });
    }
  }

  ngOnDestroy(): void {
    const url = new URL(window.location.href);
    url.searchParams.delete('activity');
    url.searchParams.delete('eventId');

    window.history.replaceState({}, '', url.toString());
  }

  setSource(): void {
    const rawSource = this.percentageSpeedingDetail?.Source ?? '-';
    this.source =
      rawSource.toLowerCase() === 'speedguage' || rawSource.toLowerCase() === 'speedgauge'
        ? 'SpeedGauge'
        : rawSource;
  }

  setActivityDetailsSummary(): void {
    let driveTime: string = '-';

    if (this.source === this.percentageSpeedingSource.SPEEDGAUGE) {
      driveTime = this.percentageSpeedingDetail.totalDriveTime
        ? convertMinutesToHHmm(this.percentageSpeedingDetail.totalDriveTime)
        : '-';
    } else if (this.source === this.percentageSpeedingSource.SAMSARA) {
      driveTime = this.percentageSpeedingDetail.totalDriveTimeInSeconds
        ? convertSecondsToHHmmss(+this.percentageSpeedingDetail.totalDriveTimeInSeconds)
        : '-';
    }

    this.activityDetailsSummary = [
      {
        startDate: convertDateFormat(this.percentageSpeedingDetail.startDate),
        endDate: convertDateFormat(this.percentageSpeedingDetail.endDate),
        totalDistanceTraveled: this.percentageSpeedingDetail.totalDriveDistance,
        driveTime: driveTime,
        maxSpeed: this.percentageSpeedingDetail.totalPercentSpeeding,
      },
    ];
  }

  setBreakdownSummary(): void {
    if (this.source === PercentageSpeedingSource.SAMSARA) {
      // prettier-ignore
      this.breakdownSummary = [
        {
          type: 'Light Speeding',
          percentage: this.percentageSpeedingDetail?.lightCriteria
            ? this.percentageSpeedingDetail?.lightCriteria[0]?.speedingPercentage
            : '',
          time: this.percentageSpeedingDetail?.lightCriteria
            ? this.percentageSpeedingDetail?.lightCriteria[0]?.speedingTimeInSeconds
            : '',
        },
        {
          type: 'Moderate Speeding',
          percentage: this.percentageSpeedingDetail?.moderateCriteria
            ? this.percentageSpeedingDetail?.moderateCriteria[0]?.speedingPercentage
            : '',
          time: this.percentageSpeedingDetail?.moderateCriteria
            ? this.percentageSpeedingDetail?.moderateCriteria[0]?.speedingTimeInSeconds
            : '',
        },
        {
          type: 'Heavy Speeding',
          percentage: this.percentageSpeedingDetail?.heavyCriteria
            ? this.percentageSpeedingDetail?.heavyCriteria[0]?.speedingPercentage
            : '',
          time: this.percentageSpeedingDetail?.heavyCriteria
            ? this.percentageSpeedingDetail?.heavyCriteria[0]?.speedingTimeInSeconds
            : '',
        },
        {
          type: 'Severe Speeding',
          percentage: this.percentageSpeedingDetail?.severeCriteria
            ? this.percentageSpeedingDetail?.severeCriteria[0]?.speedingPercentage
            : '',
          time: this.percentageSpeedingDetail?.severeCriteria
            ? this.percentageSpeedingDetail?.severeCriteria[0]?.speedingTimeInSeconds
            : '',
        },
      ];
    }
    if (this.source === PercentageSpeedingSource.SPEEDGAUGE) {
      this.breakdownSummary = [
        {
          type: 'Interstate',
          incidents: this.percentageSpeedingDetail?.interstateCriteria
            ? this.percentageSpeedingDetail?.interstateCriteria[0]?.countOfIncidents
            : '',
          observations: this.percentageSpeedingDetail?.interstateCriteria
            ? this.percentageSpeedingDetail?.interstateCriteria[0]?.countOfObservations
            : '',
          percentage: this.percentageSpeedingDetail?.interstateCriteria
            ? this.percentageSpeedingDetail?.interstateCriteria[0]?.speedingPercentage
            : '',
        },
        {
          type: 'Non-interstate',
          incidents: this.percentageSpeedingDetail?.nonInterstateCriteria
            ? this.percentageSpeedingDetail?.nonInterstateCriteria[0]?.countOfIncidents
            : '',
          observations: this.percentageSpeedingDetail?.nonInterstateCriteria
            ? this.percentageSpeedingDetail?.nonInterstateCriteria[0]?.countOfObservations
            : '',
          percentage: this.percentageSpeedingDetail?.nonInterstateCriteria
            ? this.percentageSpeedingDetail?.nonInterstateCriteria[0]?.speedingPercentage
            : '',
        },
        {
          type: 'Total',
          incidents: this.percentageSpeedingDetail?.totalCriteria
            ? this.percentageSpeedingDetail?.totalCriteria[0]?.countOfIncidents
            : '',
          observations: this.percentageSpeedingDetail?.totalCriteria
            ? this.percentageSpeedingDetail?.totalCriteria[0]?.countOfObservations
            : '',
          percentage: this.percentageSpeedingDetail?.totalCriteria
            ? this.percentageSpeedingDetail?.totalCriteria[0]?.speedingPercentage
            : '',
        },
      ];
    }
  }

  getDayName(dateString: string): string {
    // Split the date string into parts (year, month, day)
    const [year, month, day] = dateString.split('-').map(Number);

    // Create the date using Date.UTC to avoid timezone issues
    const date = new Date(Date.UTC(year, month - 1, day));

    // Get the day name without relying on the local timezone
    return date.toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' });
  }
}
