import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

import { Section } from '@app/models';
import { ActivitySectionName } from '@app/models/grid.model';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { TableData } from '@app/drivers/dialog-driver/dialog-driver.constants';
import { DialogDriverAccidentComponent } from '@app/drivers/dialog-driver/dialog-driver-accident/dialog-driver-accident.component';
import { DialogDriverInjuryComponent } from '@app/drivers/dialog-driver/dialog-driver-injury/dialog-driver-injury.component';
import { DialogDriverTelematicsComponent } from '@app/drivers/dialog-driver/dialog-driver-telematics/dialog-driver-telematics.component';
import { DialogDriverHoursOfServiceComponent } from '@app/drivers/dialog-driver/dialog-driver-hours-of-service/dialog-driver-hours-of-service.component';
import { DialogDriverInspectionComponent } from '@app/drivers/dialog-driver/dialog-driver-inspection/dialog-driver-inspection.component';
import { DialogDriverSpeedComponent } from '@app/drivers/dialog-driver/dialog-driver-speed/dialog-driver-speed.component';
import { DialogDriverPercentageSpeedingComponent } from '@app/drivers/dialog-driver/dialog-driver-percentage-speeding/dialog-driver-percentage-speeding.component';
import { PermissionService } from '@app/services/permission.service';

const endDate = new Date();
const startDate = new Date();
startDate.setDate(endDate.getDate() - 30);

@Component({
  selector: 'app-tab-sections-activity',
  templateUrl: './tab-sections-activity.component.html',
  styleUrls: ['../tab-sections.component.scss'],
})
export class TabSectionsActivityComponent implements AfterViewInit {
  @Input() rowData!: any;
  readonly sections: Section[] = [
    { title: 'Accidents', id: 'accidents' },
    { title: 'Injuries', id: 'injuries' },
    { title: 'Telematics', id: 'telematics' },
    { title: 'Hours of Service', id: 'hours-of-service' },
    { title: 'Inspections', id: 'inspections' },
    { title: 'Speeding Events', id: 'speeding-events' },
    { title: 'Percentage Speeding', id: 'percentage-speeding' },
    { title: 'Seatbelt', id: 'seatbelt' },
  ];
  readonly tableData = TableData;
  selectedRow: { sectionName: string; rowId?: string } | null = null;
  dateRange = new FormGroup({
    start: new FormControl(
      new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
    ),
    end: new FormControl(
      new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    ),
  });
  maxDisplayedRecords: number = 2;
  defaultPageSize: number = 20;
  sectionSelected: string = 'accidents';

  mobileWidth = 850;

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private dialogInfoService: DialogDriverService,
    private dialog: MatDialog,
    private permissionService: PermissionService
  ) {
    setTimeout(() => this.dialogInfoService.viewInit(255));
  }

  ngAfterViewInit(): void {
    this.attachScrollListener();
  }

  private attachScrollListener(): void {
    const articleElement = this.scrollContainer.nativeElement;
    articleElement.addEventListener('scroll', () => {
      this.onContainerScroll();
    });
  }

  private onContainerScroll(): void {
    let closestSection = null;
    let minDistance = Infinity;

    this.sections.forEach((section) => {
      const element = this.scrollContainer.nativeElement.querySelector(`#${section.id}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const distance = Math.abs(
          rect.top - this.scrollContainer.nativeElement.getBoundingClientRect().top
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestSection = section.id;
        }
      }
    });

    if (closestSection !== null) {
      this.sectionSelected = closestSection;
    }
  }

  onDateRangeSelected(range: { start: Date | null; end: Date | null }) {
    if (range.start && range.end) {
      this.dateRange.setValue({
        start: range.start,
        end: range.end,
      });
      const start = this.dateRange.get('start')?.value;
      const end = this.dateRange.get('start')?.value;
      if (start && end) {
        this.dialogInfoService.setDateRange({ start, end });
      }
    }
  }

  dateRangeUpdated(): void {
    const start = this.dateRange.get('start')?.value;
    const end = this.dateRange.get('start')?.value;
    if (start && end) {
      this.dialogInfoService.setDateRange({ start, end });
    }
  }

  onNavClick(elementId: string): void {
    this.sectionSelected = elementId;
    this.dialogInfoService.onNavClick(elementId);
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  private isMobile(): boolean {
    return window.innerWidth <= this.mobileWidth;
  }

  onRowClick(data: any, sectionName: string): void {
    const clearSelectedRow = (): void => {
      this.selectedRow = null;
    };
    this.selectedRow = { sectionName };
    let component: any;
    switch (sectionName) {
      case ActivitySectionName.ACCIDENTS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverAccidentComponent;
        break;
      case ActivitySectionName.INJURIES:
        this.selectedRow.rowId = data.id;
        component = DialogDriverInjuryComponent;
        break;
      case ActivitySectionName.TELEMATICS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverTelematicsComponent;
        break;
      case ActivitySectionName.HOURS_OF_SERVICE:
        this.selectedRow.rowId = data.id;
        component = DialogDriverHoursOfServiceComponent;
        break;
      case ActivitySectionName.INSPECTIONS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverInspectionComponent;
        break;
      case ActivitySectionName.SPEEDING_EVENTS:
        this.selectedRow.rowId = data.id;
        component = DialogDriverSpeedComponent;
        break;
      case ActivitySectionName.PERCENTAGE_SPEEDING:
        this.selectedRow.rowId = data.id;
        component = DialogDriverPercentageSpeedingComponent;
        break;
      default:
        clearSelectedRow();
    }

    const isMobile = this.isMobile();

    this.dialog
      .open(component, {
        data,
        panelClass: isMobile ? 'full-screen-dialog' : 'single',
        autoFocus: false,
        ...(isMobile
          ? {
              width: '100vw',
              height: '100vh',
              maxWidth: '100vw',
              maxHeight: '100vh',
            }
          : {}),
      })
      .afterClosed()
      .subscribe(() => {
        clearSelectedRow();
        if (sectionName === ActivitySectionName.ACCIDENTS) {
          this.dialogInfoService.refreshAccidentsGrid();
        }
        if (sectionName === ActivitySectionName.INJURIES) {
          this.dialogInfoService.refreshInjuryGrid();
        }
      });
  }

  canViewAccidents(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Accident') ||
      this.permissionService.hasPermission('WRITE', 'Accident')
    );
  }

  canViewInjuries(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Injury') ||
      this.permissionService.hasPermission('WRITE', 'Injury')
    );
  }
}
