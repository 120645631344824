<section [class.sidenav]="isExpanded">
  <div class="toggle">
    <i (click)="toggleMenu.emit(null)" class="fas fa-bars"></i>
  </div>
  <app-sub-header [type]="'mobile'"></app-sub-header>

  <mat-nav-list>
    <ng-container *ngFor="let item of pageNavItems">
      <ng-container
        *ngIf="item.children && item.children.length > 0; else navItemWithNoChildren"
      >
        <mat-list-item *ngIf="shouldShowMenuItem(item.permissionKey)">
          <a
            mat-list-item
            (click)="onExpandToggleClick(item.link)"
            [class.active-item]="getIsActive(item.link)"
            (mouseenter)="showFloatingMenu($event, item)"
          >
            <div class="side-bar__item parent">
              <i class="fas fa-{{ item.icon }}"></i>
              <span *ngIf="isExpanded" class="item-position">{{ item?.title }}</span>
              <i
                *ngIf="isExpanded"
                class="fas fa-chevron-{{ isMenuItemExpanded(item.link) ? 'up' : 'down' }}"
              ></i>
            </div>
          </a>
        </mat-list-item>

        <ng-container *ngIf="isMenuItemExpanded(item.link)">
          <ng-container *ngFor="let child of item.children">
            <mat-list-item
              class="child-list-item"
              *ngIf="shouldShowMenuItem(child.permissionKey) && isExpanded"
            >
              <a
                mat-list-item
                routerLink="{{ item.link + '/' + child.link }}"
                [class.active-children]="getIsActive(child.link)"
                (mouseenter)="showFloatingMenu($event, item)"
              >
                <div class="side-bar__child">
                  <i class="indicator"></i>
                  <span class="item-position">{{ child?.title }}</span>
                </div>
              </a>
            </mat-list-item>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #navItemWithNoChildren>
        <mat-list-item *ngIf="shouldShowMenuItem(item.permissionKey)">
          <a
            mat-list-item
            [routerLink]="[item.link]"
            routerLinkActive="active-item"
            (mouseenter)="showFloatingMenu($event, item)"
          >
            <div class="side-bar__item">
              <i class="fas fa-{{ item.icon }}"></i>
              <span *ngIf="isExpanded" class="item-position">{{ item?.title }}</span>
            </div>
          </a>
        </mat-list-item>
      </ng-template>
    </ng-container>
  </mat-nav-list>
</section>

<div
  #floatingMenu
  class="floating-menu-element"
  (mouseleave)="hideFloatingMenu()"
  (click)="navigateToCurrentPath()"
>
  <div class="icon-text-container">
    <div class="floating-icon">
      <i
        class="fas fa-{{ iconName }}"
        [class.icon-selected]="isSelected(currentPath)"
      ></i>
    </div>
    <div #menuItemText class="text-container"></div>
  </div>
  <ul #subMenuList class="floating-submenu-element"></ul>
</div>
