import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  Observable,
  catchError,
  debounceTime,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import { AssigneeType } from '@app/models/status.model';
import { UserManagementService } from '@app/services/user-management.service';
import { environment } from '@env/environment';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { PermissionService } from '@app/services/permission.service';
import { MediaService } from '@app/services/media.service';
import { AssigneeUsersType } from '@app/models/general.model';

@Component({
  selector: 'app-assignee-search',
  templateUrl: './assignee-search.component.html',
  styleUrls: ['./assignee-search.component.scss'],
})
export class AssigneeSearchComponent implements OnInit, OnChanges {
  @Input() userId: string = '';
  @Input() userName: string = '';
  @Input() terminal: string = '';
  @Input() assignee!: any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() showDeleted: boolean = false;
  @Input() setEditable: boolean = false;
  @Input() hideAssignToMe: boolean = false;
  @Input() hideUnassigned: boolean = false;
  @Input() disabled: boolean = false;
  @Output() userIdSelected = new EventEmitter<any>();

  searchUserIdControl = new FormControl();
  filteredUsers!: Observable<any[]>;
  selectedUserImage: string = '';
  selectedUserDisplay: string = '';
  editable: boolean = true;
  allUsers: any[] = [];
  loading: boolean = true;

  assigneeType = AssigneeType;

  assigneeImages: { [assigneeId: string]: string | null } = {};

  selectedOrganization: any = {};

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  constructor(
    private userManagementService: UserManagementService,
    private permissionService: PermissionService,
    private mediaService: MediaService
  ) {
    this.searchUserIdControl = new FormControl('');
  }

  ngOnInit(): void {
    this.loadSelectedOrganization();
    if (!this.setEditable) {
      this.checkPermissions();
    }

    this.initializeFormControl();
    this.fetchUsersAndInitializeDropdown();

    if (this.assignee?.userId) {
      const user = {
        userId: this.assignee.userId,
        firstName: this.assignee.firstName,
        lastName: this.assignee.lastName,
      };
      this.onSelectUser(user);
    }

    if (this.disabled || !this.editable) {
      this.searchUserIdControl.disable();
    }
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  fetchUsersAndInitializeDropdown(): void {
    this.loading = true;

    this.searchUserList('').subscribe({
      next: (users) => {
        this.allUsers = users;

        this.filteredUsers = this.searchUserIdControl.valueChanges.pipe(
          startWith(''),
          debounceTime(700),
          map((searchTerm) => this.filterUsers(searchTerm))
        );
      },
      complete: () => {
        this.loading = false;
      },
      error: (error) => {
        console.error('Error loading assignees', error);
        this.loading = false;
      },
    });
  }

  private filterUsers(searchTerm: string): any[] {
    if (!searchTerm) {
      return this.allUsers;
    }
    return this.allUsers.filter((user) =>
      `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue || !this.editable) {
        this.searchUserIdControl.disable();
      } else {
        this.searchUserIdControl.enable();
      }
    }
  }

  private initializeFormControl(): void {
    if (this.required) {
      this.searchUserIdControl.setValidators(Validators.required);
    } else {
      this.searchUserIdControl.clearValidators();
    }
    this.searchUserIdControl.updateValueAndValidity();
  }

  canViewAssignToMe(): boolean {
    return (
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      this.permissionService.hasPermission('WRITE', 'My Interventions')
    );
  }

  setFullName(user: any): string {
    return `${user?.firstName} ${user?.lastName}`;
  }

  setSubsSearchUserIdControl(): void {
    this.filteredUsers = this.searchUserIdControl.valueChanges.pipe(
      startWith(''),
      debounceTime(700),
      switchMap((searchTerm) => this.searchUserList(searchTerm))
    );
  }

  searchUserList(searchTerm: string): Observable<any> {
    const params = {
      searchField: searchTerm,
      type: AssigneeUsersType.INTERVENTIONS,
      organizationId: this.selectedOrganization?.organizationId,
    };

    return this.userManagementService.getUsers(params).pipe(
      map((res: any) => {
        const users = res[0].userResults.filter(
          (user: any) => this.showDeleted || user.active
        );

        this.allUsers = users;

        users.forEach((user: any) => {
          this.getAssigneeImage(user.userId).subscribe((imageUrl) => {
            user.imageUrl = imageUrl;
          });
        });

        return users;
      }),
      catchError((error) => {
        console.error('Error loading users', error);
        return of([]);
      })
    );
  }

  getAssigneeImage(userId: string): Observable<any> {
    if (this.assigneeImages[userId]) {
      return of(this.assigneeImages[userId]);
    }

    return this.mediaService
      .downloadFile('userProfileImage', userId, 'profile_image')
      .pipe(
        map((blob: Blob) => {
          const imageUrl = URL.createObjectURL(blob);
          this.assigneeImages[userId] = imageUrl;
          return imageUrl;
        }),
        catchError(() => of(null))
      );
  }

  onSelectUser(user: any): void {
    this.assignee = user;
    if (user === AssigneeType.UNASSIGNED) {
      this.selectedUserDisplay = AssigneeType.UNASSIGNED;
      this.selectedUserImage = this.getImageUrl('.');
    } else {
      this.selectedUserDisplay = `${user.firstName} ${user.lastName}`;
      this.selectedUserImage = this.getImageUrl(user.userId);
    }
    this.searchUserIdControl.setValue(this.selectedUserDisplay);

    this.userIdSelected.emit(user);
  }

  capitalizeWords(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  resetUser(): void {
    this.assignee = null;
    this.selectedUserDisplay = '';
    this.selectedUserImage = '';
    this.searchUserIdControl.setValue('');
    this.userIdSelected.emit(null);
  }

  clearImg(): void {
    this.assignee = null;
    this.selectedUserDisplay = '';
    this.selectedUserImage = '';
  }

  getImageUrl(userId: string): string {
    return `${environment.driverImageBaseUrl}/${userId}/profile_thumbnail${environment.driverImageToken}`;
  }

  checkPermissions(): void {
    if (this.permissionService.hasPermission('WRITE', 'All Interventions')) {
      this.editable = true;
    } else {
      this.editable = this.permissionService.hasPermission('WRITE', 'My Interventions');
      if (!this.editable) {
        this.searchUserIdControl.disable();
      }
    }
  }

  getLoggedUserId(): string {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData.userInfo.objectId;
    }
    return '';
  }

  assignToMe(): void {
    const loggedUserId = this.getLoggedUserId();
    const user = this.allUsers.find((user) => user.userId === loggedUserId);
    if (user) {
      this.onSelectUser(user);
    }
  }
}
