<table mat-table matSort [dataSource]="dataFiltered" class="activity-table">
  <ng-container matColumnDef="accidentDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.accidentDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="crashType">
    <th mat-header-cell *matHeaderCellDef>Accident Type</th>
    <td mat-cell *matCellDef="let element">{{ element?.crashType }}</td>
  </ng-container>

  <ng-container matColumnDef="citationReceived">
    <th mat-header-cell *matHeaderCellDef>Citation</th>
    <td mat-cell *matCellDef="let element">
      {{ element.citationReceived ? "Yes" : "No" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="accidentCity">
    <th mat-header-cell *matHeaderCellDef>Location</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.accidentCity }} - {{ element?.accidentState }}
    </td>
  </ng-container>

  <ng-container matColumnDef="linkedIntervention">
    <th mat-header-cell *matHeaderCellDef>Intervention</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.linkedIntervention }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columns"
    (click)="onRowClick(row)"
    class="clickable"
  ></tr>
</table>

<div *ngIf="dataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr />

<div class="view-more" *ngIf="showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
