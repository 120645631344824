export enum AnalyticsTab {
  GENERAL = 0,
  SCORES = 1,
  RETENTION = 2,
  ACCIDENTS = 3,
  INJURIES = 4,
  DRIVER_LIST = 5,
}

export enum AnalyticsTabName {
  GENERAL = 'DriverAnalyticsGeneral',
  SCORES = 'DriverAnalyticsScores',
  RETENTION = 'DriverAnalyticsRetention',
  ACCIDENTS = 'DriverAnalyticsAccidents',
  INJURIES = 'DriverAnalyticsInjuries',
  DRIVER_LIST = 'DriverAnalyticsDriveList',
}

export enum AnalyticsEventsTabName {
  GENERAL = 'EventAnalyticsGeneral',
  ACCIDENTS = 'EventAnalyticsAccidents',
  INJURIES = 'EventAnalyticsInjuries',
  TELEMATICS = 'EventAnalyticsTelematics',
  SPEEDING = 'EventAnalyticsSpeeding',
  HOS = 'EventAnalyticsHOS',
  INSPECTIONS = 'EventAnalyticsInspections',
  SEATBELT = 'EventAnalyticsSeatbelt',
}

export enum AnalyticsEventsTab {
  GENERAL = 0,
  ACCIDENTS = 1,
  TELEMATICS = 2,
  SPEEDING = 3,
  HOS = 4,
  INSPECTIONS = 5,
  SEATBELT = 6,
}

export enum AnalyticsInterventionTab {
  GENERAL = 0,
  COACHING = 1,
  EFFECTIVENESS = 2,
}

export enum AnalyticsInterventionTabName {
  GENERAL = 'InterventionAnalyticsGeneral',
  COACHING = 'InterventionAnalyticsCoaching',
  EFFECTIVENESS = 'InterventionAnalyticsEffectiveness',
}

export enum FiltersSelection {
  SELECT_ALL = 'Select All',
  DESELECT_ALL = 'Deselect All',
}

export enum ScoreOverTimeChart {
  INTERVENTIONS = 0,
  EVENTS = 1,
  SCORES = 2,
}

export enum ChartColor {
  BLUE = '#4D85FE',
  GREEN = '#17BB99',
  GREEN_LIGHT = '#009900',
  YELLOW = '#FEC401',
  RED = '#DB4E38',
  VIOLET = '#CC8BFF',
  GRAY = '#AABAC9',
  PINK = '#E67C84',
  ORANGE = '#FFAF77',
  OTHER = '#FFAF7A',
}

export enum EventType {
  SPEEDING = 'Speeding',
  TELEMATICS = 'Telematics',
  ACCIDENTS = 'Accident',
  HOS = 'HOS',
  INSPECTIONS = 'Inspection',
  SEATBELT = 'Seatbelt',
}
