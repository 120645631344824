<div class="container-table">
  <table
    mat-table
    matSort
    [dataSource]="eventData"
    matSortActive="processDate"
    matSortDisableClear
    matSortDirection="desc"
    (matSortChange)="announceSortChange($event)"
    class="events-driver-table"
  >
    <ng-container matColumnDef="criteria">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Criteria</th>
      <td
        class="criteria-column"
        mat-cell
        *matCellDef="let element"
        (click)="onRowClick(element)"
        matTooltip="{{ addSpaceAfterComma(element?.criteria) }}"
      >
        {{ addSpaceAfterComma(element?.criteria) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="severity">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Severity</th>
      <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
        <i
          *ngIf="element.severity === 'Critical'"
          [matTooltip]="element.severity"
          class="fa-solid fa-angle-double-up icon-red icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Significant'"
          [matTooltip]="element.severity"
          class="fa-solid fa-chevron-up icon-red icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Moderate'"
          [matTooltip]="element.severity"
          class="fa-solid fa-equals icon-orange icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Minor'"
          [matTooltip]="element.severity"
          class="fa-solid fa-chevron-down icon-gray icon-default-size"
        ></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="interventions">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Interventions</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngFor="let intervention of element?.interventionObjects; let isLast = last"
        >
          <span
            style="text-decoration: underline"
            (click)="onInterventionSelected(intervention, element)"
          >
            {{ intervention.key }}
          </span>
          {{ isLast ? "" : ", " }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="processDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="date-column">Date</th>
      <td
        mat-cell
        *matCellDef="let element"
        class="date-column"
        (click)="onRowClick(element)"
      >
        {{ element?.processDate | utcDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="status-column">
        Status
      </th>
      <td mat-cell *matCellDef="let element" class="status-column">
        <app-status-select
          [id]="element.id"
          [driverId]="element.driverId"
          [status]="element.status"
          [eventData]="element"
          [hasInterventionLinked]="element?.interventions ? true : false"
          [disabled]="!canEditEvents()"
        ></app-status-select>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsGroups"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumnsGroups"
      class="clickable"
      [class.row-selected]="isRowSelected(row.id)"
    ></tr>
  </table>
</div>
