<div #modalContainer>
  <!-- Desktop Header Actions -->
  <ng-template #desktopHeaderActions>
    <div class="title-container">
      <h2 class="title">Event</h2>
    </div>
    <div class="dialog-close">
      <i class="fa-solid fa-print print-icon" (click)="printCurrentTabContent()"></i>
      <i
        *ngIf="isDialogMode; else pageCloseDesktop"
        mat-dialog-close
        class="fas fa-times"
      ></i>
      <ng-template #pageCloseDesktop>
        <i class="fas fa-times" (click)="onClosePage()"></i>
      </ng-template>
    </div>
    <div class="dialog-title">
      <div class="driver-avatar">
        <img
          [src]="getImageUrl(data?.driverId)"
          appImgFallback
          alt="Avatar"
          class="user-avatar"
        />
      </div>
      <div class="driver-info">
        <span class="firstname-lastname">
          {{ data.driverName ? toTitleCase(data?.driverName) : "" }}
        </span>
        <button mat-button (click)="goToDriverProfile()">
          <i class="fas fa-id-card"></i>
          <span>{{ data?.driverId }}</span>
        </button>
        <button mat-button (click)="goToTerminal(data?.terminal)">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ data?.terminal }}</span>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #desktopHeaderColumns>
    <!-- Custom Columns -->
    <div class="custom-columns">
      <div class="column">
        <span #eventTypeRef class="event-type" [ngClass]="{ hidden: !event?.type }">
          {{ event?.type }}
        </span>
      </div>
      <div class="column">
        <span class="column-span">
          <i
            *ngIf="event?.severity === 'Critical'"
            class="fa-solid fa-angle-double-up icon-red icon"
          ></i>
          <i
            *ngIf="event?.severity === 'Significant'"
            class="fa-solid fa-chevron-up icon-red icon"
          ></i>
          <i
            *ngIf="event?.severity === 'Moderate'"
            class="fa-solid fa-equals icon-orange icon"
          ></i>
          <i
            *ngIf="event?.severity === 'Minor'"
            class="fa-solid fa-chevron-down icon-gray icon"
          ></i>
          {{ event?.severity }}
        </span>
        <label class="column-label">Severity</label>
      </div>
      <div class="column">
        <span class="column-span">{{ toUtcDate(data?.processDate) }}</span>
        <label class="column-label">Date</label>
      </div>
      <div
        class="column special-border"
        [ngClass]="{
          'black-border': currentStatus === statusType.OPEN,
          'red-border':
            currentStatus === statusType.CLOSED || currentStatus === statusType.NON_EVENT,
          'blue-border': currentStatus === statusType.ASSIGNED
        }"
      >
        <app-status-select
          *ngIf="currentStatus !== ''"
          [id]="data.id"
          [driverId]="data.driverId"
          [status]="currentStatus"
          [oldStatus]="currentStatus"
          [eventData]="data"
          [border]="false"
          [hasInterventionLinked]="data?.interventions ? true : false"
          (selectionChanged)="handleStatusSelectionChange($event)"
          [disabled]="!canEditEvents()"
        ></app-status-select>
      </div>
    </div>
  </ng-template>

  <!-- Mobile Header Template -->
  <ng-template #mobileHeaderTemplate>
    <div class="title-container">
      <h2 class="title">Event</h2>
    </div>
    <div class="dialog-close">
      <i class="fa-solid fa-print print-icon" (click)="printCurrentTabContent()"></i>
      <i
        *ngIf="isDialogMode; else pageCloseMobile"
        mat-dialog-close
        class="fas fa-times"
      ></i>
      <ng-template #pageCloseMobile>
        <i class="fas fa-times" (click)="onClosePage()"></i>
      </ng-template>
    </div>
    <div class="dialog-title">
      <div class="driver-avatar">
        <img
          [src]="getImageUrl(data?.driverId)"
          appImgFallback
          alt="Avatar"
          class="user-avatar"
        />
      </div>
      <div class="driver-info mobile-info">
        <span class="firstname-lastname">
          {{ data.driverName ? toTitleCase(data?.driverName) : "" }}
        </span>
        <div class="driver-buttons">
          <button
            class="driver-id-button short-button"
            mat-button
            (click)="goToDriverProfile()"
          >
            <i class="fas fa-id-card grey-icon"></i>
            <span>{{ data?.driverId }}</span>
          </button>
          <button
            class="terminal-button short-button"
            mat-button
            (click)="goToTerminal(data?.terminal)"
          >
            <i class="fas fa-map-marker-alt grey-icon"></i>
            <span>{{ data?.terminal }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Tabs Template -->
  <ng-template #tabsTemplate>
    <mat-tab-group
      class="tab-group-events"
      #tabGroup
      [(selectedIndex)]="selectedTabIndex"
      (selectedTabChange)="onTabChange($event)"
      animationDuration="0ms"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <i class="fas fa-triangle-exclamation"></i>
          <span>Event Details</span>
        </ng-template>
        <app-tab-sections-details
          #tabDetails
          [driverId]="data?.driverId"
          [rowData]="data"
          [rowEvent]="event"
          [isLoading]="isLoading"
          [showMenu]="showMenu"
          *ngIf="selectedTabIndex === 0"
        ></app-tab-sections-details>
      </mat-tab>
      <mat-tab *ngIf="canViewDriverProfile()">
        <ng-template mat-tab-label>
          <i class="fas fa-th-list"></i>
          <span>Driver Info</span>
        </ng-template>
        <app-tab-sections-info
          [showMenu]="showMenu"
          [rowData]="data"
          *ngIf="selectedTabIndex === 1"
        ></app-tab-sections-info>
      </mat-tab>
    </mat-tab-group>
  </ng-template>

  <!-- Dialog Mode -->
  <ng-container *ngIf="isDialogMode; else pageMode">
    <mat-dialog-actions class="layout-desktop desktop-header">
      <ng-container *ngTemplateOutlet="desktopHeaderActions"></ng-container>
    </mat-dialog-actions>
    <ng-container *ngTemplateOutlet="desktopHeaderColumns"></ng-container>
    <mat-dialog-content #modalContent>
      <div class="layout-mobile">
        <ng-container *ngTemplateOutlet="mobileHeaderTemplate"></ng-container>
      </div>
      <!-- Tabs -->
      <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>
    </mat-dialog-content>
  </ng-container>

  <!-- Page Mode -->
  <ng-template #pageMode>
    <div class="layout-desktop">
      <ng-container *ngTemplateOutlet="desktopHeaderActions"></ng-container>
    </div>
    <div class="layout-mobile">
      <ng-container *ngTemplateOutlet="mobileHeaderTemplate"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="desktopHeaderColumns"></ng-container>
    <!-- Tabs -->
    <ng-container *ngTemplateOutlet="tabsTemplate"></ng-container>
  </ng-template>
</div>
