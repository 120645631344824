import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { Location } from '@angular/common';

import { EventService } from '@app/services/event.service';
import { DialogNewInterventionComponent } from '@app/shared/components/dialog-new-intervention/dialog-new-intervention.component';
import { toTitleCase } from '@app/shared/helpers/string.helper';
import { DialogEventService } from './dialog-event.service';
import { FlyoutType } from '@app/models/fly-out.model';
import { FlyoutService } from '@app/services/flyout.service';
import { StatusType } from '@app/models/status.model';
import { PermissionService } from '@app/services/permission.service';
import { ExpandViewService } from '@app/services/expand-view.service';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';
import { Subscription } from 'rxjs';
import { MediaService } from '@app/services/media.service';

enum EventTabs {
  EventDetails = 'EventDetails',
  DriverInfo = 'DriverInfo',
}

@Component({
  selector: 'app-dialog-driver',
  templateUrl: './dialog-event.component.html',
  styleUrls: ['./dialog-event.component.scss'],
  providers: [UtcDatePipe],
})
export class DialogEventComponent implements OnInit, OnDestroy, AfterViewInit {
  driverId: string = '';
  driverGuid: string = '';
  event: any = {
    status: null,
    eventActivities: [],
  };
  selectedTabIndex: number = 0;
  selectedTabName: string = '';
  isLoading!: boolean;
  showMenu: boolean = true;
  currentStatus: string = '';

  cssContent: string = '';
  private printIframe!: HTMLIFrameElement | null;
  private queryParamsSubscription!: Subscription;

  visibleTabs: string[] = [];

  data: any;
  isDialogMode: boolean = false;

  toTitleCase = toTitleCase;
  statusType = StatusType;

  @ViewChild('eventTypeRef') eventTypeRef!: ElementRef;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef;
  @ViewChild('modalContent', { static: false }) modalContent!: ElementRef;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public injectedData: any,
    @Optional() private dialogRef: MatDialogRef<DialogEventComponent>,
    @Optional() private dialog: MatDialog,
    private eventService: EventService,
    private flyoutService: FlyoutService,
    private router: Router,
    private dialogEventService: DialogEventService,
    private permissionService: PermissionService,
    private http: HttpClient,
    private expandViewService: ExpandViewService,
    private route: ActivatedRoute,
    private location: Location,
    private mediaService: MediaService
  ) {
    this.isDialogMode = !!this.injectedData;
    if (this.isDialogMode) {
      this.data = this.injectedData;
    }
  }

  ngOnInit(): void {
    if (!this.isDialogMode) {
      this.route.queryParams.subscribe((params) => {
        this.data = {
          ...this.data,
          driverId: params['driverId'],
          driverGuid: params['driverGuid'],
          terminal: params['terminal'],
          id: params['eventId'],
        };
        if (this.data.id) {
          this.fetchEventDetails();
        }
      });
    } else {
      this.fetchEventDetails();
    }

    this.subscriptionToDialogState();
    this.subsToStatusChange();
    this.updateVisibleTabs();
    this.driverId = this.data?.driverId;
    this.driverGuid = this.data?.driverGuid;
    this.subscriptionToURLParams();
    this.loadPrintStyles();
  }

  ngAfterViewInit(): void {
    if (!this.isDialogMode) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  updateVisibleTabs(): void {
    this.visibleTabs = [EventTabs.EventDetails, EventTabs.DriverInfo];
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }

    if (this.isDialogMode) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          tab: null,
          driverId: null,
          eventId: null,
          driverGuid: null,
          terminal: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
  }

  subscriptionToURLParams(): void {
    this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
      const tabName = params['tab'];

      if (tabName === 'DriverInfo') {
        this.selectedTabIndex = 1;
        this.onTabChange({ index: this.selectedTabIndex } as MatTabChangeEvent);
      }
    });
  }

  isSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome');
  }

  loadPrintStyles(): void {
    this.http.get('assets/css/print-screen.css', { responseType: 'text' }).subscribe({
      next: (css) => {
        this.cssContent = css;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  expandAllViewMore(): void {
    this.expandViewService.triggerExpandView();
  }

  printCurrentTabContent(): void {
    this.expandAllViewMore();

    if (this.isSafari()) {
      setTimeout(() => {
        if (this.modalContainer /*  && this.modalContent */) {
          const modalHTML = this.generateModalHTML();

          // Reuse the iframe if it has already been created
          if (!this.printIframe) {
            this.printIframe = document.createElement('iframe');
            this.printIframe.style.position = 'absolute';
            this.printIframe.style.width = '0px';
            this.printIframe.style.height = '0px';
            this.printIframe.style.border = 'none';
            document.body.appendChild(this.printIframe);
          }

          const iframeDoc =
            this.printIframe.contentWindow?.document || this.printIframe.contentDocument;
          if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(modalHTML);
            iframeDoc.close();

            // Wait for the iframe to load before executing print
            this.printIframe.onload = () => {
              this.printIframe?.contentWindow?.focus();
              this.printIframe?.contentWindow?.print();
            };
          }
        }
      }, 1000);
    } else {
      setTimeout(() => {
        if (this.modalContainer /*  && this.modalContent */) {
          const modalHTML = this.generateModalHTML();

          const blob = new Blob([modalHTML], { type: 'text/html' });
          const url = URL.createObjectURL(blob);

          const printWindow = window.open(url, '_blank');

          if (printWindow) {
            printWindow.onload = () => {};
          }
        }
      }, 300);
    }
  }

  getCurrentUser() {
    const userDetailsString = localStorage.getItem('userData');
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      const { firstName, lastName } = userDetails.userInfo;
      return `${firstName} ${lastName}`;
    } else {
      return '';
    }
  }

  generateModalHTML(): string {
    const eventTitle = `${this.data?.driverId} - Event - ${
      this.event?.type
    } ${this.toUtcDate(this.data?.processDate)}`;

    const userName = this.getCurrentUser();

    const containerClone = this.modalContainer.nativeElement.cloneNode(
      true
    ) as HTMLElement;

    const layoutMobileElements = containerClone.querySelectorAll('.layout-mobile');
    layoutMobileElements.forEach((el) => el.remove());

    let finalHTML = containerClone.innerHTML;

    finalHTML = finalHTML.replaceAll(
      '/assets/images/fallback.png',
      window.location.origin + '/assets/images/fallback.png'
    );

    return `
      <html>
        <head>
          <title>${eventTitle} (Generated for ${userName})</title>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
          <style>${this.cssContent}</style>
        </head>
        <body>
          <div class="button-container">
            <button onclick="window.close()">Go Back</button>
            <button onclick="window.print()">Print</button>
          </div>
          <div class="printable-modal">
            ${finalHTML}
          </div>
        </body>
      </html>
    `;
  }

  subsToStatusChange(): void {
    this.dialogEventService.eventStatusChange$.subscribe((newStatus) => {
      this.currentStatus = newStatus;
    });
  }

  subscriptionToDialogState(): void {
    this.checkIfTopDialog();
    this.dialog.afterOpened.subscribe((dialogRef) => {
      this.checkIfTopDialog();
      dialogRef.afterClosed().subscribe(() => {
        setTimeout(() => {
          this.checkIfTopDialog();
        });
      });
    });
  }

  toUtcDate(date: any): string {
    return date ? formatDate(date, 'M/d/yyyy', 'en-US', '+0000') : '';
  }

  checkIfTopDialog(): void {
    this.showMenu = this.flyoutService.isDialogOnTop(FlyoutType.EVENT);
  }

  fetchEventDetails(): void {
    this.isLoading = true;
    this.eventService.getEvent(this.data.id).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.event = res[0];
        this.data = { ...this.data, ...this.event };
        this.currentStatus = this.event?.status;
        this.setEventTypeWidth();
        //this.setDriverGuidOnURL(this.event.driverGuid);
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  /* setDriverGuidOnURL(driverGuid: string): void {
    const currentUrl = this.location.path();
    const urlSearchParams = new URLSearchParams(currentUrl.split('?')[1] || '');

    if (driverGuid) {
      urlSearchParams.set('driverId', this.event.driverGuid);

      this.location.replaceState(
        `${currentUrl.split('?')[0]}?${urlSearchParams.toString()}`
      );
    }
  } */

  setEventTypeWidth() {
    const padding = 20;
    const characterWidth = 7;

    if (this.eventTypeRef && this.eventTypeRef.nativeElement) {
      const textLength = this.event.type.length;
      let width = textLength * characterWidth + padding;
      if (width === 41) {
        width = 44;
      }
      if (width === 76) {
        width = 74;
      }
      if (width === 74) {
        width = 80;
      }
      if (width === 80) {
        width = 78;
      }
      if (width === 90) {
        width = 88;
      }
      this.eventTypeRef.nativeElement.style.width = `${width}px`;
    }
  }

  getImageUrl(driverId: string): string {
    return this.mediaService.getDriverImageURL(driverId);
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    this.updateSelectedTabState();
    const selectedTabName = this.visibleTabs[this.selectedTabIndex];
    const currentUrl = this.location.path();

    const urlSearchParams = new URLSearchParams(currentUrl.split('?')[1] || '');

    urlSearchParams.set('eventId', this.data.id);
    urlSearchParams.set('tab', selectedTabName);

    this.location.replaceState(
      `${currentUrl.split('?')[0]}?${urlSearchParams.toString()}`
    );

    const container: Element | null = document.querySelector(
      '.dialog-event .mat-mdc-dialog-container .mdc-dialog__surface'
    );
    container?.setAttribute('style', 'overflow: hidden;');
    setTimeout(() => container?.setAttribute('style', 'overflow: visible;'), 1000);
  }

  private updateSelectedTabState(): void {
    this.selectedTabName = this.visibleTabs[this.selectedTabIndex];
  }

  onAssignInterventionClick(): void {
    this.dialog.open(DialogNewInterventionComponent, {
      width: '815px',
      panelClass: 'single',
    });
  }

  goToDriverProfile(): void {
    this.tabGroup.selectedIndex = 1;
    this.selectedTabIndex = 1;
  }

  goToTerminal(terminal: string): void {
    this.dialog.openDialogs.forEach((dialogRef) => dialogRef.close());
    this.router.navigate(['/drivers', terminal]);
  }

  handleStatusSelectionChange(newStatus: string) {
    this.currentStatus = newStatus;
    setTimeout(() => {
      this.dialogEventService.announceStatusSelectionChange(newStatus);
    }, 2000);
  }

  canViewDriverProfile(): boolean {
    return this.permissionService.hasPermission('READ', 'Driver Profile');
  }

  canEditEvents(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Events');
  }

  onClosePage(): void {
    this.router.navigate(['/events']);
  }
}
