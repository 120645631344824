<div class="container">
  <!-- ============= DESKTOP ============= -->
  <div class="layout-desktop">
    <div class="header">
      <div class="title">
        <h1>Drivers</h1>

        <!-- Filter link -->
        <div class="title-action" (click)="openFiltersModal()">
          <i class="fas fa-sliders-h"></i>
          <span>Filter</span>
        </div>

        <!-- Toggle (All Drivers / Followed Drivers) -->
        <div class="new-radio-slider">
          <input
            type="radio"
            name="slider"
            id="option1"
            class="new-radio-input"
            [(ngModel)]="followedDrivers"
            [value]="false"
          />
          <label (click)="onRadioSliderClick(false)" for="option1" class="new-radio-label"
            >All Drivers</label
          >
          <input
            type="radio"
            name="slider"
            id="option2"
            class="new-radio-input"
            [(ngModel)]="followedDrivers"
            [value]="true"
          />
          <label (click)="onRadioSliderClick(true)" for="option2" class="new-radio-label"
            >Followed Drivers</label
          >
          <div class="slider" #desktopSlider></div>
        </div>
      </div>

      <!-- Search Bar -->
      <div class="search-block">
        <input
          #searchInput
          type="text"
          class="search-input"
          name="search"
          placeholder="Search drivers..."
          [(ngModel)]="searchValue"
          (keyup.enter)="searchDriver()"
        />

        <span *ngIf="!searchValue" class="search-icon" (click)="searchDriver()">
          <i class="fas fa-search"></i>
        </span>
        <span *ngIf="searchValue" class="search-icon" (click)="clearSearch()">
          <i class="fas fa-times"></i>
        </span>
      </div>
    </div>
  </div>

  <!-- ============= MOBILE ============= -->
  <div class="layout-mobile">
    <div class="header">
      <ng-container *ngIf="searchedTerm">
        <div class="mobile-search-bar" (click)="openSearchModal()">
          <span>{{ searchedTerm }}</span>
          <i class="fas fa-search"></i>
        </div>
      </ng-container>

      <!-- Row 1 -->
      <div class="row-1">
        <h1>Drivers</h1>

        <!-- Filter link -->
        <div class="title-action" (click)="openFiltersModal()">
          <i class="fas fa-sliders-h"></i>
          <span>Filter</span>
        </div>

        <ng-container *ngIf="!searchedTerm">
          <span class="search-magnifying-glass" (click)="openSearchModal()">
            <i class="fas fa-search"></i>
          </span>
        </ng-container>
      </div>
    </div>

    <!-- Row 2 -->
    <div class="row-2">
      <!-- Toggle (All Drivers / Followed Drivers) -->
      <div class="new-radio-slider">
        <input
          type="radio"
          name="slider"
          id="option1"
          class="new-radio-input"
          [(ngModel)]="followedDrivers"
          [value]="false"
        />
        <label (click)="onRadioSliderClick(false)" for="option1" class="new-radio-label"
          >All Drivers</label
        >
        <input
          type="radio"
          name="slider"
          id="option2"
          class="new-radio-input"
          [(ngModel)]="followedDrivers"
          [value]="true"
        />
        <label (click)="onRadioSliderClick(true)" for="option2" class="new-radio-label"
          >Followed Drivers</label
        >
        <div class="slider" #mobileSlider></div>
      </div>
    </div>
  </div>

  <!-- Filter Boxes -->
  <div class="filter-boxes">
    <div *ngFor="let filter of groupedFilters; let i = index" class="filter-box">
      <span *ngIf="filter.count === 1">{{ filter.key }}: {{ filter.values[0] }}</span>
      <div
        *ngIf="filter.count > 1"
        class="clickable-filter"
        (click)="openFilterValuesDialog($event, filter.key, filter.values)"
      >
        <span>{{ filter.key }} ({{ filter.count }})</span>
        <i class="fa fa-chevron-down chevron-icon"></i>
      </div>
      <button
        *ngIf="filter.count === 1"
        class="remove-filter"
        (click)="removeBox(filter.key)"
      >
        <i class="fas fa-times icon"></i>
      </button>
    </div>
    <button *ngIf="filters.length" class="clear-all" (click)="clearAll(true)">
      Clear All
    </button>
  </div>

  <div class="container-table">
    <!-- Actions Bar -->
    <div class="table-actions">
      <span class="follow-selected"
        >{{ selection.selected.length }} of
        {{ dataSource.data.length || 0 }} Selected</span
      >

      <div class="action-buttons">
        <button
          mat-flat-button
          color="primary"
          [disabled]="selection.selected.length === 0 || isLoadingFollow"
          (click)="onFollowSelectedClick()"
        >
          <i class="far fa-solid fa-bookmark" *ngIf="!isLoadingFollow"></i>
          <div class="spinner-follow" *ngIf="isLoadingFollow">
            <mat-progress-spinner
              mode="indeterminate"
              [diameter]="15"
              class="spinner"
            ></mat-progress-spinner>
          </div>
          <span>{{ allBookmarked() ? "Unfollow Selected" : "Follow Selected" }}</span>
        </button>
        <!-- <button mat-flat-button color="primary" [disabled]="true">
          <i class="far fa-solid fa-flag"></i><span>Flag Selected</span>
        </button> -->
      </div>
    </div>

    <!-- Drivers Table-->
    <div #tableContainer class="table-responsive">
      <table
        class="drivers-table"
        mat-table
        matSort
        [matSortActive]="defaultSortActive"
        matSortDisableClear
        [matSortDirection]="defaultSortDirection"
        (matSortChange)="announceSortChange($event)"
        [dataSource]="dataSource"
        [ngClass]="{ 'loading-table': isLoading }"
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="driverName" class="driver-name-column">
          <th mat-header-cell mat-sort-header="driverName" *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            <div class="name">
              <i
                *ngIf="!element.bookMark"
                class="fa-regular fa-bookmark bookmark-icon bookmark-icon-regular"
              ></i>
              <i *ngIf="element.bookMark" class="fa-solid fa-bookmark bookmark-icon"></i>
              <img
                [src]="getImageUrl(element?.driverId)"
                appImgFallback
                alt="Avatar"
                class="user-avatar"
              />
              {{ toTitleCase(element?.driverName) }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="driverId">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            {{ element?.driverId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="terminal">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Terminal</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            {{ element?.terminal }}
          </td>
        </ng-container>

        <ng-container matColumnDef="hireDate">
          <th mat-header-cell *matHeaderCellDef>Hire Date</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            {{ element?.hireDate | utcDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nbrOfInterventions">
          <th mat-header-cell *matHeaderCellDef>Open Interventions</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            {{ element?.nbrOfInterventions }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastDispatchDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Dispatched Date</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            {{ element?.lastDispatchDate | utcDate }}
          </td>
        </ng-container>

        <ng-container *ngIf="canViewDriverScores()" matColumnDef="driverScore">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Score</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            <div
              class="block-count-change"
              *ngIf="
                isScoreBlockVisible(element?.driverScore);
                else scoreNotAvailableTemplate
              "
            >
              <span class="count-change">{{
                (element?.driverScore ?? 0) / 10 | number : "1.0-1"
              }}</span>
              <app-star-rating [type]="element.scoringTier"></app-star-rating>
            </div>
            <ng-template #scoreNotAvailableTemplate> - </ng-template>
          </td>
        </ng-container>

        <ng-container *ngIf="canViewDriverScores()" matColumnDef="scoreChangeType">
          <th mat-header-cell *matHeaderCellDef>Change</th>
          <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
            <div class="block-score">
              <span class="score-change">
                {{
                  getSymbol(element?.scoreChangeType) +
                    (element?.scoreChange ? element?.scoreChange / 10 : 0)
                }}</span
              >
              <i
                *ngIf="element?.scoreChangeType === 'Positive'"
                class="fa-solid fa-circle-arrow-up icon-green-light"
              ></i>
              <i
                *ngIf="element?.scoreChangeType === 'Negative'"
                class="fa-solid fa-circle-arrow-down icon-red"
              ></i>
              <i
                *ngIf="element?.scoreChangeType === 'No Change'"
                class="fa-solid fa-minus icon-gray"
              ></i>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <i
              class="fa-solid fa-ellipsis"
              [matMenuTriggerFor]="menu"
              (click)="onMenuClick($event)"
            ></i>
            <mat-menu #menu="matMenu">
              <!-- <button mat-menu-item (click)="onNewActivityClick()">
              <mat-icon (click)="onNewActivityClick()">add_circle</mat-icon>
              <span>New activity</span>
            </button> -->
              <button
                mat-menu-item
                *ngIf="canCreateInterventions()"
                (click)="onNewInterventionClick(element)"
              >
                <mat-icon>add_circle</mat-icon>
                <span>New intervention</span>
              </button>
              <button mat-menu-item (click)="onFollowDriverClick(element)">
                <mat-icon>bookmark</mat-icon>
                <span>{{ element?.bookMark ? "Unfollow" : "Follow" }} Driver</span>
              </button>
              <!-- <mat-divider></mat-divider> -->
              <!--            <button mat-menu-item class="custom-menu-item">-->
              <!--              <mat-icon color="warn">-->
              <!--                block-->
              <!--              </mat-icon>-->
              <!--              <span style="color: #CC0000">Block Driver</span>-->
              <!--            </button>-->
              <!--            <button mat-menu-item>-->
              <!--              <mat-icon style="color: darkgreen">-->
              <!--                block-->
              <!--              </mat-icon>-->
              <!--              <span style="color: darkgreen">Unblock Driver</span>-->
              <!--            </button>-->
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="clickable"
          [class.row-selected]="isRowSelected(row.driverId)"
        ></tr>
      </table>

      <!-- Spinner -->
      <div *ngIf="isLoading" class="spinner-overlay">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>

      <div
        *ngIf="
          !isLoading && (paginationParams.total === 0 || dataSource.data.length === 0)
        "
        class="no-records-found"
      >
        <p>Sorry, no drivers could be found matching your search query.</p>
        <br />
        <p>Please adjust your search or filters and try again.</p>
      </div>
    </div>

    <mat-paginator
      showFirstLastButtons
      [length]="paginationParams.total"
      [pageIndex]="pageEvent.pageIndex"
      [pageSize]="pageEvent.pageSize"
      (page)="onPageChangeClick($event)"
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>
</div>
