import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, from, switchMap, throwError, map, catchError } from 'rxjs';
import { AuthService } from '@app/services/auth.service';
import { environment } from '@env/environment';

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tokenObservable: Observable<string | null>;

    if (req.url.startsWith(environment.adminApiUrl)) {
      tokenObservable = from(this.authService.acquireAdminApiToken());
    } else if (req.url.startsWith(environment.complianceApiUrl)) {
      tokenObservable = from(this.authService.acquireComplianceApiToken());
    } else if (req.url.startsWith(environment.dataApiUrl)) {
      tokenObservable = from(this.authService.acquireDataApiToken());
    } else {
      console.warn(`No matching token scope for URL: ${req.url}`);
      return next.handle(req);
    }

    return tokenObservable.pipe(
      switchMap(token => {
        let clonedRequest = req;

        if (token) {
          clonedRequest = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`),
          });
        }

        return next.handle(clonedRequest).pipe(
          map(event => {
            if (event instanceof HttpResponse) {
              if (typeof event.body === 'string') {
                try {
                  const cleanBodyString = this.cleanJsonString(event.body);
                  const cleanBody = JSON.parse(cleanBodyString);
                  return event.clone({ body: cleanBody });
                } catch (error) {
                  console.error('Error parsing JSON response:', error);
                  throw new HttpErrorResponse({
                    error: 'Error parsing JSON response after cleaning',
                    status: event.status,
                    statusText: event.statusText,
                    url: event.url ?? undefined,
                  });
                }
              } else if (req.responseType === 'blob') {
                return event.clone({ body: event.body });
              }
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            console.error('HTTP Error:', error);
            return throwError(() => error);
          })
        );
      })
    );
  }

  private cleanJsonString(jsonString: string): string {
    return jsonString
      .replace(/[\u0000-\u001F\u007F-\u009F]/g, ' ') 
      .replace(/\\n/g, ' '); 
  }
}
