import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of, BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { DriverService } from '@app/services/driver.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-driver-search',
  templateUrl: './driver-search.component.html',
  styleUrls: ['./driver-search.component.scss'],
})
export class DriverSearchComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() organization: any;
  @Input() isEnabled: boolean = false;

  @Input() driver: string = '';
  @Input() userName: string = '';
  @Input() terminal: string = '';
  @Input() assignee!: any;
  @Input() showDeleted: boolean = false;
  @Output() driverSelected = new EventEmitter<any>();

  searchDriverControl = new FormControl('');
  filteredUsers!: Observable<any[]>;
  selectedUserImage: string = '';
  selectedUserDisplay: string = '';

  private organization$ = new BehaviorSubject<any>(null);

  constructor(private driverService: DriverService) {}

  ngOnInit(): void {
    this.initializeFormControl();

    if (this.assignee?.driverId) {
      const user = this.assignee;
      this.onSelectUser(user);
    }

    this.setupUserSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['organization']) {
      this.organization$.next(this.organization);
      this.resetUser();
    }
  }

  private initializeFormControl(): void {
    if (this.required) {
      this.searchDriverControl.setValidators(Validators.required);
    } else {
      this.searchDriverControl.clearValidators();
    }
    this.searchDriverControl.updateValueAndValidity();
  }

  private setupUserSearch(): void {
    this.filteredUsers = combineLatest([
      this.searchDriverControl.valueChanges.pipe(
        debounceTime(700),
        distinctUntilChanged()
      ),
      this.organization$,
    ]).pipe(
      switchMap(([searchTerm, organization]) => {
        if (searchTerm && searchTerm.trim().length > 0 && organization) {
          return this.searchUserList(searchTerm, organization);
        } else {
          return of([]);
        }
      })
    );
  }

  private searchUserList(searchTerm: string, organization: any): Observable<any[]> {
    const params = this.prepareParams(searchTerm, organization);
    return this.driverService.getDriverList(params).pipe(
      map((data: any) => {
        if (data.length && data[0].driverSummaries) {
          return data[0].driverSummaries;
        } else {
          return [];
        }
      })
    );
  }

  private prepareParams(searchTerm: string, organization: any): any {
    const params: any = {
      pageSize: 10,
      pageNumber: 1,
      searchField: searchTerm,
      sortField: 'driverName',
      sortValue: 'ASC',
      status: 'Active',
      organizationId: organization.organizationId,
    };
    return params;
  }

  onSelectUser(driver: any): void {
    this.assignee = driver;
    const capitalizedDriverName = this.capitalizeWords(driver.driverName);
    this.selectedUserDisplay = `${capitalizedDriverName} - ${driver.driverId}`;
    this.selectedUserImage = this.getImageUrl(driver.driverId);

    this.searchDriverControl.setValue(this.selectedUserDisplay);
    this.driverSelected.emit(driver);
  }

  capitalizeWords(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  resetUser(): void {
    this.assignee = null;
    this.selectedUserDisplay = '';
    this.selectedUserImage = '';
    this.searchDriverControl.setValue('');
    this.driverSelected.emit(null);
  }

  clearImg(): void {
    this.assignee = null;
    this.selectedUserDisplay = '';
    this.selectedUserImage = '';
  }

  getImageUrl(driverId: string): string {
    return `${environment.driverImageBaseUrl}/${driverId}/profile_thumbnail${environment.driverImageToken}`;
  }
}
