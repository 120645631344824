<div class="stepper-container w-500" [formGroup]="policeForm">
  <h3>Have the police been called?*</h3>
  <mat-radio-group formControlName="called" required class="column">
    <mat-radio-button *ngFor="let option of policeCalled" [value]="option.id">
      {{ option.description }}
    </mat-radio-button>
  </mat-radio-group>
  <div
    *ngIf="policeForm.get('called')?.value === 1 || policeForm.get('called')?.value === 2"
  >
    <h3>Please provide the responding officer's information.</h3>
    <div class="notes">(Name, Badge Number, Phone Number, etc.)</div>
    <mat-form-field appearance="outline" class="textarea w-full">
      <textarea matInput formControlName="respondingOfficerInfo"></textarea>
    </mat-form-field>

    <h3>Please provide the police report number</h3>
    <div class="column">
      <mat-form-field appearance="outline">
        <mat-label>Report #</mat-label>
        <input matInput formControlName="reportNumber" />
      </mat-form-field>
    </div>

    <h3>Did you receive a citation?*</h3>
    <mat-radio-group formControlName="citation">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
