import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DriverService } from '@app/services/driver.service';
import { MediaService } from '@app/services/media.service';
import { environment } from '@env/environment';
import { Observable, debounceTime, map, of, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-driver-id-search',
  templateUrl: './driver-id-search.component.html',
  styleUrls: ['./driver-id-search.component.scss'],
})
export class DriverIdSearchComponent implements OnInit, OnChanges {
  @Input() driverId: string = '';
  @Input() driverName: string = '';
  @Input() terminal: string = '';
  @Input() disabled: boolean = false;
  @Input() organizationId?: string;
  @Output() driverIdSelected = new EventEmitter<any>();

  searchDriverIdControl = new FormControl();
  filteredDrivers!: Observable<any[]>;
  selectedDriverImage: string = '';
  selectedDriverDisplay: string = '';

  constructor(private driverService: DriverService, private mediaService: MediaService) {
    this.searchDriverIdControl = new FormControl('', Validators.required);
    if (this.disabled) {
      this.searchDriverIdControl.disable();
    }
    this.setSubsSearchDriverIdControl();
  }

  ngOnInit(): void {
    if (this.driverId !== '') {
      const driver = {
        driverId: this.driverId,
        driverName: this.driverName,
        terminal: this.terminal,
      };
      this.onSelectDriver(driver);
      this.searchDriverIdControl.disable();
    } else if (this.disabled) {
      this.searchDriverIdControl.disable();
    } else {
      this.searchDriverIdControl.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.searchDriverIdControl.disable();
      } else {
        this.searchDriverIdControl.enable();
      }
    }
  }

  public reset(): void {
    this.selectedDriverImage = '';
    this.selectedDriverDisplay = '';
    this.searchDriverIdControl.reset();
    this.driverIdSelected.emit(null);
    if (!this.disabled) {
      this.searchDriverIdControl.enable();
    }
  }

  setSubsSearchDriverIdControl(): void {
    this.filteredDrivers = this.searchDriverIdControl.valueChanges.pipe(
      startWith(''),
      debounceTime(700),
      switchMap((searchTerm) => this.searchDriverList(searchTerm))
    );
  }

  searchDriverList(searchTerm: string): Observable<any[]> {
    if (searchTerm) {
      const params = {
        searchField: searchTerm,
        pageSize: 15,
        pageNumber: 1,
        organizationId: this.organizationId || environment.organizationId,
      };
      return this.driverService
        .getDriverList(params)
        .pipe(map((res: any) => res[0].driverSummaries));
    } else {
      return of([]);
    }
  }

  onSelectDriver(driver: any): void {
    this.selectedDriverDisplay = `${this.capitalizeWords(driver.driverName)} (${
      driver.driverId
    })`;
    this.selectedDriverImage = this.getImageUrl(driver.driverId);
    this.searchDriverIdControl.setValue(this.selectedDriverDisplay);

    this.driverIdSelected.emit(driver);
  }

  capitalizeWords(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  resetDriver(): void {
    this.selectedDriverImage = '';
    this.searchDriverIdControl.setValue('');
    this.driverIdSelected.emit(null);
  }

  clearImg(): void {
    this.selectedDriverImage = '';
  }

  getImageUrl(driverId: string): string {
    return this.mediaService.getDriverImageURL(driverId);
  }
}
