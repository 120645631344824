import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { AppConfig } from '@app/app.config';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { INote } from '@app/models/note.model';

@Injectable({
  providedIn: 'root',
})
export class DriverNotesService {
  private readonly baseUrl = environment.dataApiUrl;
  private readonly notesUrl = 'notes';

  constructor(private http: HttpClient) {}

  createNote(request: INote): Observable<any> {
    const fullUrl = `${this.baseUrl}/${this.notesUrl}`;
    return this.http.post(fullUrl, request);
  }

  getNotes(
    entityType: string,
    entityId: string,
    page: number,
    pageSize: number
  ): Observable<INote[]> {
    const fullUrl = `${this.baseUrl}/${this.notesUrl}`;
    const params = new HttpParams()
      .set('EntityType', entityType)
      .set('EntityId', entityId)
      .set('PageNumber', page)
      .set('PageSize', pageSize);

    return this.http.get<INote[]>(fullUrl, { params });
  }
}
