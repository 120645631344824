<div class="header-block">
  <h2>Hours of Service</h2>

  <button
    *ngIf="canCreateHOS()"
    mat-flat-button
    color="primary"
    class="new-item-button"
    (click)="onNewHOSClick()"
  >
    <i class="fas fa-plus-circle"></i>
    <span class="button-title">Add New</span>
  </button>
</div>

<table
  *ngIf="!isLoading"
  mat-table
  matSort
  [dataSource]="hoursServiceDataFiltered"
  class="table-header-sticky-driver-details"
>
  <ng-container matColumnDef="summaryDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.summaryDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="day">
    <th mat-header-cell *matHeaderCellDef>Day</th>
    <td mat-cell *matCellDef="let element">{{ element?.day }}</td>
  </ng-container>

  <ng-container matColumnDef="offDuty">
    <th mat-header-cell *matHeaderCellDef>Off Duty</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.offDuty !== "" ? convertToHourMinuteFormat(element?.offDuty) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="sleeper">
    <th mat-header-cell *matHeaderCellDef>Sleeper</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.sleeper !== "" ? convertToHourMinuteFormat(element?.sleeper) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="driving">
    <th mat-header-cell *matHeaderCellDef>Driving</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.driving !== "" ? convertToHourMinuteFormat(element?.driving) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="onDuty">
    <th mat-header-cell *matHeaderCellDef>On Duty</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.onDuty !== "" ? convertToHourMinuteFormat(element?.onDuty) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="personalConveyance">
    <th mat-header-cell *matHeaderCellDef>Personal Conveyance</th>
    <td mat-cell *matCellDef="let element">
      {{
        element?.personalConveyance !== ""
          ? convertToHourMinuteFormat(element?.personalConveyance)
          : ""
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="violations">
    <th mat-header-cell *matHeaderCellDef>Violation</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.violations.length ? "Yes" : "No" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="hoursServiceColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: hoursServiceColumns"
    (click)="onRowClick(row)"
    class="clickable"
    [class.row-selected]="isRowSelected('hoursOfService', row.id)"
  ></tr>

  <!--No data row-->
  <tr *matNoDataRow>
    <td
      *ngIf="!isLoading"
      [attr.colspan]="hoursServiceColumns.length"
      class="no-records-found"
    >
      No records found
    </td>
  </tr>
</table>

<div *ngIf="isLoading" class="spinner-container">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<div class="paginator-container" *ngIf="!isViewMore">
  <div class="paginator-text">
    Showing {{ startPageIdx }} - {{ endPageIdx }} of {{ totalRecordCount }} items
  </div>
  <mat-paginator
    [pageSize]="pageEvent.pageSize"
    [length]="paginationParams.total"
    [hidePageSize]="true"
    [pageIndex]="pageEvent.pageIndex"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>

<hr *ngIf="!isLoading" />

<div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
