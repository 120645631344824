<div class="title-section-container">
  <form [formGroup]="interventionForm">
    <div class="inputs-container">
      <!-- Title -->
      <mat-form-field class="input-icon input-separated" appearance="outline">
        <mat-label>ID #</mat-label>
        <input matInput formControlName="key" />
      </mat-form-field>
      <!-- Reason -->
      <mat-form-field class="input-icon input-separated" appearance="outline">
        <mat-label>Reason</mat-label>
        <input matInput formControlName="reason" />
      </mat-form-field>
      <!-- Driver -->
      <mat-form-field class="input-icon input-separated" appearance="outline">
        <mat-label>Driver</mat-label>
        <input matInput formControlName="driver" [matAutocomplete]="auto" />
        <mat-icon class="suffix" matSuffix>
          <i class="fa-solid fa-magnifying-glass"></i>
        </mat-icon>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelectDriver($event.option.value)"
        >
          <mat-option *ngFor="let driver of filteredDrivers | async" [value]="driver">
            <div class="driver-option-content">
              <span>{{ driver.driverName | capitalize }}</span>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!-- Driver Info -->
      <div class="driver-info">
        <button mat-button (click)="goToDriverProfile()">
          <i class="fas fa-id-card"></i>
          <span>{{ driverSelected?.driverId }}</span>
        </button>

        <button mat-button (click)="goToTerminal(data?.terminal)">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ driverSelected?.terminal }}</span>
        </button>
      </div>
    </div>
  </form>
  <div class="buttons-container">
    <!-- Save Button  -->
    <button class="save-button" mat-stroked-button color="green-light" (click)="save()">
      <i class="fa-solid fa-circle-check"></i>Save
    </button>
    <!-- Cancel Button  -->
    <button mat-stroked-button color="warn" (click)="cancel()">
      <i class="fa-solid fa-circle-xmark"></i>Cancel
    </button>
  </div>
</div>
