import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mobile-search-modal',
  templateUrl: './mobile-search-modal.component.html',
  styleUrls: ['./mobile-search-modal.component.scss'],
})
export class MobileSearchModalComponent implements OnDestroy, AfterViewInit {
  searchValue: string;
  title = 'Search';
  placeholder = 'Search...';

  @ViewChild('mobileSearchInput') mobileSearchInput!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MobileSearchModalComponent>
  ) {
    this.title = data?.title;
    this.placeholder = data?.placeholder;
    this.searchValue = data?.searchValue ?? '';
  }

  ngOnDestroy(): void {
    this.onCancel();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mobileSearchInput.nativeElement.focus();
    }, 300);
  }

  onSearch(): void {
    this.dialogRef.close(this.searchValue.trim());
  }

  onCancel(): void {
    this.dialogRef.close(this.searchValue.trim());
  }

  onClear(): void {
    this.searchValue = '';
  }
}
